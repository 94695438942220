<template>
	<div class="binding">
		<p class="title">钱包修改</p>
		<span class="little_title">钱包地址</span>
		<van-field v-model="submitForm.wallet" type="text" />
		<span class="little_title">手机号码</span>
		<van-field v-model="submitForm.mobile" type="number" disabled />
		<span class="little_title">手机验证码</span>
		<div class="message">
			<van-field v-model="submitForm.code" type="number" />
			<van-button v-if="showbtn" color="#E8F3FF"  @click="handleSend(submitForm.mobile)">获取验证码</van-button>
			<van-button v-else color="#E8F3FF" :disabled="attcode">{{code_ts}}</van-button>
		</div>
		<div class="bottom">
			<van-button  color="#124DA5" @click="handleSafe(submitForm.mobile)">确定</van-button>
		</div>
	</div>
</template>

<script>
	import {
		Personal
	} from "@/api/index";
	export default {
		data() {
			return {
				confirm: true, //提交验证按钮判断
				attcode: false, //点击获取验证码按钮判断
				showbtn: true, // 展示获取验证码或倒计时按钮判断
				yzcode: '',
				code_ts: '获取验证码', //倒计时提示文字
				sec: 60, // 倒计时秒数
				submitForm: {
					wallet: '',
					mobile: '',
					code: '',
				},
				form: {
					nums: '',
				},
			};
		},
		created() {
			this.Info();
		},
		methods: {
			async Info() {
				let { status, data } = await Personal.info();
				if(status) {
					this.submitForm.mobile = data.data.mobile;
				}
			},
			//发送短信
			async handleSend(mobile) {
				let {
					status,
					data
				} = await Personal.sendsms({
					mobile:mobile,
					scene:this.scene
				});
				if (status) {
					if (data.code == 200) {
						var timer = setInterval(() => {
							this.sec = this.sec - 1
							this.code_ts = this.sec + 'S后重试'
							this.showbtn = false
							this.attcode = true
							if (this.sec === 0) {
								clearInterval(timer)
								this.sec = 60
								this.code_ts = this.sec + 'S后重试'
								this.showbtn = true
								this.attcode = false
							}
						}, 1000)
						this.$message.success(data.data);
					} else {
						this.$message.error(data.message);
					}
				}
			},
			//手机安全+钱包地址
			async handleSafe(wallet, mobile, code) {
				let {
					status,
					data
				} = await Personal.safe(this.submitForm);
				if (status) {
					if (data.code == 200) {
						this.$message.success(data.data);
						this.$router.push('/Home/index');
					} else {
						this.$message.error(data.message);
					}
				}
			},
		},
	}
</script>

<style lang="less">
	.binding {
		margin: 5vw 5vw;

		.title {
			font-size: 7vw;
			margin: 5vw 0;
		}
		.little_title {
			font-size: 4vw;
		}
		.van-field__control {
			border: 0.2vw solid #eee;
			border-radius: 2vw;
			padding: 1vw 3vw;
		}

		.van-cell {
			padding: 10px 0;
		}

		.van-cell::after {
			width: 0;
		}

		.van-button {
			color: #124DA5 !important;
			border-radius: 3vw;
			width: 40vw;
			height: 10vw;
			margin-left: 5vw;
			margin-top: 0;
		}

		.message {
			display: flex;
			align-items: center;
		}
		.bottom {
			.van-button {
				color: #FFFFFF !important;
				border-radius: 3vw;
				width: 90vw;
				height: 12vw;
				margin-left: 0;
				margin-top: 30vw;
			}
		}
		input{
		    -webkit-appearance: none;
		}
	}
</style>
