<template>
	<div class="details">
		<p class="title">我的质押详情</p>
		<p class="tit">信息</p>
		<div class="info_box">
			<div class="line">
				<div class="name">套餐</div>
				<div class="text">{{tableData.meal.title}}</div>
			</div>
			<div class="line">
				<div class="name">份数</div>
				<div class="text">{{tableData.buy_number}}</div>
			</div>
			<div class="line">
				<div class="name">质押</div>
				<div class="text">{{tableData.total_meals}}</div>
			</div>
			<!-- <div class="line">
				<div class="name">Gas</div>
				<div class="text">{{tableData.total_gas}}</div>
			</div> -->
			<div class="line">
				<div class="name">释放天数(倒计时)</div>
				<div class="text">
					<span>{{tableData.release_days + tableData.waiting_days}}</span>
					<span v-if="tableData.lose_release_days != -1">({{tableData.lose_release_days}})</span>
					<span v-if="tableData.lose_release_days == 0">可释放</span>
				</div>
			</div>
			<div class="line">
				<div class="name">开始时间</div>
				<div class="text">{{dateFmt(tableData.start_time)}}</div>
			</div>
			<div class="line">
				<div class="name">到期时间</div>
				<div class="text">{{dateFmt(tableData.end_time)}}</div>
			</div>
			<div class="line">
				<div class="name">生效天数(倒计时)</div>
				<div class="text">
					<span>{{tableData.waiting_days}}</span>
					<span v-if="tableData.lose_waiting_days != -1">({{tableData.lose_waiting_days}})</span>
					<span v-if="tableData.lose_waiting_days == 0">已生效</span>
				</div>
			</div>
			<div class="line">
				<div class="name">可划转余额抵扣</div>
				<div class="text">{{tableData.coins}}</div>
			</div>
			<div class="line">
				<div class="name">状态</div>
				<div class="text">
					<span v-if="tableData.status==0" style="color: #1989FA;">审核中</span>
					<span v-if="tableData.status==1" style="color: #67C23A;">审核通过</span>
					<span v-if="tableData.status==2" style="color: #F56C6C ;">审核未通过</span>
					<span v-if="tableData.status==3" style="color: #F56C6C;">已到期</span>
				</div>
			</div>
			<div class="audit" v-if="tableData.status==1 || tableData.status==2">
				<div class="info">审核备注</div>
				<div class="verify">{{tableData.verify_detail}}</div>
			</div>
		</div>
	</div>
</template>

<script>
import {Ecological} from "@/api/index";
export default {
	data() {
	  return {
		active: 0,
		recordskong:false,
		recordsnokong:false,
	   id:'',
	   tableData: {
		   meal:{}
	   },
	    page1: {
	      current_page: 1,
	      pagesize: 10,
	      total:0,
	     },
	   records:[],
	   }
	},
	created() {
		let { id } = this.$route.query;
		if (this.id == ''){
			this.id = id
		}
		
		this.Packagedetails()
	},
	methods:{
		async Packagedetails() {
			let { status, data } = await Ecological.packagedetails({id:this.id}); 
			if (status) {
				if(data.code == 200){
					this.tableData = data.data;
				}
			}
		},
		//开始结束时间切割
		dateFmt(date) {
			if (date == null || date == undefined){
				return '';
			}
			return date.slice(0,11);
		},
	}
}
</script>

<style lang="less">
	.details {
		padding: 5vw 5vw 20vw 5vw;
		.title {
			text-align: center;
			font-weight: bold;
		}
		.tit {
			font-size: 4.5vw;
			font-weight: bold;
			color: #124DA5;
			padding-bottom: 5vw;
		}
		.tec_tit {
			font-size: 4.5vw;
			font-weight: bold;
			color: #124DA5;
			padding-bottom: 5vw;
			padding-top: 5vw;
		}
		.info_box {
			box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
			border-radius: 4vw;
			padding: 5vw 5vw 2vw 5vw;
			background-color: #F1F5FA;
			.line {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-bottom: 4vw;
				.name {
					font-size: 4vw;
					color: #999999;
				}
				.text {
					font-size: 4vw;
					color: #333333;
				}
			}
			.audit {
				display: flex;
				padding-bottom: 4vw;
			}
			.info {
				font-size: 4vw;
				color: #999999;
				width: 25vw;
			}
			.verify {
				font-size: 4vw;
				color: #333333;
				width: 70vw;
				text-align: justify;
				line-height: 150%;
			}
		}
		.assets-list {
			.item {
				padding: 5vw 0;
				border-bottom: 0.2vw solid #eee;
				.top {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-bottom: 1.5vw;
					font-size: 4vw;
					color: #333333;
					font-weight: bold;
					.van-icon {
						color: #999999;
					}
					.status {
						color: #124DA5;
						margin: 0;
					}
				}
				.bottom {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-top: 1.5vw;
		
					.date {
						color: #999999;
					}
		
					.num {
						color: #124DA5;
					}
		
					.price {
						span {
							color: #E80000;
						}
					}
				}
			}
		}
	}
</style>