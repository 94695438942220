<template>
	<div>
		<van-tabbar v-model="active">
		  <van-tabbar-item to="/Home/index" icon="home-o">首页</van-tabbar-item>
		  <van-tabbar-item to="/Server/list" icon="orders-o">管理</van-tabbar-item>
		  <!-- <van-tabbar-item to="/Buy/first" ><van-icon name="add" color="#124DA5" size="10vw" /></van-tabbar-item> -->
		  <van-tabbar-item to="/Assets/assets" icon="apps-o">通证</van-tabbar-item>
		  <!-- <van-tabbar-item :to="geturl()" icon="apps-o">{{Agentform.tab}}</van-tabbar-item> -->
		  <van-tabbar-item to="/Info/personal" icon="manager-o">我的</van-tabbar-item>
		</van-tabbar>
	</div>
</template>

<script>
	import { Personal } from "@/api/index";
export default {
	data() {
	  return {
		agent:false,
		noagent:false,
		Agentform:{
			tab:'',
		},
	  };
	},
	created() {
		// this.Agent()
	},
	methods: {
		// async Agent() {
		// 	let { status, data } = await Personal.info();
		// 	if (status) {
		// 		this.Agentform = data.data;
		// 		if(this.Agentform.agent > 1){
		// 			this.agent = true
		// 			this.Agentform.tab = '分销'
		// 		}else {
		// 			this.noagent = true
		// 			this.Agentform.tab = '通证'
		// 		}
		// 		this.$store.commit('setBase',{
		// 			agent:data.data.agent,
		// 			agent_name:data.data.agent_name,
		// 			agent_partner:data.data.agent_partner,
		// 			market:data.data.market,
		// 			next_agent:data.data.next_agent,
		// 			status:data.data.status,
		// 			username:data.data.username,
		// 			grade:data.data.grade
		// 		})
		// 	}else {
		// 		this.noagent = true
		// 		this.Agentform.tab = '通证'
		// 	}
		// },
		// geturl(){
		// 	if (this.agent){
		// 		return '/Distribution/list'
		// 	}else{
		// 		return '/Assets/assets'
		// 	}
		// },
		
	},
	  computed: {
	    active: {
	      get(){
	        switch (this.$route.path) {
	        case "/Home/index":
	          return 0;
			case "/Assets/assets":
			  return 2;
			case "/Assets/list":
			  return 2;
			case "/Operating/list":
			  return 0;
			case "/Operating/record":
			  return 0;
	        case "/Server/list":
	          return 1;
			case "/Server/detail":
			  return 1;
	        case "/Buy/first":
	          return 2;
			case "/Buy/second":
			  return 2;
			        case "/Assets/assets":
			          return 3;
					  case "/Assets/list":
					    return 3;
	  //       case "/Distribution/list":
	  //         return 3;
			// case "/Distribution/Contribution":
			//   return 3;
			// case "/Distribution/application":
			//   return 3;
			// case "/Distribution/cashdetail":
			//   return 3;
			// case "/Distribution/contributiondetail":
			//   return 3;
			// case "/Distribution/exchange":
			//   return 3;
			// case "/Distribution/exchangedetail":
			//   return 3;
			// case "/Distribution/tradingdetail":
			  // return 3;
	        case "/Info/personal":
	          return 4;
			case "/Info/certification":
			  return 4;
			case "/Info/opinion":
			  return 4;
			case "/Info/password":
			  return 4;
			case "/Info/wallet":
			  return 4;
	        default:
	          break;
	      }
	      },
	      set(){}
	    }
	  },
};
</script>

<style lang="less">

</style>
