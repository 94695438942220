import axios from "axios"
//登录
let login = (formData) => axios.post("/api/login",formData);
//注册
let register = (formData) => axios.post("/api/register",formData);
//退出登录
let logout = (formData={}) => axios.post("/api/logout",formData);
//忘记密码
let forget = (data = {}) => axios.post("/api/forget_password",data);
//验证短信验证码
let check = (formData={}) => axios.post("/api/check_code",formData);
//发送短信验证码(忘记密码)
let sendsms = (data = {}) => axios.post('/api/sendsms',{scene:'forget_password',phone:data.mobile });
export default{
    register,
    login,
	logout,
	forget,
	check,
	sendsms,
}