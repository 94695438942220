import axios from 'axios';
//分销详情
let details = (data = {}) => axios.get('/api/agent', { params:data });
//交易明细
let deallist = (data) => axios.get('/api/agent/detail', { params:{ty:1,page:data.page} });
//提现记录
let withdrawal = (data) => axios.get('/api/agent/record', { params:{ty:1,page:data.page} });//
//贡献值列表
let contributionlist = (data) => axios.get('/api/agent/detail', { params:{ty:2,page:data.page} });
//兑换记录
let exchange = (data) => axios.get('/api/agent/record', { params:{ty:2,page:data.page} });
//佣金提现申请
let apply = (data = {}) => axios.post('/api/agent/apply',{ty:1,nums:data.nums});
//贡献值申请提现
let contributionapply = (data = {}) => axios.post('/api/agent/apply',{ty:2,nums:data.nums});
//交易明细详情
let tradingdetails = (data = {}) => axios.get('/api/agent/detail/'+data.id, { params:data });
//申请记录详情
let recorddetail = (data = {}) => axios.get('/api/agent/record/'+data.id, { params:data});
export default {
	details,
	deallist,
	withdrawal,
	contributionlist,
	exchange,
	apply,
	contributionapply,
	tradingdetails,
	recorddetail,
}