<template>
	<div class="trading_detail">
		<div class="trading_number">
			<div class="text">数额</div>
			<div class="number">{{Tradingdetailsform.money}}</div>
		</div>
		<div class="trading_bottom">
				<div class="line">
					<span class="title">用户</span>
					<span class="content" v-if="Tradingdetailsform.aboutuser!==null">{{Tradingdetailsform.aboutuser.username}}</span>
				</div>
				<div class="line">
					<span class="title">类型</span>
					<span class="content">现金</span>
				</div>
				<div class="line">
					<span class="title">明细</span>
					<span class="content">{{Tradingdetailsform.content}}</span>
				</div>
				<div class="line">
					<span class="title">时间</span>
					<span class="content">{{Tradingdetailsform.thetime}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Distribution } from "@/api/index";
	export default {
		data() {
			return {
				id:'',
				Tradingdetailsform:{aboutuser:{}},
			};
		},
		created() {
			let { id } = this.$route.query;
			if (this.id == ''){
				this.id = id
			}
			this.Tradingdetails()
		},
		methods: {
			async Tradingdetails() {
				let { status, data } = await Distribution.tradingdetails({id:this.id});
				if (status) {
					this.Tradingdetailsform = data.data;
				}
			},
		}
	};
</script>

<style lang="less">
	.trading_detail {
		.trading_number {
			padding: 5vw 5vw;
			border-bottom: 0.2vw solid #eee;
			.text {
				font-size: 3vw;
				color: #333333;
			}
			.number {
				font-size: 7vw;
				color: #333;
				font-weight: bold;
				padding-top: 3vw;
			}
		}
		.trading_bottom {
			margin: 10vw 5vw 0;
			.line {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 3vw 0;
				.title {
					font-size: 4vw;
					color: #999999;
				}
				.content {
					font-size: 4vw;
					color: #333333;
				}
			}
		}
	}
</style>
