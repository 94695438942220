import axios from 'axios';
//概况总览
let total = (data = {}) => axios.get('/api/user/my', { params:data });
//运营奖励列表
let operating = (data = {}) => axios.get('/api/user/partner_list', { params:data });
//运营奖励转让
let transfer = (data = {}) => axios.post('/api/user/transfer',data);
//运营奖励详情
let operatingdetail = (data = {}) => axios.get('/api/user/partner_list/'+data.id, { params:data});
//运营奖励转出记录
let transferlist = (data = {}) => axios.get('/api/user/transfer/list', { params:data });
//运营奖励转出记录详情
let transferdetail = (data = {}) => axios.get('/api/user/transfer/'+data.id, { params:data});
export default {
	total,
	operating,
	transfer,
	operatingdetail,
	transferlist,
	transferdetail,
}