import axios from 'axios';
//用户服务器列表
let userlist = (data = {}) => axios.get('/api/user_mechines', { params:data });
//服务器列表
let servelist = (data) => axios.get('/api/mechine_pools', { params:{page:data.page} });
//用户矿机详情
let kuangInfo = (data = {}) => axios.get('/api/user_mechines/'+data.id, {params:{}});
//用户矿机删除
let deleteInfo = (data = {}) => axios.delete('/api/user_mechines/'+data.id, {params:{}});
export default {
	userlist,
	servelist,
	kuangInfo,
	deleteInfo
}