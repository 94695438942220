<template>
	<div class="transfer">
		<div class="transfer_top">
			<div class="text">转出金额</div>
			<div class="content">
				<div class="number">{{Transferdetailform.money}}</div>
			</div>
		</div>
		<div class="transfer_bottom">
			<div class="line">
				<span class="title">所属服务器</span>
				<span class="content">{{Transferdetailform.userkuang}}</span>
			</div>
			<div class="line">
				<span class="title">接收人</span>
				<span class="content">{{Transferdetailform.aboutuser.username}}</span>
			</div>
			<div class="line">
				<span class="title">时间</span>
				<span class="content">{{Transferdetailform.thetime}}</span>
			</div>
			<div class="line">
				<span class="title">备注</span>
				<span class="content">{{Transferdetailform.content}}</span>
			</div>
		</div>
	</div>
</template>

<script>
import { Home } from "@/api/index";
	export default {
		data() {
			return {
				id:'',
				Transferdetailform:{aboutuser:{}},
				 form:{},
			};
		},
		created() {
			let { id } = this.$route.query;
			if (this.id == ''){
				this.id = id
			}
			this.Transferdetail()
		},
		methods: {
			async Transferdetail() {
				let { status, data } = await Home.transferdetail({id:this.id});
				if (status) {
					this.Transferdetailform = data.data;
				}
			},
		},
		
	};
</script>

<style lang="less">
	.transfer {
		.transfer_top {
			margin: 5vw 5vw;
			.text {
				font-size: 4.5vw;
				font-weight: bold;
			}
			.content {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 2vw;
				.number {
					font-size: 6vw;
					font-weight: bold;
					color: #E80000;
				}
				.btn {
					.van-button {
						border-radius: 2vw;
						height: 9vw;
						padding: 0 6vw;
					}
				}
			}
		}
		.transfer_bottom {
			margin: 10vw 5vw 0;
			.line {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 3vw 0;
				.title {
					font-size: 4vw;
					color: #999999;
				}
				.content {
					font-size: 4vw;
					color: #333333;
				}
			}
		}
		.van-dialog {
			.dialog {
				margin: 0 5vw;
			}
			.cash_num {
				margin: 2vw 5vw 0;
				color: #124DA5;
				font-weight: bold;
			}
			.van-cell__value {
				border-bottom: 0.1vw solid #ccc;
			}
			.van-cell::after {
				width: 0;
			}
		}
	}
	
</style>
