<template>
	<div class="application_box1">
		<div class="application">
			<div class="text">可提现金额</div>
			<div class="price">￥{{tableForm.money}}</div>
			<van-cell-group>
			  <van-field v-model="nums"   placeholder="请输入提现金额" />
			</van-cell-group>
			<div class="go_all" @click="handleAll(nums)">全部提现</div>
			
		</div>
		<div class="application_bottom">
			<van-button class="btn" color="#124DA5" @click="handleApply(nums)">申请提现</van-button>
		</div>
	</div>
</template>

<script>
	import { Distribution } from "@/api/index";
export default {
  data() {
    return {
      nums: '',
	  tableForm:[],
    };
  },
  created() {
  	this.Details()
  },
  methods: {
	  async Details() {
	  		let { status, data } = await Distribution.details(); 
	  		if (status) {
	  			this.tableForm = data.data;
	  		}
	  	},
		handleAll (nums) {
			this.nums = this.tableForm.money;
		},
		 async handleApply (nums) {
				let { status, data} = await Distribution.apply({nums:nums});
				if(status) {
					if(data.code == 200){
						this.$message.success("操作成功");
						this.Details();
						this.$router.replace('/Distribution/list')
					}else{
						this.$message.error(data.message);	
					}
				}
			},
  }
};
</script>

<style lang="less">
	.application_box1 {
		.application {
			margin-top: 10vw;
			padding-bottom: 6vw;
			border-bottom: 0.2vw solid #eee;
			.van-cell {
				border: 0.2vw solid #124DA5;
				width: 50%;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				border-radius: 4vw;
			}
			.text {
				text-align: center;
				font-size: 3vw;
				color: #333;
				font-weight: bold;
			}
			.price {
				text-align: center;
				font-size: 3vw;
				color: #333;
				padding-top: 3vw;
				padding-bottom: 5vw;
			}
			.go_all {
				text-align: center;
				margin-top: 16vw;
				font-size: 3.5vw;
				color: #124DA5;
			}
		}
		[class*=van-hairline]::after {
			width: 0;
		}
		.application_bottom {
			padding: 5vw;
			.btn {
				border-radius: 1vw;
				width: 90vw;
			}
		}
		
	}
</style>
