<template>
  <div class="assets_list">
    <div class="page_title">数据中心</div>
    <div class="asset_detail">
      <div class="filecoin"></div>
      <div class="asset-items">
        <div class="item">
          <p class="text">实际可提现</p>
          <p class="number">{{ Formdata.data.cash_int }}</p>
        </div>
        <div class="item">
          <p class="text">总可提现</p>
          <p class="number">{{ Formdata.data.cash }}</p>
        </div>
        <div
          style="display: none"
          class="item"
          v-if="this.$store.state.base.id == 1568"
        >
          <p class="text">总质押数</p>
          <p class="number">15000</p>
        </div>
        <div class="item" v-else>
          <p class="text">总锁仓数</p>
          <p class="number">{{ Formdata.data.locked }}</p>
        </div>
      </div>
    </div>
    <div class="asset_total">
      <div class="text">总通证数</div>
      <div class="number">{{ Formdata.data.total }}</div>
    </div>
    <div class="btn_operation">
      <van-button v-if="judge_realname" type="info" @click="goAuthentication()"
        >请实名认证</van-button
      >
      <van-button
        v-if="judge_wallet"
        plain
        type="info"
        @click="onClickvalidation"
        >钱包与手机验证</van-button
      >
    </div>
    <div class="btn_withdrawal">
      <van-button
        v-if="judge_withdrawal"
        plain
        type="info"
        @click="onClickMultiple"
        style="display: none;!important"
        disabled
        >批量提现</van-button
      >
    </div>
    <div class="title">数据中心管理</div>
    <div class="pool" v-for="item in mineform" @click="goParam(item)">
      <div class="pool_name">
        <span>{{ item.pool_name }}</span>
        <van-icon name="arrow" />
      </div>
      <div class="terms">
        <div class="item">
          <p class="text">可提现数量：{{ item.cash }}</p>
          <p class="text">锁仓数量：{{ item.locked }}</p>
          <div class="installment" v-if="item.stage == true">
            <div class="text">
              <div>自由分期：{{ item.stage_cash }}</div>
            </div>
            <div class="text">
              <div>锁仓分期：{{ item.stage_locked }}</div>
              <div style="display: none">
                <span>倒计时:{{ item.lose_release_days }}天</span>
              </div>
            </div>
          </div>
          <p class="text" v-if="item.release_180 > 0">
            180天锁仓数量：{{ item.release_180 }}
          </p>
        </div>
      </div>
    </div>
    <van-dialog
      v-model="show"
      title="申请提现"
      confirmButtonText="提现"
      show-cancel-button
      @confirm="handleMultiple(nums)"
    >
      <div class="dialog">实际可提现总数</div>
      <div class="cash_num">{{ Formdata.data.cash_int }}</div>
      <van-field
        v-model="nums"
        type="digit"
        label="输入提现数量"
        placeholder="提现数量格式为正整数"
      />
    </van-dialog>
    <van-popup
      v-model="noteshow"
      closeable
      position="bottom"
      :style="{ height: '30%' }"
    >
      <p class="title">温馨提示</p>
      <p class="content">1、批量提现时只能提取每个数据中心的整数部分;</p>
      <p class="content">2、最低提取数量要大于1才能提取；</p>
      <p class="content">
        3、强烈建议，每次提取数量最好累加到10个左右再统一提取，以减少损耗；
      </p>
      <p class="content">4、请完成实名认证、手机安全验证后再进行提现操作</p>
    </van-popup>
  </div>
</template>

<script>
import { Property, Personal } from "@/api/index";
export default {
  data() {
    return {
      noteshow: true,
      show: false,
      Formdata: { data: {} },
      mineform: [],
      judge_withdrawal: false,
      judge_realname: false,
      judge_wallet: false,
      infoform: [],
      mineform: [],
      nums: "",
    };
  },
  created() {
    this.Cash();
    this.Pools();
    this.Ifinfo();
  },
  methods: {
    goAuthentication() {
      this.$router.push("/Info/certification");
    },
    async Cash() {
      let { status, data } = await Property.cash();
      if (status) {
        this.Formdata = data;
        this.Ifinfo();
      }
    },
    async Pools() {
      let { status, data } = await Property.pools();
      if (status) {
        this.mineform = data.data;
      }
    },
    goParam(item) {
      this.$router.push({
        path: "list?pool_id=" + item.pool_id,
        params: { pool_id: item.pool_id },
      });
    },
    //判断信息
    async Ifinfo() {
      let { status, data } = await Personal.info();
      if (status) {
        this.infoform = data.data;

        //提现判断
        this.judge_withdrawal =
          this.infoform.status == 5 &&
          this.Formdata.data.cash > 0 &&
          this.infoform.mobile_status == 1;
        //实名判断
        this.judge_realname = this.infoform.status != 5;
        //钱包判断
        this.judge_wallet =
          (this.infoform.wallet == "" || this.infoform.wallet == null) &&
          this.infoform.mobile_status == 0;
      }
    },
    //提现按钮
    onClickMultiple() {
      this.show = true;
    },
    //批量提现
    async handleMultiple(nums) {
      let { status, data } = await Property.multiple({ nums: nums }, this.form);
      if (status) {
        if (data.code == 200) {
          this.$message.success(data.data);
          this.Cash();
          this.Pools();
          this.Ifinfo();
        } else {
          this.$message.error(data.message);
        }
      }
    },
    //验证按钮
    onClickvalidation() {
      this.$router.push("/Info/binding");
    },
  },
};
</script>

<style lang="less">
.assets_list {
  padding-bottom: 20vw;
  background-color: #f8f8fb;
  .page_title {
    font-size: 8vw;
    font-weight: bold;
    color: #333333;
    padding: 5vw 5vw 0vw 5vw;
  }
  .filecoin {
    margin: 10vw 5vw 2vw;
    .text {
      vertical-align: middle;
      color: #ffffff;
      font-size: 3.5vw;
      margin-right: 2vw;
    }
    img {
      vertical-align: middle;
    }
  }
  .asset_total {
    background-color: #ffffff;
    margin: 0 5vw;
    padding: 5vw 4vw;
    box-sizing: border-box;
    border-radius: 4vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text {
      font-size: 4vw;
      font-weight: bold;
    }
    .number {
      font-size: 4vw;
      color: #3988ff;
    }
  }
  .asset_detail {
    background: url(../../assets/img/asset_background.png) no-repeat center
      center;
    background-size: contain;
    padding: 25vw 0;
    margin: 0 5vw;
    position: relative;
    .filecoin {
      position: absolute;
      top: 0vw;
      left: 0vw;
      color: #ffffff;
    }
    .asset-items {
      display: flex;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80vw;
      .item {
        width: 49.5vw;
        text-align: center;
        .number {
          color: #ffffff;
          font-weight: bold;
          font-size: 4vw;
          padding-top: 4vw;
        }
        .text {
          color: #ffffff;
          font-size: 3vw;
        }
      }
    }
  }
  .btn_operation {
    // padding: 0 5vw;
    margin: 2vw 5vw;
    .van-button {
      width: 49%;
      border-radius: 3vw;
      margin-top: 1vw;
    }
    .van-button:first-child {
      margin-right: 2%;
    }
  }
  .btn_withdrawal {
    // padding: 0 5vw;
    margin: 2vw 5vw;
    .van-button {
      width: 100%;
      border-radius: 3vw;
      margin-top: 0;
    }
  }
  .title {
    margin: 5vw 5vw;
    color: #124da5;
    font-weight: bold;
  }
  .pool {
    margin: 5vw 5vw;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 3vw;
    .pool_name {
      padding: 3vw 5vw;
      background-color: #f9fafc;
      color: #999999;
      font-size: 4vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .van-icon {
        color: #124da5;
      }
    }
    .terms {
      .item {
        width: 80vw;
        padding: 5vw 5vw;
        background-color: #ffffff;
        .text {
          color: #333333;
          font-size: 4vw;
          padding-top: 2vw;
        }
        .text:first-child {
          padding-top: 0;
        }
        .installment {
          .text {
            color: #333333;
            font-size: 4vw;
            padding-top: 2vw;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
              vertical-align: middle;
              color: #0486fe;
            }
            .van-icon {
              vertical-align: middle;
              margin-left: 2vw;
            }
          }
        }
      }
    }
  }
  .van-dialog {
    .dialog {
      margin: 0 5vw;
    }
    .cash_num {
      margin: 2vw 5vw 0;
      color: #124da5;
      font-weight: bold;
    }
    .van-cell__value {
      border-bottom: 0.1vw solid #ccc;
    }
    .validation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .van-button {
        width: 40vw;
        margin-right: 2vw;
      }
    }
  }
  .van-popup {
    height: 60% !important;
    border-top-left-radius: 8vw;
    border-top-right-radius: 8vw;
    .title {
      text-align: center;
      margin-top: 5vw;
      margin-bottom: 10vw;
      font-size: 4.5vw;
      color: #333333;
      font-weight: bold;
    }
    .content {
      padding: 1vw 5vw;
      font-size: 4vw;
      color: #333333;
      line-height: 7vw;
    }
  }
}
</style>
