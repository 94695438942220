<template>
	<div class="modification">
		<div class="text">忘记密码</div>
		<van-form>
		  <van-field
		    v-model="submitForm.password"
		    type="password"
		    name="password"
		    label="新密码"
		    placeholder="请输入新密码"
		  />
		  <van-field
		    v-model="submitForm.sec_password"
		    type="password"
		    name="sec_password"
		    label="再次输入新密码"
		    placeholder="请再次输入新密码"
		  />
		  <div style="margin: 16px;">
		    <van-button round block color="#124DA5" @click="handleCommit(submitForm)">完成</van-button>
		  </div>
		</van-form>
	</div>
</template>

<script>
	import {
			Admin
		} from "@/api/index";
export default {
  data() {
    return {
		submitForm: {
			password: '',
			sec_password:'',
		},
    };
  },
  created() {
  	this.token = this.$route.query.token;
  	if (this.token == ''){
  		this.token = token
  	}
  },
  methods: {
	  async handleCommit(submitForm) {
		  let {
		  	status,
		  	data
		  } = await Admin.forget({
			  password:submitForm.password,
			  sec_password:submitForm.sec_password,
			  mytoken:this.token
		  });
		  if (status) {
		  	if (data.code == 200) {
		  		this.$message.success('修改成功');
				this.$router.push('/login')
		  	} else {
		  		this.$message.error(data.message);
		  	}
		  }
	  }
  },
};
</script>

<style lang="less">
	.modification {
		.text {
			font-size: 5vw;
			color: #333333;
			text-align: center;
			font-weight: bold;
			margin-top: 3vw;
		}
		.van-button {
			margin-top: 14vw;
			border-radius: 3vw;
		}
		.van-field__label {
			margin-bottom: 1vw;
		}
		.van-cell {
			display: block;
		}
		.van-field__control {
			border: 1px solid #E5E5E5;
			border-radius: 2vw;
			padding: 2vw;
		}
		.van-cell::after {
			display: none;
		}
		.warn {
			display: flex;
			align-items: center;
			font-size: 3vw;
			color: #D40808;
			padding: 0 16px;
			.font {
				margin-left: 2vw;
			}
		}
		.van-form {
			margin: 10vw 5vw;
			.van-radio {
				overflow: visible;
			}
		}
		.van-radio {
			font-size: 3.5vw;
			margin-left: 3.5vw;
			margin-top: 5vw;
			color: #999999;
			.aggrement {
				color: #016FE1;
			}
		}
		.van-field__label {
			width: 7.2em;
		}
		.message {
			position: relative;
			.van-field__control {
				border: 1px solid #E5E5E5;
				border-radius: 2vw;
				padding: 2vw;
				width: 70%;
			}
			.van-button {
				margin: 0;
				position: absolute;
				right: 0;
				bottom: 16px;
				width: 30%;
			}
		}
	}
	
</style>
