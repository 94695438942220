<template>
	<div>
		<div class="buy_number">
			<div class="text">登记数量</div>
			<div class="number">{{Kuangform.nums}}台</div>
		</div>
		<div class="buy_bottom">
			<div class="line">
				<span class="title">产品名称</span>
				<span class="content">{{Kuangform.kuangname}}</span>
			</div>
			<div class="line">
				<span class="title">所在数据中心</span>
				<span class="content">{{Kuangform.changname}}</span>
			</div>
			<div class="line">
				<span class="title">产品参数</span>
				<span class="content">{{Kuangform.pan}}盘{{Kuangform.ti}}T</span>
			</div>
			<div class="line">
				<span class="title">套餐(倒计时/天)</span>
				<span style="color: #1989FA;" class="content"  v-if="Kuangform.set_meal === 1">自购48T</span>
				<span style="color: #1989FA;" class="content"  v-if="Kuangform.set_meal === 2">自购96T</span>
				<span style="color: #1989FA;" class="content"  v-if="Kuangform.set_meal === 3">
					<span>48T生态包</span>
					<span>{{Kuangform.waiting_days}}</span>
					<span v-if="Kuangform.lose_waiting_days != -1">({{Kuangform.lose_waiting_days}})</span>
					<span v-if="Kuangform.lose_waiting_days == 0">已生效</span>
				</span>
				<span style="color: #1989FA;" class="content"  v-if="Kuangform.set_meal === 4">
					<span>96T生态包</span>
					<span>{{Kuangform.waiting_days}}</span>
					<span v-if="Kuangform.lose_waiting_days != -1">({{Kuangform.lose_waiting_days}})</span>
					<span v-if="Kuangform.lose_waiting_days == 0">已生效</span>
				</span>
				<span style="color: #1989FA;" class="content"  v-if="Kuangform.set_meal === 0"></span>
			</div>
			<div class="line">
				<span class="title">开始时间</span>
				<span class="content">{{Kuangform.firstday}}</span>
			</div>
			<div class="line">
				<span class="title">到期时间</span>
				<span class="content">{{Kuangform.endtime}}</span>
			</div>
			<div class="line">
				<span class="title">服务器状态</span>
				<span class="content">
					<span style="color: #84CE61;" v-if="Kuangform.status===5">运行中</span>
					<span style="color: #FF8C8D;" v-if="Kuangform.status===4">停机中</span>
					<span style="color: #1989FA;" v-if="Kuangform.status===3">审核不过</span>
					<span style="color: #1989FA;" v-if="Kuangform.status===2">审核中</span>
					<span style="color: #FF8C8D;" v-if="Kuangform.status===1">待上传</span>
				</span>
			</div>
		</div>
		<div class="buy_btn" v-if="Kuangform.status===5">
			<van-button color="#FF5722" style="display: none;">续费托管</van-button>
		</div>
		<div class="buy_btn" v-if="Kuangform.status===4">
			<van-button color="#FF5722" style="display: none;">续费托管</van-button>
		</div>
		<div class="buy_btn" v-if="Kuangform.status===3">
			<van-button color="#D4D4D4" @click="handleDelete">删除</van-button>
			<van-button color="#FF5722" @click.native="goPay(Kuangform.id)">重新提交</van-button>
		</div>
		<div class="buy_btn" v-if="Kuangform.status===2">
			<van-button color="#FF5722" style="width: 90vw;">请耐心等待</van-button>
		</div>
		<div class="buy_btn" v-if="Kuangform.status===1">
			<van-button color="#D4D4D4" @click="handleDelete">删除</van-button>
			<van-button color="#FF5722" @click.native="goPay(Kuangform.id)">去上传</van-button>
		</div>
		<van-dialog v-model="show" title="您确定要删除吗？" confirmButtonText='确定' show-cancel-button @confirm="ulDelete(Kuangform.id)">
		</van-dialog>
	</div>
</template>

<script>
	import { Server } from "@/api/index";
		export default {
		  data() {
		    return {
		     id:'',
			 Kuangform:{},
			 show: false,
		    };
		  },
		  created() {
			let { id } = this.$route.query;
			if (this.id == ''){
				this.id = id
			}
			this.KuangInfo()
		  },
		  methods: {
			async KuangInfo() {
				let { status, data } = await Server.kuangInfo({id:this.id});
				if (status) {
					this.Kuangform = data.data;
				}
			},
			//删除按钮
			handleDelete() {
				this.show = true
			},
			async ulDelete(kuang_id){
				let { status, data } = await Server.deleteInfo({id:kuang_id});
				if (status) {
					if(data.code == 200){
						this.$message.success('删除成功');
						this.$router.push('/Server/list');
					}else{
						this.$message.error(data.message)
					}
				}
			},
			//去上传
			goPay(kuang_id){
				this.$router.push({path:"/Buy/second?kuang="+kuang_id,params:{kuang:kuang_id}})
			},
		  }
		};
</script>

<style lang="less">
	.buy_number {
		padding: 5vw 5vw;
		border-bottom: 0.2vw solid #eee;
		.text {
			font-size: 3vw;
			color: #333333;
		}
		.number {
			font-size: 6vw;
			font-weight: bold;
			color: #333333;
			padding-top: 2vw;
		}
	}
	.buy_bottom {
		margin: 10vw 5vw 0;
		.line {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 3vw 0;
			.title {
				font-size: 4vw;
				color: #999999;
			}
			.content {
				font-size: 4vw;
				color: #333333;
			}
		}
	}
	.buy_btn {
		margin: 30vw 5vw 0;
		.van-button {
			border-radius: 2vw;
		}
		.van-button:first-child {
			margin-right: 2vw;
			width: 33vw;
		}
		.van-button:last-child {
			width: 55vw;
		}
	}
</style>
