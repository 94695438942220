<template>
	<div class="announcement_all">
		<p class="CN">公告列表</p>
		<p class="EN">Announcement of the list</p>
		<div class="announcement_list" v-for="item in form">
			<div class="item">
				<div class="title">{{item.title}}</div>
				<div class="date">{{item.pubtime}}</div>
			</div>
		</div>
	</div>
</template>


<script>
	import { Announcement } from "@/api/index";
	export default {
	    data() {
	      return {
			centerDialogVisible: true,
			form: [],
		  };
		},
		created() {
			this.Announcementlist()
			},
	methods: {
			async Announcementlist() {
					let { status, data } = await Announcement.list(); 
					if (status) {
						this.form = data.data.data;
					}
				},
	},
	  };
</script>

<style lang="less">
	.announcement_all {
		margin:5vw 5vw 0;
		.CN {
			font-size: 6vw;
			color: #333333;
			font-weight: bold;
			padding-bottom: 1vw;
		}
				
		.EN {
			font-size: 3vw;
			color: #999999;
		}
		.announcement_list {
			margin-top: 10vw;
			.item {
				padding: 2vw 0;
				margin-bottom: 3vw;
				border-bottom: 0.2vw solid #eeeeee;
				.title {
					font-size: 3.5vw;
					color: #333333;
				}
				.date {
					font-size: 3.5vw;
					text-align: right;
					color: #999999;
					padding-top: 3vw;
				}
			}
		}
	}
</style>
