<template>
	<div class="cash_detail">
		<div class="cash_number">
			<div class="text">数额</div>
			<div class="number">{{Recorddetailform.money}}</div>
		</div>
		<div class="cash_bottom">
				<div class="line">
					<span class="title">明细</span>
					<span class="content">{{Recorddetailform.content}}</span>
				</div>
				<div class="line">
					<span class="title">状态</span>
					<span class="content" v-if="Recorddetailform.status===5" style="color: #84CE61;">审核通过</span>
					<span class="content" v-if="Recorddetailform.status===3" style="color: #FF8C8D;">审核失败</span>
					<span class="content" v-if="Recorddetailform.status===2" style="color: #1989FA;">审核中</span>
					<span class="content" v-if="Recorddetailform.status===1" style="color: #1989FA;">待审核</span>
				</div>
				<div class="line">
					<span class="title">申请时间</span>
					<span class="content">{{Recorddetailform.applytime}}</span>
				</div>
				<div class="line">
					<span class="title">审核时间</span>
					<span class="content">{{Recorddetailform.verifytime}}</span>
				</div>
				<div class="line">
					<span class="title">备注</span>
					<span class="info_content">{{Recorddetailform.verifyinfo}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Distribution } from "@/api/index";
	export default {
		data() {
			return {
				id:'',
				Recorddetailform:[],
			};
		},
		created() {
			let { id } = this.$route.query;
			if (this.id == ''){
				this.id = id
			}
			this.Recorddetail()
		},
		methods: {
			async Recorddetail() {
				let { status, data } = await Distribution.recorddetail({id:this.id});
				if (status) {
					this.Recorddetailform = data.data;
				}
			},
		}
	};
</script>

<style lang="less">
	.cash_detail {
		.cash_number {
			padding: 5vw 5vw;
			border-bottom: 0.2vw solid #eee;
			.text {
				font-size: 3vw;
				color: #333333;
			}
			.number {
				font-size: 7vw;
				color: #333;
				font-weight: bold;
				padding-top: 3vw;
			}
		}
		.cash_bottom {
			margin: 10vw 5vw 0;
			.line {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 3vw 0;
				position: relative;
				.title {
					font-size: 4vw;
					color: #999999;
				}
				.content {
					font-size: 4vw;
					color: #333333;
				}
				.info_content {
					text-align: right;
					font-size: 3.5vw;
					color: #333333;
					width: 70vw;
					position: absolute;
					right: 0;
				}
			}
		}
	}
	
</style>

