import axios from "axios"
//矿机列表
let machinelist = (data= {}) => axios.get('/api/mechine_pools', { params:data });
//购买
let buy = (data= {}) => axios.post('/api/user_mechines/buy', data);
//上传图片
let ImageUpload = (data= {}) => axios.post('/api/upload', data,{
	headers: {
		"Content-Type": "multipart/form-data"
	}});
//上传付款凭证
let pay = (data= {}) => axios.post('/api/user_mechines/pay', data);
export default {
	machinelist,
	buy,
	pay,
	ImageUpload
}