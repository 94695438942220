<template>
	<div class="ecological">
		<div class="title">生态板块</div>
		<div class="items">
			<van-list v-model="loading" :finished="finished"  finished-text="没有更多了"  @load="onLoadPackagelist()">
				<div class="package-list">
					<div class="item" v-for="(it,index) in tableData" >
						<div class="tit">
							<div class="mask" v-if="buydisabled(it.sell_out,it.expire)"></div>
							<div>{{it.title}}</div>
							<div>
								<div v-if="it.sell_out == 0 && it.expire == true" style="color: #ffffff;">开放中</div>
								<div v-if="it.sell_out == 1 || it.expire == false" style="color: #ffffff;">已关闭</div>
								<!-- <span v-if="it.expire == false" style="color: #ffffff;">已停止</span> -->
							</div>
						</div>
						<div class="base_num">
							<div class="nums">{{it.base_number}}</div>
							<div class="text">基数</div>
						</div>
						<div class="release">
							<div class="nums">{{it.release_days + it.waiting_days}}</div>
							<div class="text">释放天数</div>
						</div>
						<div class="waiting">
							<div class="nums">{{it.waiting_days}}</div>
							<div class="text">生效天数</div>
						</div>
						<div class="status">
							
						</div>
						<div class="buy_meals">
							<van-button color="#146FF8" :disabled="buydisabled(it.sell_out,it.expire)" @click="buyopen(it)">质押<van-icon name="arrow" color="#ffffff" /></van-button>
						</div>
						<div class="number" v-if="it.total_nums != -1">总份数:{{it.total_nums}}（剩余份数:{{it.total_nums - it.total_buy_nums}}）</div>
						<div class="date" v-if="new Date(it.end_time).getTime() < new Date().getTime()" style="color: firebrick;">有效日期:{{it.start_time.slice(0,11)}}到 {{it.end_time.slice(0,11)}}</div>
						<div class="date" v-if="new Date(it.end_time).getTime() >= new Date().getTime()">有效日期:{{it.start_time.slice(0,11)}}到 {{it.end_time.slice(0,11)}}</div>
					</div>
				</div>
			</van-list>
		</div>
		<van-dialog class="pact" v-model="show" title="《智岚博丰科技有限公司生态板块操作提示》" :showConfirmButton= "false" >
			<div class="contract_content">
				<div>
					1.标准数量。在<span style="color: #E30000;">{{dateFmt(this.item.start_time)}}－{{dateFmt(this.item.end_time)}}</span>期间以{{this.item.base_number}}枚Filecoin为1份，{{this.item.gas_number}}枚为封装费用，{{this.item.pledge_number}}个为质押，一次可质押多份。Filecoin数量最终以实际交付至生态指定收取地址为准,
					指定地址是<br>[f1v7owjsy2mekckz24nvurv3eev43o26ap<br>sq6wl7a]<span style="color: #E30000;">冲币数量不足标准或者倍数有余数的，生态不予返还。</span>
				</div>
				<div>
					2.	生态用途。将转入的Filecoin用于为存储服务参与Filecoin网络提供质押。
				</div>
				<div>
					3.	 生态周期。生态板块第一期的合约周期为{{this.item.release_days}}天，转入符合标准要求数量的Filecoin当日开始计算，前{{this.item.waiting_days}}天为封装期，不分配收益，从第{{this.item.waiting_days + 1}}天开始分配当日收益。收益周期{{this.item.release_days}}天。
				</div>
				<div>
					4.	收益计算。计算公式如下：
					<div style="color: #E30000;">份数*Fil主网24小时平均效率*60%</div>
					<div>份数：{{this.item.base_number}}个Filecoin为1份</div>
					<div>主网：同步于Filecoin主网的浏览器，以<span style="color: #E30000;">https://filscan.io/为准。</span></div>
					<div>24小时平均挖矿效率：以浏览器显示计算方式为准。</div>
				</div>
				<div>
					5.	分配方式。自分配收益之日起，每天上午10点钟从Filecoin主网抓取数据进行核算，并转入充币方后台，每日收益按照Filecoin官方要求，25%的收益自由释放，75%的收益分180天线性释放。
				</div>
				<div>
					6.	返还方式。合约{{this.item.release_days}}天到期之后一次性将第1条约定的质押Filecoin转至充币方指定的收取地址。
				</div>
				<div>
					7.  违约责任。转入符合标准数量的Filecoin智岚博丰科技有限公司不得挪作他用，只能用于存储服务器Filecoin网络质押，并提供所有数量Filecoin的安全，因智岚博丰科技有限公司运维原因导致的损失由智岚博丰科技有限公司全部承担，除此以外的原因造成的损失智岚博丰科技有限公司不承担责任。合约期内不允许解约，充币方单方违约，智岚博丰科技有限公司有权扣除全部收益和充币总额30%的Filecoin作为违约金。				</div>
				<div>
					8.	本协议冲币方点击确认后自动生效。
				</div>
				<div>
					9.	如需续约，请提前一个月与智岚博丰科技有限公司联系。
				</div>
				<div>
					10.未满18周岁不得参与。
				</div>
				<div class="bottom">
					<div class="swit">
						<van-switch size="20px" active-color="#37D39B" v-model="agree" />
						<div class="font">本人已全部阅读以上条款并认同所有内容，对此没有异议。</div>
					</div>
					<div class="agree">
						<van-button type="primary" plain @click="show=false">取消</van-button>
						<van-button v-if="showbtn" type="primary" @click="handleBuy(item)">同意</van-button>
						<van-button type="primary" v-else :disabled="attcode">{{code_ts}}</van-button>
					</div>
				</div>
			</div>
		</van-dialog>
	<!-- 维护 -->
	<van-dialog v-model="messageDialog" title="通知" show-cancel-button class="messagebox" :showConfirmButton ="false" :showCancelButton = "false" :closeOnClickOverlay="true">
		<div >生态板块维护中，开通时间另行通知。</div>
	</van-dialog>
	</div>
</template>

<script>
	import {Ecological} from "@/api/index";
	export default {
		data() {
			return {
				messageDialog:true,//维护通知
				manage:'',
				agree:false,
				//合同
				attcode: false,
				showbtn: false, 
				code_ts: '10s',
				sec: 10,
				show:false,
				item:{
					start_time:"",
					end_time:""
				},
				id:'',
				tableData: [],
				total_meal:0,
				loading: false,
				 finished: false,
				 data: [], 
				 page: {
					current_page: 1,
					pagesize: 10
				},
			}
		},
		created() {
			this.spacialmanage()
		},
		methods:{
			//点击质押跳合同 //10倒计时
			buyopen(it){
				this.agree = false;
				this.showbtn = false;
				this.attcode = true;
				if(!this.countdown){
					this.countdown = true
					var timer = setInterval(() => {
					        this.sec = this.sec-1
					        this.code_ts = this.sec+ 'S'
					        if (this.sec === 0) {
								this.countdown = false
					            clearInterval(timer)
					            this.sec = 10
					            this.code_ts = this.sec+ 'S'
					            this.showbtn = true
								this.attcode = false
					        }
					    }, 1000)
				}
				this.show = true;
				this.item = it;
			},
			
			//跳转购买页
			handleBuy(item){
				if(!this.agree) {
					this.$message.error('请先勾选同意合同条款')
					return 
				}else {
					this.$router.push({name: 'Buypackage', params: item})
				}
				
			},
			async onLoadPackagelist() {
				if(!this.finished){
					let {status,data} = await Ecological.packagelist({page:this.page.current_page});
					if (status) {
						this.tableData.push(...data.data.data);
						if(data.data.total_meal > 0){
							this.total_meal = data.data.total_meal;
						}
						if(data.data.data.length == 0){
							this.finished = true;
						}
						this.page.current_page += 1
					}
				} 
				// 加载状态结束
				this.loading = false;
			},
			//特殊1089
			spacialmanage() {
				this.manage = this.$store.getters.getBase.grade;
			},
			//质押的禁用
			buydisabled(sell_out,expire){
				return sell_out != 0 || !expire;
			},
			//年月日
			dateFmt(date){
				if (date == null || date == undefined){
					return '';
				}
				return date.slice(0,4)+'年'+date.slice(5,7)+'月'+date.slice(8,10)+'日';
			}
		},
		
	}
</script>

<style lang="less">
	.ecological {
		background: url(../../assets/img/ic_ecobeijing.png) no-repeat top center;
		background-size: 100% ;
		background-color: #F7F9FC;
		// max-height: 100vw;
		.title {
			font-size: 8.5vw;
			font-weight: 500;
			color: #ffffff;
			padding: 24vw 6vw 5vw 7vw;
		}
		.van-dialog__header {
			font-size: 3.5vw;
			padding-bottom: 0.52vw;
		}
		
		.number {
			font-size: 11vw;
			color: #ffffff;
			text-align: center;
			padding-top: 10vw;
		}
		.items {
			margin: 0vw 2.5vw;
			padding-bottom: 10vw;
			.item {
				background: url(../../assets/img/img_kapianbai.png) no-repeat center center;
				background-size: cover;
				border-top-right-radius: 3vw;
				border-top-left-radius: 3vw;
				width: 95vw;
				height: 56vw;
				position: relative;
				margin-top: 5vw;
				.tit {
					background: url(../../assets/img/img_kapianlan.png) no-repeat;
					background-position: center center;
					background-size: 100%;
					height: 12vw;
					padding: 0 5vw;
					color: #fff;
					display: flex;
					align-items: center;
					justify-content: space-between;
					// position: relative;
					position: absolute;
					width:81%;
					left: 4.1vw;
					.mask {
						border-top-right-radius: 2vw;
						border-top-left-radius: 2vw;
						width: 100%;
						height: 100%;
						background-color: #EBEDF1;
						opacity: 0.7;
						position: absolute;
						left: 0;
						right: 0;
					}
				}
				.base_num {
					text-align: center;
					position: absolute;
					left: 11vw;
					top: 20vw;
					.nums {
						font-size: 5vw;
						color: #124DA5;
						font-weight: bold;
					}
					.text {
						font-size: 4vw;
						color: #999999;
						padding-top: 1vw;
					}
				}
				.release {
					text-align: center;
					position: absolute;
					left: 31vw;
					top: 20vw;
					.nums {
						font-size: 5vw;
						color: #124DA5;
						font-weight: bold;
					}
					.text {
						font-size: 4vw;
						color: #999999;
						padding-top: 1vw;
					}
				}
				.waiting {
					text-align: center;
					position: absolute;
					left: 51vw;
					top: 20vw;
					.nums {
						font-size: 5vw;
						color: #124DA5;
						font-weight: bold;
					}
					.text {
						font-size: 4vw;
						color: #999999;
						padding-top: 1vw;
					}
				}
				.buy_meals {
					text-align: center;
					position: absolute;
					right: 8vw;
					top: 17vw;
					.van-button--normal {
						padding: 50px 10px;
						border-radius: 2vw;
						height: 36px;
						.van-button__text {
							display: flex;
							align-items: center;
						}
					}
					.van-button--disabled {
						opacity: 0.3;
					}
					.van-button {
						margin-top: 0;
					}
				}
				.number {
					position: absolute;
					bottom: 14vw;
					left: 11vw;
					font-size: 3vw;
					color: #999999;
				}
				.date {
					position: absolute;
					bottom: 8vw;
					left: 11vw;
					font-size: 3vw;
					color: #999999;
				}
			}
		}
		.pact {
			background-color: rgba(255,255,255,0.9);
			overflow-y: auto;
			height: 80%;
			width: 76vw;
			padding: 5vw;
			font-size: 4vw;
			text-align: justify;
			line-height: 8vw;
			.bottom {
				.swit {
					display: flex;
					padding-top: 4vw;
					justify-content: space-between;
					.font {
						font-size: 3.5vw;
						width: 80%;
						line-height: 5vw;
					}
				}
				.agree {
					padding-top: 3vw;
					display: flex;
					justify-content: space-between;
					.van-button {
						border-radius: 3vw;
					}
				}
			}
		}
		.messagebox {
			background-color: #ffffff;
			overflow-y: auto;
			width: 76vw;
			padding: 5vw;
			text-align: center;
			height: 10%;
			font-size: 4vw;
			.van-dialog__header {
				font-size: 5vw;
				color: #333333;
			}
			.van-dialog__header {
				padding-top: 0;
				padding-bottom: 2vw;
			}
		}
	}
</style>
