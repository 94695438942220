import axios from "axios"
//基本信息
let info = (data= {}) => axios.get('/api/user/base', { params:data });
//修改密码
let password = (data = {}) => axios.post('/api/user/resetpwd', data);
//发送短信
let sendsms = (data = {}) => axios.post('/api/sendsms', {scene:'safe',phone:data.mobile });
//手机安全+钱包地址
let safe = (data = {}) => axios.post('/api/user/safe', {wallet:data.wallet,mobile:data.mobile,code:data.code});
//认证
let realname = (data = {}) => axios.post('/api/user/realname', data);
//上传图片
let ImageUpload = (data= {}) => axios.post('/api/upload', data,{
	headers: {
		"Content-Type": "multipart/form-data"
	}});
export default {
	info,
	password,
	sendsms,
	safe,
	realname,
	ImageUpload,
}