<template>
	<div class="all_server">
		<div class="server_top">
			<div class="left">
				<div class="CN">服务器管理</div>
				<div class="EN">server management</div>
			</div>
			<div class="right">
				<div>
					<img width="44px" src="../../assets/img/ic_logo.png" alt="智岚博丰">
				</div>
			</div>
		</div>
		<div class="server_middle">
			<div class="server_box">
				<div class="my_server">
					<img width="24px" src="../../assets/img/ic_fuwuqi.png" alt="智岚博丰">
					<div class="number">{{form.mytai}}台</div>
					<div class="text">服务器数量</div>
				</div>
				<!-- <router-link to="/Buy/first">
					<div class="insert">
						<van-icon name="add" color="#fff" size="7vw" />
						<div class="bottom">
							<span>新增服务器</span>
							<van-icon name="arrow" />
						</div>
					</div>
				</router-link> -->
			</div>
		</div>
		<div class="server_bottom">
			<van-tabs v-model="active" @change="tabchange">
				<van-tab title="全部" style="margin-bottom: 15vw;" name="0">
					<van-list v-model="loading"  :finished="finished"  finished-text="没有更多了"  @load="onLoad()">
						<div class="server_list" v-for="(item,index) in ServerList">
							<div class="item" @click="goServer(item)">
								<div class="left">
									<div class="photo"><img width="44px" src="../../assets/img/ic_cipan.png" alt="智岚博丰"></div>
									<div class="text">
										<div class="name">{{item.kuangname}}</div>
										<div class="parameter">{{item.pan}}盘{{item.ti}}T</div>
									</div>
								</div>
								<div class="right">
									<div class="nums">X{{item.nums}}台</div>
									<div class="status">
										<span style="color: #84CE61;" v-if="item.status===5">运行中</span>
										<span style="color: #FF8C8D;" v-if="item.status===4">停机中</span>
										<span style="color: #1989FA;" v-if="item.status===3">审核不过</span>
										<span style="color: #1989FA;" v-if="item.status===2">审核中</span>
										<span style="color: #FF8C8D;" v-if="item.status===1">待上传</span>
									</div>
									
								</div>
							</div>
						</div>
					</van-list>
				</van-tab>
				<van-tab title="已上传" style="margin-bottom: 15vw;" name="5">
				<van-list v-model="loading"  :finished="finished"  finished-text="没有更多了"  @load="onLoad()">
					<div class="server_list" v-for="(item,index) in ServerList">
							<div class="item" @click="goServer(item)" v-if="item.status===5">
								<div class="left">
									<div class="photo"><img width="44px" src="../../assets/img/ic_cipan.png" alt="智岚博丰"></div>
									<div class="text">
										<div class="name">{{item.kuangname}}</div>
										<div class="parameter">{{item.pan}}盘{{item.ti}}T</div>
									</div>
								</div>
								<div class="right">
									<div class="nums">X{{item.nums}}台</div>
									<div class="status">
										<span style="color: #84CE61;" v-if="item.status===5">运行中</span>
										<span style="color: #FF8C8D;" v-if="item.status===4">停机中</span>
										<span style="color: #1989FA;" v-if="item.status===3">审核不过</span>
										<span style="color: #1989FA;" v-if="item.status===2">审核中</span>
										<span style="color: #FF8C8D;" v-if="item.status===1">待上传</span>
									</div>
								</div>
							</div>
					</div>
				</van-list>	
				</van-tab>
				<van-tab title="未上传" style="margin-bottom: 15vw;" name="1">
				<van-list v-model="loading"  :finished="finished"  finished-text="没有更多了"  @load="onLoad()">
					<div class="server_list" v-for="(item,index) in ServerList">
							<div class="item" @click="goServer(item)" v-if="item.status===1">
								<div class="left">
									<div class="photo"><img width="44px" src="../../assets/img/ic_cipan.png" alt="智岚博丰"></div>
									<div class="text">
										<div class="name">{{item.kuangname}}</div>
										<div class="parameter">{{item.pan}}盘{{item.ti}}T</div>
									</div>
								</div>
								<div class="right">
									<div class="nums">X{{item.nums}}台</div>
									<div class="status">
										<span style="color: #84CE61;" v-if="item.status===5">运行中</span>
										<span style="color: #FF8C8D;" v-if="item.status===4">停机中</span>
										<span style="color: #1989FA;" v-if="item.status===3">审核不过</span>
										<span style="color: #1989FA;" v-if="item.status===2">审核中</span>
										<span style="color: #FF8C8D;" v-if="item.status===1">待上传</span>
									</div>
								</div>
							</div>
					</div>
				</van-list>
				</van-tab>
				<van-tab title="待审核" style="margin-bottom: 15vw;" name="2">
				<van-list v-model="loading"  :finished="finished"  finished-text="没有更多了"  @load="onLoad()">
					<div class="server_list" v-for="(item,index) in ServerList">
							<div class="item" @click="goServer(item)" v-if="item.status===2">
								<div class="left">
									<div class="photo"><img width="44px" src="../../assets/img/ic_cipan.png" alt="智岚博丰"></div>
									<div class="text">
										<div class="name">{{item.kuangname}}</div>
										<div class="parameter">{{item.pan}}盘{{item.ti}}T</div>
									</div>
								</div>
								<div class="right">
									<div class="nums">X{{item.nums}}台</div>
									<div class="status">
										<span style="color: #84CE61;" v-if="item.status===5">运行中</span>
										<span style="color: #FF8C8D;" v-if="item.status===4">停机中</span>
										<span style="color: #1989FA;" v-if="item.status===3">审核不过</span>
										<span style="color: #1989FA;" v-if="item.status===2">审核中</span>
										<span style="color: #FF8C8D;" v-if="item.status===1">待上传</span>
									</div>
								</div>
							</div>
					</div>
				</van-list>
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
import { Home,Server } from "@/api/index";
	export default {
	  data() {
	    return {
		  active:'',
		  form: {coins:{}},
		  ServerList: [],
		  //刷新
		  loading: false,
		  finished: false,
		  data: [], 
		  page: {
			current_page: 1,
		    pagesize: 10
		 },
		   currentstatus:0
	    };
	  },
	  created() {
		this.Total();
	  },
	  methods: {
		async Total() {
			let { status, data } = await Home.total();
			if (status) {
				this.form = data.data;
			}
		},
		goServer(item){
			 this.$router.push({path:'detail?id='+item.id,params:{id:item.id}})
		},
		async onLoad() {
			if(!this.finished){
				let { status, data } = await Server.userlist({page:this.page.current_page,status:this.currentstatus});
				if (status) {
					this.ServerList.push(...data.data.data);
					if(data.data.data.length == 0){
						this.finished = true;
					}
					this.page.current_page += 1
				}
			} 
			// 加载状态结束
			this.loading = false;
		},
		tabchange(name,title){
			this.currentstatus = name
			this.ServerList = []
			this.page.current_page = 1
			this.finished = false
			this.loading = true
			this.onLoad()
		}
	  }
	};
</script>

<style lang="less">
	.all_server {
		.van-tabs__line {
			background-color: #124DA5;
		}
		.van-tabs__wrap {
		    margin-bottom: 2vw;
		}
		.server_top {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 5vw 5vw;
		
			.left {
				.CN {
					font-size: 6vw;
					color: #333333;
					font-weight: bold;
					padding-bottom: 1vw;
				}
		
				.EN {
					font-size: 3vw;
					color: #999999;
				}
			}
		}
		
		.server_middle {
			margin: 5vw 0 5vw 5vw;
		
			.server_box {
				display: flex;
				justify-content: space-between;
				align-items: center;
		
				.my_server {
					background-image: linear-gradient(to right, #0860B0 , #134BA2);
					border-radius: 3vw;
					height: 58vw;
					width: 47vw;
					padding: 8vw 5vw;
					box-sizing: border-box;
		
					.number {
						font-size: 6vw;
						color: #fff;
						padding-top: 18vw;
					}
		
					.text {
						font-size: 3vw;
						color: #fff;
						padding-top: 2vw;
					}
				}
		
				.insert {
					padding: 5vw 3vw;
					box-sizing: border-box;
					height: 35vw;
					width: 35vw;
					background-color: #26A6F4;
					border-top-left-radius: 3vw;
					border-bottom-left-radius: 3vw;
		
					.bottom {
						padding-top: 6vw;
						display: flex;
						align-items: center;
						font-size: 3vw;
						color: #ffffff;
					}
				}
			}
		}
		
		.server_bottom {
			margin: 5vw 5vw;
		}
		.server_list {
			.item {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 3vw 0;
				border-bottom: 0.2vw solid #eee;
				.left {
					display: flex;
					align-items: center;
					.text {
						margin-left: 2vw;
						.name {
							font-size: 4vw;
							font-weight: bold;
							color: #333333;
							padding-bottom: 1vw;
						}
						.parameter {
							font-size: 4vw;
							color: #999999;
							padding-top: 1vw;
						}
					}
				}
				.right {
					.nums {
						font-weight: 4vw;
						color: #333333;
						padding-bottom: 1vw;
					}
					.status {
						padding-top: 1vw;
					}
				}
			}
		}
	}
	
</style>
