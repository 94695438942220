<template>
	<div>
		<div class="password_box">
			<van-cell-group>
			  <van-field v-model="ruleForm.password" label="请输入原密码" placeholder="请输入原密码" />
			</van-cell-group><van-cell-group>
			  <van-field v-model="ruleForm.new_password" label="请输入新密码" placeholder="请输入新密码" />
			</van-cell-group><van-cell-group>
			  <van-field v-model="ruleForm.sec_new_password" label="重复新密码" placeholder="请再次输入新密码" />
			</van-cell-group>
		</div>
		<div class="certification_btn">
			<van-button color="#124DA5"  @click="save()">提交</van-button>
		</div>
	</div>
</template>

<script>
import { Personal } from "@/api/index";
  export default {
    data() {
      return {
        ruleForm: {
          password: '',
          new_password:'',
          sec_new_password:'',
        },
      };
    },
   created () {
	   
   },
   methods: {
	   async save() {
			let { status, data } = await Personal.password(this.ruleForm);
			if (status) {
				if(data.code == 200) {
					this.$message.success(data.data);
				} else {
					this.$message.error(data.message);
				}
			} 
	   },
   },
  }
</script>

<style lang="less">
	.password_box {
		margin: 20vw 5vw 40vw;
		padding: 5vw 5vw;
		box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
		border-radius: 5vw;
		.van-cell {
			display: block;
			font-size: 3.5vw;
			color: #333333;
		}
		.note {
			color: #999999;
			font-size: 3vw;
			margin-left: 5vw;
		}
		.van-button {
			border-radius: 2vw;
			margin-top: 2vw;
			span{
				color: #124DA5;
			}
		}
		.van-field__label {
			width: 30vw;
		}
		.van-field__control {
			border: 0.2vw solid #eee;
			padding: 1vw 3vw;
			border-radius: 2vw;
			margin-top: 2vw;
		}
	}
	[class*=van-hairline]::after {
		width: 0;
	}
	
	.certification_btn {
		margin: 10vw 5vw 20vw;
		.van-button {
			width: 90vw;
			border-radius: 2vw;
		}
	}
	input{
	    -webkit-appearance: none;
	}
	
</style>
