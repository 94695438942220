<template>
	<div class="distribution">
		<div class="page">
			<div class="distribution_bg">
				<div class="content">
					<div class="left">
						<p class="role">{{tableForm.username}}-{{tableForm.agent_name}}</p>
						<p class="recommend">推荐码{{tableForm.phone}}</p>
					</div>
					<div class="right">
						<img width="50px" src="../../assets/img/ic_logo.png" alt="智岚博丰">
					</div>
				</div>
				<div class="about_us">
					<div class="balance">
						￥{{tableForm.money}}
					</div>
					<div class="bottom">
						<div class="text">佣金余额(元)</div>
						<div class="gocash" @click="goCash">
							<span>去提现</span>
							<van-icon name="arrow" />
						</div>
					</div>
				</div>
			</div>
			<div class="distribution_middle">
				<div class="left" @click="goContribution">
					<img width="25px" src="../../assets/img/ic_icon.png" alt="智岚博丰">
					<div class="number">{{tableForm.score}}</div>
					<div class="gocontribution">
						<span>贡献值</span>
						<van-icon name="arrow" />
					</div>
				</div>
				<div class="right">
					<div class="top">
						<p class="number">{{tableForm.sons}}人</p>
						<p class="text">合伙人数</p>
					</div>
					<div class="bottom">
						<img width="20px" src="../../assets/img/ic_lanfuwuqi.png" alt="智岚博丰">
						<p class="number">{{tableForm.mechines}}台</p>
						<p class="text">旗下服务器</p>
					</div>
				</div>
			</div>
			<div class="distribution_bottom">
				<van-tabs v-model="active" @change="tabchange">
					<van-tab title="交易列表" style="margin-bottom: 15vw;" name="0">
						<van-list v-model="loading"  :finished="finished"  finished-text="没有更多了"  @load="onLoadJ()">
							<div class="distribution_list" v-for="(item,index) in dealData">
								<div class="item" @click="goDeal(item)">
									<div class="top">
										<div class="name">
											<span v-if="item.aboutuser!==null">{{item.aboutuser.username}}</span>
										</div>
										<div class="number">{{item.money}}</div>
									</div>
									<div class="bottom">
										<div class="note">{{item.content}}</div>
										<van-icon name="arrow" />
									</div>
								</div>
							</div>
						</van-list>
					</van-tab>
					<van-tab title="提现记录" style="margin-bottom: 15vw;" name="1">
						<van-list v-model="loading"  :finished="finished"  finished-text="没有更多了"  @load="onLoadT()">
							<div class="distribution_list" v-for="(item,index) in recordData">
								<div class="item" @click="goCashR(item)">
									<div class="top">
										<div class="name">{{item.content}}</div>
										<div class="number">{{item.money}}</div>
									</div>
									<div class="bottom">
										<div class="note">{{item.applytime}}</div>
										<van-icon name="arrow" />
									</div>
								</div>
							</div>
						</van-list>
					</van-tab>
				</van-tabs>
			</div>
		</div>
	</div>
</template>

<script>
import { Distribution,Home } from "@/api/index";
export default {
    data() {
      return {
		active:'',
		centerDialogVisible: false,
		form:{
			nums:'',
		},
		tableForm:[],
		dealData:[],
		recordData:[],
		//刷新
		loading: false,
		finished: false,
		page: {
		  current_page: 1,
		  pagesize: 10
		 },
		 
		currentstatus:0
      };
    },
	created() {
		this.Details()
	},
	methods:{
		//进入贡献页面
		goContribution() {
			this.$router.push('/Distribution/Contribution')
		},
		goCash() {
			this.$router.push('/Distribution/application')
		},
		async Details() {
				let { status, data } = await Distribution.details(); 
				if (status) {
					this.tableForm = data.data;
				}
			},
		//交易列表
		async onLoadJ() {
			if(!this.finished){
				let { status, data } = await Distribution.deallist({page:this.page.current_page});
				if (status) {
					this.dealData.push(...data.data.data);
					if(data.data.data.length == 0){
						this.finished = true;
					}
					this.page.current_page += 1
				}
			} 
			// 加载状态结束
			this.loading = false;
		},
		//提现列表
		async onLoadT() {
			if(!this.finished){
				let { status, data } = await Distribution.withdrawal({page:this.page.current_page});
				if (status) {
					this.recordData.push(...data.data.data);
					if(data.data.data.length == 0){
						this.finished = true;
					}
					this.page.current_page += 1
				}
			} 
			// 加载状态结束
			this.loading = false;
		},
		tabchange(name){
			this.recordData = []
			this.dealData = []
			this.page.current_page = 1
			this.finished = false
			this.loading = true
			switch(name){
				case "0":
				this.onLoadJ()
				break
				case "1":
				this.onLoadT()
				break
			}
		},
		async handleApply (nums) {
			let { status, data} = await Distribution.apply(this.form);
			if(status) {
				if(data.code == 200){
					this.$message.success("操作成功");
					this.Details();
					this.onLoadJ();
					this.onLoadT();
				}else{
					this.$message.error(data.message);	
				}
			}
		},
		goDeal(item){
			this.$router.push({path:'tradingdetail?id='+item.id,params:{id:item.id}})
		},
		goCashR(item){
			this.$router.push({path:'cashdetail?id='+item.id,params:{id:item.id}})
		},
	},
  };
</script>

<style lang="less">
	.distribution {
		.van-tabs__line {
			background-color: #124DA5;
		}
		.van-tabs__wrap {
		    margin-bottom: 2vw;
		}
		.page {
			.distribution_bg {
				background: url(../../assets/img/ic_beijing4.png) no-repeat center left;
				background-color: #124DA5;
				width: 100vw;
				height: 66vw;
				border-bottom-right-radius: 9vw;
				.content {
					padding: 10vw 5vw;
					display: flex;
					justify-content: space-between;
					.left {
						.role {
							color: #fff;
							font-size: 3.5vw;
							padding-bottom: 1vw;
						}
						.recommend {
							color: #fff;
							font-size: 3.5vw;
							padding-top: 1vw;
						}
					}
				}
				.about_us {
					margin: 0vw 5vw;
					.balance {
						font-size: 6vw;
						color: #fff;
					}
					.bottom {
						padding-top: 10vw;
						display: flex;
						justify-content: space-between;
						.text {
							font-size: 3.5vw;
							color: #fff;
						}
						.gocash {
							span {
								font-size: 3.5vw;
								color: #fff;
								vertical-align: middle;
							}
							.van-icon {
								font-size: 3.5vw;
								color: #fff;
								vertical-align: middle;
							}
						}
					}
				}
			}
			.distribution_middle {
				margin: 5vw 5vw;
				display: flex;
				.left {
					width: 42.5vw;
					height: 50vw;
					background-image: linear-gradient(to right, #0860B0 , #134BA2);
					border-radius: 3vw;
					margin-right: 2.5vw;
					padding: 5vw;
					box-sizing: border-box;
					.number {
						font-size: 6vw;
						font-weight: bold;
						color: #fff;
						padding-top: 6vw;
					}
					.gocontribution {
						font-size: 3vw;
						color: #fff;
						padding-top: 7vw;
						span {
							vertical-align: middle;
						}
						.van-icon {
							vertical-align: middle;
						}
					}
				}
				.right {
					width: 42.5vw;
					height: 50vw;
					margin-left: 2.5vw;
					.top {
						height: 20vw;
						border-radius: 3vw;
						box-shadow: 0 0 10px rgba(0,0,0,0.1);
						margin-bottom: 2.5vw;
						padding: 2vw 5vw;
						box-sizing: border-box;
						.number {
							color: #124DA5;
							font-size: 4.5vw;
							font-weight: bold;
						}
						.text {
							font-size: 3.5vw;
							color: #999999;
							padding-top: 2vw;
						}
					}
					.bottom {
						height: 27.5vw;
						border-radius: 3vw;
						box-shadow: 0 0 10px rgba(0,0,0,0.1);
						padding: 2vw 5vw;
						box-sizing: border-box;
						img {
							padding-bottom: 2vw;
						}
						.number {
							color: #124DA5;
							font-size: 4.5vw;
							font-weight: bold;
						}
						.text {
							font-size: 3.5vw;
							color: #999999;
							padding-top: 2vw;
						}
					}
				}
			}
			.distribution_bottom {
				margin: 5vw 5vw;
			}
			.distribution_list {
				.item {
					padding: 5vw 0;
					border-bottom: 0.2vw solid #eee;
					.top {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding-bottom: 1vw;
						.name {
							font-size: 3.5vw;
							color: #333333;
						}
						.number {
							font-size: 3.5vw;
						}
					}
					.bottom {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding-top: 1vw;
						.note {
							font-size: 3.5vw;
							color: #999999;
						}
						.van-icon {
							font-size: 3.5vw;
							color: #999999;
						}
					}
				}
			}
		}
	}
</style>
