<template>
	<div class="all_assets">
		<div class="filecoin">
			<!-- <span class="text">FIL</span>
			<img width="18px" src="../../assets/img/ic_tixing.png" alt="智岚博丰"> -->
		</div>
		<div class="cash_number">
			{{pageform.cash}}
		</div>
		<div class="cash_text">
			可提现数量
		</div>
		<div class="details_bottom">
			<div class="items"  style="text-align: left;">
				<p class="number">{{pageform.locked}}</p>
				<p class="text">锁仓数量</p>
			</div>
			<div class="items"  style="text-align: center;border: 0;" v-if="pageform.release_180 > 0">
				<p class="number">{{pageform.release_180}}</p>
				<p class="text">180天锁仓数量</p>
			</div>
			<div class="installment" v-if="pageform.stage == true">
				<div class="ment">
					<p class="number">{{pageform.stage_cash}}</p>
					<div class="text">
						<div style="margin-right: 2vw;">自由分期</div>
						<van-popover v-model="showPopover1" trigger="click" placement='top-end'>
							<div class="free_msg">每月16号进行释放，每次平均释放1/12，共12期</div>
							<template #reference>
								<van-icon name="question-o" color="#0486FE" size='16' />
							</template>
						</van-popover>
					</div>
				</div>
				<div class="ment locken_ment">
					<p class="number">{{pageform.stage_locked}}</p>
					<div class="text locken_text">
						<div style="margin-right: 2vw;">锁仓分期</div>
						<van-popover v-model="showPopover2" trigger="click" placement='top-end'>
							<div class="locked_msg">540天锁仓，到期后每天平均释放1/60，共60期</div>
							<template #reference>
								<van-icon name="question-o" color="#0486FE" size='16' />
							</template>
						</van-popover>
					</div>
					<div class="countdown" style="display: none;">倒计时:{{pageform.lose_release_days}}天</div>
				</div>
			</div>
			
		</div>
		<div class="btn">
			<van-button v-if="judge_realname"  type="info" @click="goAuthentication()">请实名认证</van-button>
			<van-button  v-if="judge_wallet" plain type="info"  @click="onClickvalidation">钱包与手机验证</van-button>
			<van-button  v-if="judge_withdrawal"  :type="judge_mention?'danger':'info'" @click="onClick" :disabled="ifDisabled">{{BtnText}}</van-button>
		</div>
		<div class="form_list">
			<van-tabs v-model="active"  @change="tabchange" swipe-threshold = "4">
				<van-tab  title="每日通证明细" style="margin-bottom: 15vw;" name="3">
						<div class="assets-list" v-for="(item,index) in DaybonusData" v-if="DaybonusDatanokong">
							<div class="item" >
								<div class="top">
									<div class="text" style="font-weight: bold;font-size: 3.5vw;">时间</div>
									<div class="date">{{item.share_time}}</div>
								</div>
								<div class="bottom">
									<div class="text" style="font-weight: bold; font-size: 3.5vw;">额度变化</div>
									<div class="num">{{item.share}}</div>
								</div>
							</div>
						</div>
						<div v-if="DaybonusDatakong">
							<van-empty
							  class="custom-image"
							  image="error"
							  description="此列表为空"
							/>
						</div>
						<van-pagination
							v-if="DaybonusDatanokong"
							v-model="Daypage1.current_page"
							:total-items="Daypage1.total"
							:show-page-size="4"
							force-ellipses
							@change="handleCurrentChange4"
						/>
				</van-tab>
				<van-tab  title="通证明细" style="margin-bottom: 15vw;" name="0">
						<div class="assets-list" v-for="(item,index) in bonusData" v-if="bonusDatanokong">
								<div class="item">
									<div class="top">
										<div>
											<span v-if="item.share_type===1">25%立即释放</span>
											<span v-if="item.share_type===2">75%线性释放</span>
										</div>
									</div>
									<div class="bottom">
										<div class="num">{{item.share_balance}}</div>
										<div class="date">{{item.share_time}}</div>
									</div>
								</div>
						</div>
						<div v-if="bonusDatakong">
							<van-empty
							  class="custom-image"
							  image="error"
							  description="此列表为空"
							/>
						</div>
						<van-pagination
							v-if="bonusDatanokong"
							v-model="page1.current_page"
							:total-items="page1.total"
							:show-page-size="4"
							force-ellipses
							@change="handleCurrentChange1"
						/>
				</van-tab>
				<van-tab title="每日分红明细" style="margin-bottom: 15vw;" name="4">
						<div class="assets-list" v-for="(item,index) in DaybonusHData"  v-if="DaybonusHDatanokong">
							<div class="item" >
								<div class="top">
									<div class="text" style="font-weight: bold;font-size: 3.5vw;">时间</div>
									<div class="date">{{item.share_time}}</div>
								</div>
								<div class="bottom">
									<div class="text" style="font-weight: bold; font-size: 3.5vw;">额度变化</div>
									<div class="num">{{item.share}}</div>
								</div>
							</div>
						</div>
						<div v-if="DaybonusHDatakong">
							<van-empty
							  class="custom-image"
							  image="error"
							  description="此列表为空"
							/>
						</div>
						<van-pagination
						  v-if="DaybonusHDatanokong"
						  v-model="Daypage2.current_page"
						  :total-items="Daypage2.total"
						  :show-page-size="4"
						  force-ellipses
						  @change="handleCurrentChange5"
						/>
				</van-tab>
				<van-tab title="分红明细" style="margin-bottom: 15vw;" name="1">
						<div class="assets-list" v-for="(item,index) in bonusHData"  v-if="bonusHDatanokong">
								<div class="item" >
									<div class="top">
										<div>
											<span v-if="item.share_type===1">25%立即释放</span>
											<span v-if="item.share_type===2">75%线性释放</span>
										</div>
									</div>
									<div class="bottom">
										<div class="num">{{item.share_balance}}</div>
										<div class="date">{{item.share_time}}</div>
									</div>
								</div>
						</div>
						<div v-if="bonusHDatakong">
							<van-empty
							  class="custom-image"
							  image="error"
							  description="此列表为空"
							/>
						</div>
						<van-pagination
						  v-if="bonusHDatanokong"
						  v-model="page2.current_page"
						  :total-items="page2.total"
						  :show-page-size="4"
						  force-ellipses
						  @change="handleCurrentChange2"
						/>
				</van-tab>
				<van-tab title="提现申请" style="margin-bottom: 15vw;" name="2">
						<div class="assets-list" v-for="(item,index) in withdrawalData"  v-if="withdrawalDatanokong">
								<div class="item">
									<div class="top">
										<div>申请提现</div>
										<div class="status">
											<span style="color: #84CE61;" v-if="item.status===5">审核通过</span>
											<span style="color: #FF8C8D;" v-if="item.status===3">审核失败</span>
											<span style="color: #1989FA;" v-if="item.status===2">审核中</span>
											<span style="color: #1989FA;" v-if="item.status===1">待审核</span>
										</div>
									</div>
									<div class="bottom">
										<div class="price">{{item.money}}</div>
										<div class="date">{{item.applytime}}</div>
									</div>
								</div>
						</div>
						<div v-if="withdrawalDatakong">
							<van-empty
							  class="custom-image"
							  image="error"
							  description="此列表为空"
							/>
						</div>
						<van-pagination
						  v-if="withdrawalDatanokong"
						  v-model="page3.current_page"
						  :total-items="page3.total"
						  :show-page-size="4"
						  force-ellipses
						  @change="handleCurrentChange3"
						/>
				</van-tab>
				<van-tab title="自由分期" style="margin-bottom: 15vw;" name="5"  v-if="pageform.stage == true">
						<div class="assets-list" v-for="(item,index) in freeData" v-if="freeDatanokong">
							<div class="item" >
								<div class="top">
									<div class="text" style="font-weight: bold;font-size: 3.5vw;">时间</div>
									<div class="date">{{item.snapshot_time}}</div>
								</div>
								<div class="bottom">
									<div class="text" style="font-weight: bold; font-size: 3.5vw;">额度变化</div>
									<div class="num">{{item.share}}</div>
								</div>
							</div>
						</div>
						<div v-if="freeDatakong">
							<van-empty
							  class="custom-image"
							  image="error"
							  description="此列表为空"
							/>
						</div>
						<van-pagination
						  v-if="freeDatanokong"
						  v-model="freepage.current_page"
						  :total-items="freepage.total"
						  :show-page-size="4"
						  force-ellipses
						  @change="handleCurrentChange6"
						/>
				</van-tab>
				<van-tab title="180天释放明细" style="margin-bottom: 15vw;" name="6"  v-if="pageform.release_180 > 0">
						<div class="assets-list" v-for="(item,index) in releaseData" v-if="releaseDatanokong">
							<div class="item" >
								<div class="top">
									<div class="text" style="font-weight: bold;font-size: 3.5vw;">时间</div>
									<div class="date">{{item.snapshot_time}}</div>
								</div>
								<div class="bottom">
									<div class="text" style="font-weight: bold; font-size: 3.5vw;">总数</div>
									<div class="num">{{item.release_180}}</div>
								</div>
								<div class="bottom">
									<div class="text" style="font-weight: bold; font-size: 3.5vw;">分期数</div>
									<div class="num">{{item.current_release}}</div>
								</div>
							</div>
						</div>
						<div v-if="releaseDatakong">
							<van-empty
							  class="custom-image"
							  image="error"
							  description="此列表为空"
							/>
						</div>
						<van-pagination
						  v-if="releaseDatanokong"
						  v-model="releasepage.current_page"
						  :total-items="releasepage.total"
						  :show-page-size="4"
						  force-ellipses
						  @change="handleCurrentChange7"
						/>
				</van-tab>
				<van-tab title="锁仓分期" style="margin-bottom: 15vw;" name="7"  v-if="pageform.stage == true">
						<div class="assets-list" v-for="(item,index) in lockedData" v-if="lockedDatanokong">
							<div class="item" >
								<div class="top">
									<div class="text" style="font-weight: bold;font-size: 3.5vw;">时间</div>
									<div class="date">{{item.snapshot_time}}</div>
								</div>
								<div class="bottom">
									<div class="text" style="font-weight: bold; font-size: 3.5vw;">额度变化</div>
									<div class="num">{{item.current_release}}</div>
								</div>
							</div>
						</div>
						<div v-if="lockedDatakong">
							<van-empty
							  class="custom-image"
							  image="error"
							  description="此列表为空"
							/>
						</div>
						<van-pagination
						  v-if="lockedDatanokong"
						  v-model="lockedpage.current_page"
						  :total-items="lockedpage.total"
						  :show-page-size="4"
						  force-ellipses
						  @change="handleCurrentChange8"
						/>
				</van-tab>
			</van-tabs>
		</div>
		<van-dialog v-model="show" title="申请提现" confirmButtonText='提现' show-cancel-button @confirm="handleSingle(nums)">
			<div class="dialog">可提现总数</div>
			<div class="cash_num">{{pageform.cash}}</div>
			<van-field v-model="nums" type="digit" label="输入提现数量" />
		</van-dialog>
		<van-dialog v-model="warnDialog" title="重要提示" confirmButtonText='确定' show-cancel-button @confirm="clickWarn()">
			<div class="warn_text">
				<div class="text">请确认您的地址不是火币交易所地址</div>
				<div class="text">{{infoform.wallet}}</div>
				<div class="text" style="color: darkred;">火币交易所地址不可提现，禁止使用</div>
			</div>
		</van-dialog>
	</div>
</template>

<script>
	import { Property,Personal } from "@/api/index";
	export default {
		data() {
			return {
				infoform:{
					wallet:'',
				},
				warnDialog:false,
				BtnText:'分币中，今日12点开启',
				ifDisabled:true,
				showPopover1: false,
				showPopover2: false,
				bonusDatakong:false,
				bonusDatanokong:false,
				DaybonusDatakong:false,
				DaybonusDatanokong:false,
				bonusHDatakong:false,
				bonusHDatanokong:false,
				DaybonusHDatakong:false,
				DaybonusHDatanokong:false,
				withdrawalDatakong:false,
				withdrawalDatanokong:false,
				freeDatakong:false,
				freeDatanokong:false,
				releaseDatakong:false,
				releaseDatanokong:false,
				lockedDatakong:false,
				lockedDatanokong:false,
				nums:'',
				show: false,
				active: 0,
				pool_id:'',
				pageform:[],
				bonusData:[],
				bonusHData:[],
				DaybonusData:[],
				DaybonusHData:[],
				withdrawalData:[],
				lockedData:[],
				freeData:[],
				releaseData:[],
				judge_withdrawal:false,
				judge_realname:false,
				judge_wallet:false,
				judge_mention:false,
				Daypage1: {
				  current_page: 1,
				  pagesize: 10,
				  total:0,
				 },
				page1: {
				  current_page: 1,
				  pagesize: 10,
				  total:0,
				 },
				Daypage2: {
				  current_page: 1,
				  pagesize: 10,
				  total:0,
				},
				page2: {
				  current_page: 1,
				  pagesize: 10,
				  total:0,
				 },
				page3: {
				  current_page: 1,
				  pagesize: 10,
				  total:0,
				 },
				 freepage: {
				   current_page: 1,
				   pagesize: 10,
				   total:0,
				  },
				  lockedpage: {
				    current_page: 1,
				    pagesize: 10,
				    total:0,
				   },
				  releasepage: {
				    current_page: 1,
				    pagesize: 10,
				    total:0,
				   },
			};
		},
		created() {
			let { pool_id } = this.$route.query;
			if (this.pool_id == ''){
				this.pool_id = pool_id
			}
			this.Poolsinfo()
			this.onLoadADay()
			this.onLoadA()
			this.onLoadBDay()
			this.onLoadB()
			this.onLoadC()
			this.Freeinstall()
			this.Releaseinstall()
			this.LockedList()
			this.time_range("9:00", "12:00")
			setInterval(() => {
				this.time_range("9:00", "12:00")
			}, 5000);
		},
		methods: {
			formate (day) {
			    if(day>=10){
			        return day
			    }else{
			        return `0${day}`
			    }
			},
			time_range(beginTime, endTime) {
				let date = new Date();
				let year = date.getFullYear();
				let m = date.getMonth() + 1;
				let mounth = this.formate(m);
				let d = date.getDate();
				let day = this.formate(d);
			      let strb = beginTime.split(":");
			      if (strb.length != 2) {
			        return false;
			      }
			      let stre = endTime.split(":");
			      if (stre.length != 2) {
			        return false;
			      }
			      let b = new Date();
			      let e = new Date();
			      let n = new Date();
			      b.setHours(strb[0]);
			      b.setMinutes(strb[1]);
			      e.setHours(stre[0]);
			      e.setMinutes(stre[1]);
			      if (n.getTime() - b.getTime() >= 0 && n.getTime() - e.getTime() < 0) {
			        this.ifDisabled = true;
			        this.BtnText = '分币中，今日12点开启'
			      } else {
			        this.ifDisabled = false;
			        this.BtnText = '提现'
			      }
			    },
				//分币分页
				async handleCurrentChange1(val) {
					this.page1.current_page = val;
					this.onLoadA(this.page1.current_page)
				},
				//每日分币分页
				async handleCurrentChange4(val) {
					this.Daypage1.current_page = val;
					this.onLoadADay(this.Daypage1.current_page)
				},
				//分红分页
				async handleCurrentChange2(val) {
					this.page2.current_page = val;
					this.onLoadB(this.page2.current_page)
				},
				//每日分红分页
				async handleCurrentChange5(val) {
					this.Daypage2.current_page = val;
					this.onLoadBDay(this.Daypage2.current_page)
				},
				async handleCurrentChange3(val) {
					this.page3.current_page = val;
					this.onLoadC(this.page3.current_page)
				},
				//自由分期分页
				async handleCurrentChange6(val) {
					this.freepage.current_page = val;
					this.Freeinstall(this.freepage.current_page)
				},
				//180天锁仓明细分页
				async handleCurrentChange7(val) {
					this.releasepage.current_page = val;
					this.Releaseinstall(this.releasepage.current_page)
				},
				//锁仓分页
				async handleCurrentChange8(val) {
					this.lockedpage.current_page = val;
					this.LockedList(this.lockedpage.current_page)
				},
				goAuthentication() {
					this.$router.push('/Info/certification')
				},
				//验证按钮
				onClickvalidation() {
					this.$router.push('/Info/binding')
				},
				async Poolsinfo() {
						let { status, data } = await Property.poolsinfo({pool_id:this.pool_id}); 
						if (status) {
							this.pageform = data.data;
							//提币关闭判断
							this.judge_mention = this.pageform.uping;
							this.Ifinfo()
						}
					},
				
				//每日通证
				async onLoadADay() {
						let { status, data } = await Property.poolday({pool_id:this.pool_id,cate:1,page:this.Daypage1.current_page}); 
						if (status) {
							this.DaybonusData = data.data.data
							if(this.DaybonusData.length == 0){
								this.DaybonusDatakong = true
							}else {
								this.DaybonusDatanokong = true
							}
							this.Daypage1.total = data.data.total
						}
				},
				//通证
				async onLoadA() {
						let { status, data } = await Property.sharebonus({pool_id:this.pool_id,cate:1,page:this.page1.current_page}); 
						if (status) {
							this.bonusData = data.data.data
							if(this.bonusData.length == 0){
								this.bonusDatakong = true
							}else {
								this.bonusDatanokong = true
							}
							this.page1.total = data.data.total
						}
				},
				//每日分红
				async onLoadBDay() {
						let { status, data } = await Property.poolday({pool_id:this.pool_id,cate:2,page:this.Daypage2.current_page}); 
						if (status) {
							this.DaybonusHData = data.data.data
							if(this.DaybonusHData.length == 0){
								this.DaybonusHDatakong = true
							}else {
								this.DaybonusHDatanokong = true
							}
							this.Daypage2.total = data.data.total
						}
				},
				//分红
				async onLoadB() {
						let { status, data } = await Property.sharebonus({pool_id:this.pool_id,cate:2,page:this.page2.current_page}); 
						if (status) {
							this.bonusHData = data.data.data
							if(this.bonusHData.length == 0){
								this.bonusHDatakong = true
							}else {
								this.bonusHDatanokong = true
							}
							this.page2.total = data.data.total
						}
				},
				//提现
				async onLoadC() {
						let { status, data } = await Property.poolwithdrawal({page:this.page3.current_page}); 
						if (status) {
							this.withdrawalData = data.data.data
							if(this.withdrawalData.length == 0){
								this.withdrawalDatakong = true
							}else {
								this.withdrawalDatanokong = true
							}
							this.page3.total = data.data.total
						}
				},
				//自由分期列表
				async Freeinstall() {
						let { status, data } = await Property.freeinstall({pool_id:this.pool_id,page:this.freepage.current_page}); 
						if (status) {
							this.freeData = data.data.data
							if(this.freeData.length == 0){
								this.freeDatakong = true
							}else {
								this.freeDatanokong = true
							}
							this.freepage.total = data.data.total
						}
				},
				//180天锁仓列表
				async Releaseinstall() {
						let { status, data } = await Property.release180s({pool_id:this.pool_id,page:this.releasepage.current_page}); 
						if (status) {
							this.releaseData = data.data.data
							if(this.releaseData.length == 0){
								this.releaseDatakong = true
							}else {
								this.releaseDatanokong = true
							}
							this.releasepage.total = data.data.total
						}
				},
				//锁仓分期列表
				async LockedList() {
						let { status, data } = await Property.lockedlist({pool_id:this.pool_id,page:this.lockedpage.current_page}); 
						if (status) {
							this.lockedData = data.data.data
							if(this.lockedData.length == 0){
								this.lockedDatakong = true
							}else {
								this.lockedDatanokong = true
							}
							this.lockedpage.total = data.data.total
						}
				},
				tabchange(name){
					this.Daypage1.total = 1
					this.Daypage1.current_page = 1
					this.page1.total = 1
					this.page1.current_page = 1
					this.Daypage2.total = 1
					this.Daypage2.current_page = 1
					this.page2.total = 1
					this.page2.current_page = 1
					this.page3.total = 1
					this.page3.current_page = 1
					this.freepage.total = 1
					this.freepage.current_page = 1
					this.lockedpage.total = 1
					this.lockedpage.current_page = 1
					switch(name){
						case "0":
						this.onLoadA()
						break
						case "1":
						this.onLoadB()
						break
						case "2":
						this.onLoadC()
						break
						case "3":
						this.onLoadADay()
						break
						case "4":
						this.onLoadBDay()
						break
						case "5":
						this.Freeinstall()
						break
						case "6":
						this.Releaseinstall()
						break
						case "7":
						this.LockedList()
						break
					}
				},
				//判断信息
				async Ifinfo() {
					let { status, data } = await Personal.info();
						if (status) {
								this.infoform = data.data;
								//提现判断
								this.judge_withdrawal = this.infoform.status==5 && this.pageform.cash > 0 && this.infoform.mobile_status == 1;
								//实名判断
								this.judge_realname = this.infoform.status != 5;
								//钱包判断
								this.judge_wallet = (this.infoform.wallet == '' ||this.infoform.wallet == null) && this.infoform.mobile_status == 0;
						}	
					},
					//提现按钮
					onClick() {
						
						if (this.judge_mention){
							this.$message.error('数据中心正在升级数据，请稍后再试。');
							return
						}
						this.warnDialog = true
						console.log(this.warnDialog)
					},
					clickWarn() {
						this.warnDialog = false
						this.show = true
					},
				//单个提现
				async handleSingle (nums) {
					let { status, data} = await Property.single({pool_id:this.pool_id,nums:nums},this.form);
					if(status) {
						if(data.code == 200){
							this.$message.success(data.data);
							this.Poolsinfo()
							this.Ifinfo()
							this.page.current_page = 1
							this.onLoadC()
						}else{
							this.$message.error(data.message);	
						}
					}
				},
			},
	};
</script>

<style lang="less">
	.all_assets {
		.van-pagination {
			padding: 10vw 0;
		}
		.van-pagination__item::after {
			border: 0;
		}
		.van-tabs__line {
			background-color: #124DA5;
		}
		.van-tabs__wrap {
		    margin-bottom: 2vw;
		}
		.filecoin {
			margin: 10vw 5vw 2vw;
		
			.text {
				vertical-align: middle;
				color: #999999;
				font-size: 4vw;
				margin-right: 2vw;
			}
		
			img {
				vertical-align: middle;
			}
		}
		.cash_number {
			font-size: 8vw;
			font-weight: bold;
			color: #124DA5;
			margin: 5vw 5vw;
		}
		.cash_text {
			font-size: 4vw;
			color: #124DA5;
			margin: 2vw 5vw;
		}
		
		.details_bottom {
			display: flex;
			align-items: center;
			padding: 5vw 5vw;
			.installment {
				display: flex;
				width: 75%;
				.ment {
					border-right: 1px dashed #999999;
					width: 50%;
					text-align: center;
					.number {
						font-size: 3.5vw;
						color: #333333;
						margin-bottom: 1vw;
					}
					.text {
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 3vw;
						color: #999999;
						margin-top: 1vw;
					}
				}
				.locken_ment {
					position: relative;
					.countdown {
						width: 100%;
						position: absolute;
						top: -6vw;
						font-size: 3vw;
						color: #3988FF;
						left: 50%;
						transform: translateX(-50%);
					}
				}
				.ment:last-child {
					border-right: 0;
				}
			}
			.items {
				border-right: 1px dashed #999999;
				width: 25%;
				text-align: center;
				.number {
					font-size: 3.5vw;
					color: #333333;
					margin-bottom: 1vw;
				}
				.text {
					font-size: 3vw;
					color: #999999;
					margin-top: 1vw;
				}
			}
		}
		.btn {
			padding: 5vw 5vw;
			border-bottom: 0.2vw solid #eeeeee;
			.van-button {
				margin-right: 2vw;
				border-radius: 3vw;
				margin-top: 0;
			}
		}
		.form_list {
			margin: 5vw 5vw;
		}
		
		.assets-list {
			.item {
				padding: 5vw 0;
				border-bottom: 0.2vw solid #eee;
				.top {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-bottom: 1.5vw;
					font-size: 4vw;
					color: #333333;
					font-weight: bold;
					.van-icon {
						color: #999999;
					}
					.status {
						margin: 0;
					}
				}
				.bottom {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-top: 1.5vw;
		
					.date {
						color: #999999;
					}
		
					.num {
						color: #124DA5;
					}
		
					.price {
						color: #E80000;
					}
				}
			}
		}
		.van-dialog {
			.dialog {
				margin: 0 5vw;
			}
			.cash_num {
				margin: 2vw 5vw 0;
				color: #124DA5;
				font-weight: bold;
			}
			.van-cell__value {
				border-bottom: 0.1vw solid #ccc;
			}
			.warn_text {
				margin: 0 5vw;
				.text {
					font-size: 3.5vw;
					padding-top: 1.5vw;
				}
			}
		}
	}
	.free_msg {
		width: 38vw;
		padding: 2vw;
		font-size: 3vw;
	}
	.locked_msg {
		width: 38vw;
		padding: 2vw;
		font-size: 3vw;
	}
</style>
