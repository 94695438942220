<template>
	<div class="register">
		<van-form @submit="onSubmit">
		  <van-field
		    v-model="username"
		    name="username"
		    label="请输入个人姓名"
		    placeholder="请输入个人姓名"
		  />
		  <van-field
		    v-model="tel"
		    name="phone"
		    label="手机号码"
		    placeholder="请输入手机号码"
		  />
		  <van-field
		    v-model="password"
		    type="password"
		    name="password"
		    label="请输入密码"
		    placeholder="请输入密码"
		  />
		  <van-field
		    v-model="sec_password"
		    type="password"
		    name="sec_password"
		    label="请再次输入密码"
		    placeholder="请再次输入密码"
		  />
		  <van-field
		    v-model="tuijian"
		    name="tuijianma"
		    label="请输入推荐码"
		    placeholder="请输入推荐码"
		  />
		  <van-radio-group v-model="radio" direction="horizontal">
		    <van-radio name="1">同意会员<a href="###" class="aggrement">注册协议</a></van-radio>
		  </van-radio-group>
		  <div style="margin: 16px;">
		    <van-button round block color="#124DA5" native-type="submit" disabled>注册</van-button>
		  </div>
		</van-form>
	</div>
</template>

<script>
import {Admin} from "@/api/index";
export default {
  data() {
    return {
      username: '',
	  tel:'',
      password: '',
	  sec_password:'',
	  tuijian:'',
	  radio:'',
    };
  },
  methods: {
	  async onSubmit(values) {
		//   if(this.radio == ''){
		// 	  this.$message.error("请先勾选注册协议");
		// 	  return
		//   }
		//   if(this.password.length < 6){
		//   		this.$message.error("密码至少为六位");
		//   		return
		//   }
		// let { status, data } = await Admin.register(values);
		// if (status) {
		// 	if(data.code != 200){
		// 		this.$message.error(data.message);
		// 		return
		// 	}
		// 	this.$message.success('注册成功');
		// 	this.$router.replace('/login');
		// } else {
		// 	this.$message.error(data.data);
		// }
    },
  },
};
</script>

<style lang="less">
	.register {
		.van-button {
			margin-top: 14vw;
			border-radius: 3vw;
		}
		.van-cell {
			
		}
		.van-form {
			margin: 10vw 5vw;
			.van-radio {
				overflow: visible;
			}
		}
		.van-radio {
			font-size: 3.5vw;
			margin-left: 3.5vw;
			margin-top: 5vw;
			color: #999999;
			.aggrement {
				color: #016FE1;
			}
		}
		.van-field__label {
			width: 7.2em;
		}
	}
	
</style>
