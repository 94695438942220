<template>
	<div class="basic_info">
		<div class="title">个人信息</div>
		<div class="info_box">
			<van-cell-group>
			  <van-field v-model="pageform.realname" label="姓名" placeholder="姓名将于实名认证后显示" disabled />
			</van-cell-group>
			<div class="note">提示：姓名需要与身份证保持一致</div>
			<van-cell-group>
			  <van-field v-model="pageform.mobile" label="手机号" placeholder="请输入手机号" disabled />
			</van-cell-group>
		</div>
		<div class="title">地址</div>
		<div class="info_box">
			<van-field
			  v-model="pageform.wallet"
			  center
			  
			  placeholder="请输入"
			>
			</van-field>
			<div class="btn">
				<van-button size="small" type="primary" v-clipboard:copy="pageform.wallet" v-clipboard:success="onCopy" v-clipboard:error="onError" ><span>复制</span></van-button>
				<van-button size="small" type="info"  v-show="no_bind" @click="onClickvalidation"><span>绑定</span></van-button>
				<van-button size="small" type="info" v-if="alter"  @click="onClickalter"><span>修改</span></van-button>
			</div>
			<div class="note">提示：提现时与手机绑在一起，如果想修改，请联系客服</div>
		</div>
	</div>
</template>

<script>
	import { Personal } from "@/api/index";
	  export default {
	    data() {
	      return {
			pageform:{},
	        no_bind:false,
			alter:false,
	      };
	    },
		created() {
			this.Info();
		},
		methods:{
			async Info() {
				let { status, data } = await Personal.info();
				if (status) {
					this.pageform = data.data;
					this.no_bind = this.pageform.wallet == '' || this.pageform.wallet == null
					this.alter = this.pageform.wallet != '' && this.pageform.wallet != null;
				}
			},
		//验证按钮
			onClickvalidation() {
				this.$router.push('/Info/binding')
			},
			//修改按钮
			onClickalter() {
				this.$router.push('/Info/alter')
			},
			onCopy(e){ 　　 // 复制成功
				this.$message.success('复制成功')
			},
			onError(e){　　 // 复制失败
				this.$message.success('复制失败')
			},
		},
		
	  }
</script>

<style lang="less">
	.basic_info {
		.title {
			font-size: 3.5vw;
			margin: 5vw;
			color: #124DA5;
			font-weight: bold;
		}
		.info_box {
			margin: 0 5vw;
			padding: 5vw 5vw;
			box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
			border-radius: 5vw;
			.van-cell {
				display: block;
				font-size: 3.5vw;
				color: #333333;
			}
			.note {
				color: #999999;
				font-size: 3vw;
				margin-left: 5vw;
			}
			.van-button {
				border-radius: 2vw;
				margin-top: 2vw;
				
			}
			.van-field__label {
				width: 30vw;
			}
			.van-field__control {
				border: 0.2vw solid #eee;
				padding: 1vw 3vw;
				border-radius: 2vw;
				margin-top: 2vw;
			}
		}
		[class*=van-hairline]::after {
			width: 0;
		}
		.van-dialog {
			.dialog {
				margin: 0 5vw;
			}
			.cash_num {
				margin: 2vw 5vw 0;
				color: #124DA5;
				font-weight: bold;
			}
			.van-cell__value {
				border-bottom: 0.1vw solid #ccc;
			}
			.van-cell {
				font-size: 3.5vw;
			}
			.validation {
				display: flex;
				align-items: center;
				justify-content: space-between;
				.van-button {
					width: 40vw;
					margin-right: 2vw;
					height: 10vw;
				}
			}
		}
		.van-cell:after {
			width: 0;
		}
		input{
		    -webkit-appearance: none;
		}
		.btn {
			padding: 10px 16px;
			.van-button {
				margin-right: 4vw;
			}
		}
	}
</style>
