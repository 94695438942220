<template>
	<div class="application_box">
		<div class="application">
			<div class="text">可提现</div>
			<div class="price">{{tableForm.cash_int}}</div>
			<van-cell-group>
			  <van-field v-model="nums"   placeholder="请输入提现金额" />
			</van-cell-group>
			<div class="go_all" @click="handleAll(nums)">全部提现</div>
			<van-button color="#124DA5" @click="handleSinglepack(nums)" :disabled="ifDisabled">{{BtnText}}</van-button>
		</div>
		<div class="application_bottom">
			
		</div>
	</div>
</template>

<script>
	import { Ecological } from "@/api/index";
export default {
  data() {
    return {
      nums: '',
	  tableForm:[],
	  BtnText:'分币中，今日12点开启',
	  ifDisabled:true,
    };
  },
  created() {
  	this.Coins()
	this.time_range("9:00", "12:00")
	setInterval(() => {
		this.time_range("9:00", "12:00")
	}, 5000);
  },
  methods: {
	 formate (day) {
	     if(day>=10){
	         return day
	     }else{
	         return `0${day}`
	     }
	 },
	 time_range(beginTime, endTime) {
	 	let date = new Date();
	 	let year = date.getFullYear();
	 	let m = date.getMonth() + 1;
	 	let mounth = this.formate(m);
	 	let d = date.getDate();
	 	let day = this.formate(d);
	       let strb = beginTime.split(":");
	       if (strb.length != 2) {
	         return false;
	       }
	       let stre = endTime.split(":");
	       if (stre.length != 2) {
	         return false;
	       }
	       let b = new Date();
	       let e = new Date();
	       let n = new Date();
	       b.setHours(strb[0]);
	       b.setMinutes(strb[1]);
	       e.setHours(stre[0]);
	       e.setMinutes(stre[1]);
	       if (n.getTime() - b.getTime() >= 0 && n.getTime() - e.getTime() < 0) {
	         this.ifDisabled = true;
	         this.BtnText = '分币中，今日12点开启'
	       } else {
	         this.ifDisabled = false;
	         this.BtnText = '提现'
	       }
	     },
	  async Coins() {
	  	let { status, data } = await Ecological.coins();
	  	if (status) {
	  		this.tableForm = data.data;
	  	}
	  },
		handleAll (nums) {
			this.nums = this.tableForm.cash_int;
		},
		 async handleSinglepack (nums) {
				let { status, data} = await Ecological.packagewithdrawal({nums:nums});
				if(status) {
					if(data.code == 200){
						this.$message.success(data.data);
						this.$router.replace('/Ecological/package')
					}else{
						this.$message.error(data.message);	
					}
				}
			},
  }
};
</script>

<style lang="less">
	.application_box {
		.application {
			margin-top: 10vw;
			padding-bottom: 6vw;
			border-bottom: 0.2vw solid #eee;
			.van-cell {
				border: 0.2vw solid #124DA5;
				width: 50%;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				border-radius: 4vw;
			}
			.text {
				text-align: center;
				font-size: 3vw;
				color: #333;
				font-weight: bold;
			}
			.price {
				text-align: center;
				font-size: 3vw;
				color: #333;
				padding-top: 3vw;
				padding-bottom: 5vw;
			}
			.go_all {
				text-align: center;
				margin-top: 16vw;
				font-size: 3.5vw;
				color: #124DA5;
			}
		}
		[class*=van-hairline]::after {
			width: 0;
		}
		.application_bottom {
			padding: 5vw;
			.line {
				padding-bottom: 4vw;
				display: flex;
				height: 5vw;
				line-height: 5vw;
				font-size: 4vw;
				color: #333333;
				.name {
					width: 25vw;
				}
				.content_spacial {
					height: 30vw;
					width: 65vw;
				}
			}
		}
		.van-button {
			border-radius: 1vw;
			position: absolute;
			right: 10vw;
			top: 40vw;
			width: 25vw;
			height: 11vw;
		}
	}
</style>
