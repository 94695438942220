import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Login from	'../views/Admin/login.vue'
import Register from '../views/Admin/register.vue'
import Lose from '../views/Admin/404.vue'
import Forget from '../views/Admin/forget.vue'
import Modification from '../views/Admin/modification.vue'
import Home from '../components/Layout.vue'
import Homeindex from '../views/Home/index.vue'
import Operating from '../components/Layout.vue'
import Server from '../components/Layout.vue'
import Announcement from '../components/Layout.vue'
import Info from '../components/Layout.vue'
import Assets from '../components/Layout.vue'
import Asset from '../views/Assets/assets.vue'
import Assetslist from '../views/Assets/list.vue'
import ServerList from '../views/Server/list.vue'
import OperatingList from '../views/Operating/list.vue'
import OperatingRecord from '../views/Operating/record.vue'
import OperatingDetails from '../views/Operating/details.vue'
import ServerDetail from '../views/Server/detail.vue'
import AnnouncementList from '../views/Announcement/list.vue'
import Personal from '../views/Info/personal.vue'
import Binding from '../views/Info/binding.vue'
import Alter from '../views/Info/alter.vue'
import PersonalCertification from '../views/Info/certification.vue'
import PersonalWallet from '../views/Info/wallet.vue'
import PersonalPassword from '../views/Info/password.vue'
import Ecological from '../components/Layout.vue'
import EcoIndex from '../views/Ecological/index.vue'
import Package from '../views/Ecological/package.vue'
import Packagedetails from '../views/Ecological/details.vue'
import Packagewithdrawal from '../views/Ecological/withdrawal.vue'
import Pledgedwithdrawal from '../views/Ecological/pledgedwithdrawal.vue'
import Packagetotal from '../views/Ecological/total.vue'
import Packagedetail from '../views/Ecological/detail.vue'
import Buypackage from '../views/Ecological/buypackage.vue'
// import Buy from '../components/Layout.vue'
// import Second from '../views/Buy/second.vue'
// import BuyFirst from '../views/Buy/first.vue'
import Distribution from '../components/Layout.vue'
import DistributionList from '../views/Distribution/list.vue'
import DistributionCashdetail from '../views/Distribution/cashdetail.vue'
import Contributiondetail from '../views/Distribution/contributiondetail.vue'
import Exchangedetail from '../views/Distribution/exchangedetail.vue'
import DistributionApplication from '../views/Distribution/application.vue'
import Contribution from '../views/Distribution/contribution.vue'
import DistributionContribution from '../views/Distribution/contribution.vue'
import Tradingdetail from '../views/Distribution/tradingdetail.vue'
import Exchange from '../views/Distribution/exchange.vue'
const routes = [{ 
	path: '/login',
	name: 'Login',
	alias: "/",
	component: Login,
},
{
	path: '/register',
	name: 'Register',
	component: Register
},
{
	path: '*',
	name: '404',
	component: Lose
},
{
	path: '/forget',
	name: 'Forget',
	component: Forget
},
{
	path: '/modification',
	name: 'Modification',
	component: Modification
},
{
	path:"/Home",
	name:'Home',
	component: Home,
	children:[
	{
		path: "index",
		name: "Index",
		component: Homeindex,
	},]
},
{
	path:"/Assets",
	name:'Assets',
	component: Assets,
	children:[
	{
		path: "assets",
		name: "Asset",
		component: Asset,
	},
	{
		path: "list",
		name: "List",
		component: Assetslist,
	},]
},
{
	path:"/Operating",
	name:'operating',
	component: Operating,
	children:[
	{
		path: "list",
		name: "List",
		component: OperatingList,
	},
	{
		path: "record",
		name: "Record",
		component: OperatingRecord,
	},
	{
		path: "details",
		name: "Details",
		component: OperatingDetails,
	},]
},
{
	path:"/Server",
	name:'Server',
	component: Server,
	children:[
	{
		path: "list",
		name: "List",
		component: ServerList,
	},
	{
		path: "detail",
		name: "Detail",
		component: ServerDetail,
	},]
},
// {
// 	path:"/Buy",
// 	name:'Buy',
// 	component: Buy,
// 	children:[
// 	{
// 		path: "first",
// 		name: "First",
// 		component: BuyFirst,
// 	},
// 	{
// 		path: "second",
// 		name: "Second",
// 		component: Second,
// 	},]
// },
{
	path:"/Announcement",
	name:'Announcement',
	component: Announcement,
	children:[
	{
		path: "list",
		name: "List",
		component: AnnouncementList,
	},]
},
{
	path:"/Ecological",
	name:'Ecological',
	component: Ecological,
	children:[
	{
		path: "index",
		name: "EcoIndex",
		component: EcoIndex,
	},
	{
		path: "package",
		name: "Package",
		component: Package,
	},
	{
		path: "buypackage",
		name: "Buypackage",
		component: Buypackage,
	},
	{
		path: "details",
		name: "Packagedetails",
		component: Packagedetails,
	},
	{
		path: "withdrawal",
		name: "Packagewithdrawal",
		component: Packagewithdrawal,
	},
	{
		path: "pledgedwithdrawal",
		name: "Pledgedwithdrawal",
		component: Pledgedwithdrawal,
	},
	{
		path: "total",
		name: "Packagetotal",
		component: Packagetotal,
	},{
		path: "detail",
		name: "Packagedetail",
		component: Packagedetail,
	},]
},
{
	path:"/Distribution",
	name:'Distribution',
	component: Distribution,
	children:[
	{
		path: "list",
		name: "List",
		component: DistributionList,
	},
	{
		path: "tradingdetail",
		name: "Tradingdetail",
		component: Tradingdetail,
	},
	{
		path: "cashdetail",
		name: "Cashdetail",
		component: DistributionCashdetail,
	},
	{
		path: "contributiondetail",
		name: "Contributiondetail",
		component: Contributiondetail,
	},
	{
		path: "exchangedetail",
		name: "Exchangedetail",
		component: Exchangedetail,
	},
	{
		path: "application",
		name: "Application",
		component: DistributionApplication,
	},
	{
		path: "contribution",
		name: "Contribution",
		component: DistributionContribution,
	},
	{
		path: "exchange",
		name: "Exchange",
		component: Exchange,
	},]
},
{
	path:"/Info",
	name:'Info',
	component: Info,
	children:[
	{
		path: "personal",
		name: "Personal",
		component: Personal,
	},
	{
		path: "certification",
		name: "Certification",
		component: PersonalCertification,
	},
	{
		path: "wallet",
		name: "Wallet",
		component: PersonalWallet,
	},
	{
		path: "password",
		name: "Password",
		component: PersonalPassword,
	},
	{
		path: "binding",
		name: "Binding",
		component: Binding,
	},
	{
		path: "alter",
		name: "Alter",
		component: Alter,
	},]
},
]

const router = new VueRouter({
  routes,
  mode: 'history'
})


export default router
