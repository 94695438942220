import axios from "axios";
import Vue  from "vue";
import {setCookie,getCookie} from '@/assets/js/cookie.js'
//设置baseURL
// axios.defaults.baseURL = "http://zlbf.user.com/";
axios.defaults.baseURL = "https://api.365supports.com/";
// axios.defaults.baseURL = "http://192.168.13.185:1111/";
Vue.prototype.$base_url = axios.defaults.baseURL

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
   let token = localStorage.getItem('token');
   if(token == '' || token == null){
   	token = getCookie('token')
   	localStorage.token = token
   }
   if (config.method === 'get' || config.method === 'delete') {
       config.params.token = token;
   } else {
       config.data.token = token;
   }
    // 在发送请求之前做些什么
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use((response) => {
    //请求异常报错处理
    if(response.status === 204 && response.config.url === '/logout'){
        setTimeout(() => {
            window.location.href = "/login/";
        }, 800);
    }else{
        if(response.data.code === -1){
            setTimeout(() => {
                window.location.href = "/login/";
            }, 800);
			
            return
        }
        // 对响应数据做点什么
        return response;
    }
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});