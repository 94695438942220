import axios from "axios"
//套餐列表
let packagelist = (data = {}) => axios.get('/api/meals', { params:data });
//我的套餐列表
let mypackagelist = (data = {}) => axios.get('/api/user_meals', { params:data });
//我的套餐详情
let packagedetails = (data= {}) => axios.get('/api/user_meals/'+data.id, { params:data});
//购买套餐
let buymeal = (data= {}) => axios.post('/api/meals/buy', data);
//生态明细
// let mealRecords = (data= {}) => axios.get('/api/user_meals/records', { params:data});
//分红明细
let bonus = (data= {}) => axios.get('/api/meals/recommends', { params:data});
//生态提现
let packagewithdrawal = (data = {}) => axios.post('/api/user_meals/withdraw',{nums:data.nums});
//质押释放提现
let releasewithdrawal = (data = {}) => axios.post('/api/user_meals/pledged_withdraw',{nums:data.nums});
//生态提现记录
let packagerecord = (data = {}) => axios.get('/api/user_meals/withdraw/list', { params:data});
//生态币详情
let coins = (data = {}) => axios.get('/api/user_meals/coins', { params:data});
//校验推荐码
let check = (data= {}) => axios.post('/api/meals/check_phone', data);
//生态合计
let packagetotal = (data = {}) => axios.get('/api/meals/nums_count', { params:data });
//每日生态明细/每日分红明细
let day = (data= {}) => axios.get('/api/meals/day', { params:data});
//生态特殊管理状态
let manage = (data= {}) => axios.post('/api/meals/manage', data);
//生态明细列表（包含生态可提现）
let usablecoins = (data = {}) => axios.get('/api/user_meals/usablecoins', { params:data });
export default {
	packagelist,
	mypackagelist,
	packagedetails,
	buymeal,
	// mealRecords,
	bonus,
	packagewithdrawal,
	packagerecord,
	coins,
	check,
	packagetotal,
	day,
	manage,
	usablecoins,
	releasewithdrawal
}