<template>
	<div class="buypackage">
		<div class="item">
				<div class="tit">
					<div>{{item.title}}</div>
				</div>
				<div class="base_num">
					<div class="nums">{{item.base_number}}</div>
					<div class="text">基数</div>
				</div>
				<div class="release">
					<div class="nums">{{item.release_days + item.waiting_days}}</div>
					<div class="text">释放天数</div>
				</div>
				<div class="waiting">
					<div class="nums">{{item.waiting_days}}</div>
					<div class="text">生效天数</div>
				</div>
				<div class="number">总份数:{{buyitem.total_nums}}（剩余份数:{{remaining}}）</div>
				<div class="date">有效日期:{{dateFmt(item.start_time)}}到 {{dateFmt(item.end_time)}}</div>
		</div>
		<p class="title">质押</p>
		<div class="meal_box">
			<div class="base">
				<div>基数: {{buyitem.base_number}}</div>
				<div style="padding-top: 2vw;">总数:{{buyitem.total_nums}}份   (剩余{{remaining}}份)</div>
			</div>
			<div class="tit">份数<span style="color: #D20000;">*</span></div>
			<div class="step">
				<van-stepper  v-model="buyitem.buy_num" input-width="60vw" button-size="10vw" @change="NumberChange" step="1" min="1" :max="this.remaining" />
			</div>
			<div class="total">合计:{{totol_meals}}</div>
			<!-- <div class="total" style="color: #D80000;">请向以下地址转入{{totol_meals}}个,并上传凭证</div> -->
			<div class="balance" >
				<div >可划转余额 (  {{buyitem.cash}}  )<span style="color: #D20000;">*</span></div>
				<van-field class="balance_input" v-model="buyitem.coins"  type="digit" />
				<div class="notes" v-if="notes">余额不足请向以下地址转入{{totol_balance}}，并上传凭证</div>
				<div class="notes" v-if="redundance">可划转余额超过购买数额</div>
				<div class="notes" v-if="available">可划转余额超过可用数额</div>
			</div>
			<div class="address_box">
				<div class="address">
					<div class="address_name">质押地址<span style="color: #D20000;">*</span></div><div class="qr" @click="handleQr()"><span>质押二维码</span><van-icon name="arrow" /></div>
				</div>
				<div class="zhiya" v-clipboard:copy="Zhiya" v-clipboard:success="onCopy" v-clipboard:error="onError" >f1v7owjsy2mekckz24nvurv3eev43o26apsq6wl7a</div>
				<div class="copyaddress" v-clipboard:copy="Zhiya" v-clipboard:success="onCopy" v-clipboard:error="onError">点击复制</div>
				<div class="note">注:支付前请务必电话确认地址</div>
			</div>
			<div style="border-bottom: 1px solid #e9e9e9;"   v-if="notes">
				<div class="tit_font">凭证图片<span style="color: #D20000;">*</span></div>
				<van-uploader v-model="fileList" multiple :after-read="upload" max-count="6" @delete="delimage" />
			</div>
			<div class="recommend" style="display: none;">
				<div >邀请人(一般为邀请人的手机号或用户名)<span style="color: #D20000;">*</span></div>
				<div class="recommend_item">
					<van-field v-model="buyitem.tuijianma" class="recommend_input"  placeholder="请填写手机/用户名" :disabled="isSpecial" />
					<van-button plain color="#A4BADB"  @click = "check()" v-if="buyitem.tuijianma != '' && buyitem.tuijianma != null ">验证</van-button>
				</div>
				<div class="msg" v-if="kongtuijian">
					<img src="../../assets/img/icon_em.png" alt="zlbf">
					<span>如不填写邀请人，邀请人将默认为平台。</span>
				</div>
				<div class="msg" v-if="truetuijian">
					<img src="../../assets/img/icon_right.png" alt="zlbf">
					<span>验证有效   邀请人：{{tuijianForm.agent_name}}-{{tuijianForm.username}}   手机号：{{tuijianForm.phone}}</span>
				</div>
				<div class="msg" v-if="falsetuijian">
					<img src="../../assets/img/icon_mistake.png" alt="zlbf">
					<span>验证无效   您所填写的邀请人无效！</span>
				</div>
			</div>
			<div class="btn"><van-button color="#124DA5" :disabled='isDisabled' @click="onBuy()">质押</van-button></div>
		</div>
		<van-popup v-model="show" closeable class="qrimg">
			<div class="title">请扫码质押</div>
			<div class="box">
				<img width="191px" src="../../assets/img/img_qrcode.png" alt="zlbf">
				<div class="save">长按二维码保存</div>
				<div class="text">质押地址</div>
				<div class="address_text" v-clipboard:copy="Zhiya" v-clipboard:success="onCopy" v-clipboard:error="onError">f1v7owjsy2mekckz24nvurv3eev43o26apsq6wl7a</div>
			</div>
			<div class="bot_note">请勿向上述地址充值非相关资产，否则资产将不可找回</div>
		</van-popup>
	</div>
</template>

<script>
import {Ecological,Buy,Home} from "@/api/index";
export default {
  data() {
    return {
		show:false,
		available:false,
		isSpecial:false,
		kongtuijian:false,
		truetuijian:false,
		falsetuijian:false,
		isDisabled:true,
		redundance:false,
		notes:false,
		Zhiya:'f1v7owjsy2mekckz24nvurv3eev43o26apsq6wl7a',
		base_number:'',
		buyitem:{
			meal_id:0,
			base_number:0,
			buy_proof:'',
			buy_num:0,
			coins:0,
			tuijianma:'',
			agents:'',
			cash:0,
			total_nums:0,
			total_buy_nums:0,
		},
		fileList: [],
		image_urls:[],
		item:{
			start_time:'',
			end_time:'',
		},
		tuijianForm:{},
    };
  },
  created() {
	  this.item = this.$route.params;
	  this.buyitem.meal_id = this.item.id;
	  this.buyitem.base_number = this.item.base_number;
	  this.buyitem.total_nums = this.item.total_nums;
	  this.buyitem.total_buy_nums = this.item.total_buy_nums;
	  //剩余份数为0时不能质押
	  if(this.remaining == 0){
	  		this.isDisabled = true;
	  }else {
	  		this.isDisabled = false;
	  }
	  this.buy();
	  //避免页面刷新 数据丢失
	  if(this.item.id == undefined ||this.item.base_number == undefined || this.item.total_nums == undefined || this.item.total_buy_nums == undefined) {
		  this.$router.push('/Ecological/index');
	  }
  },
  methods:{
	  NumberChange(currentValue) {
	  	this.buyitem.coins = this.totol_meals > this.buyitem.cash? this.buyitem.cash:this.totol_meals;
	  	
	  },
	  //二维码
	  handleQr() {
		  this.show = true;
	  },
	  upload(file){
	  		let images = []
	  		if(Object.prototype.toString.call(file)==='[object Object]'){
	  			images = [file]
	  		}else{
	  			images = file
	  		}
	  		self = this
	  		let token = localStorage.getItem('token')
	  		images.forEach(function (item) {
	  			let formData = new window.FormData();
	  			formData.append('token',token)
	  			formData.append('image',item.file)
	  			Buy.ImageUpload(formData).then(res =>{
	  				item.message = res.data.data
	  				self.image_urls.push(res.data.data)
	  			}).catch(err => {
	  			})
	  		})
	  	},
	  	delimage(file){
	  		let i = file.message
	  		this.image_urls = this.image_urls.filter(item => item != i)
	  	},
	  	async buy() {
			let { status, data } = await Home.total();
			if(status) {
				this.buyitem.cash = data.data.coins.ecology_int;
				// this.buyitem.cash = 0
				this.buyitem.coins = this.totol_meals > this.buyitem.cash? this.buyitem.cash:this.totol_meals;
				this.buyitem.agents = data.data.agent
				this.buyitem.area = data.data.area_partner
				this.isSpecial = this.buyitem.agents == 4 || this.buyitem.agents == 5 || this.buyitem.agents == 9 ||this.buyitem.area == 1;
				if (this.isSpecial) {
					this.buyitem.tuijianma = data.data.phone
				}else {
					this.buyitem.tuijianma = data.data.meal_tuijianma;
				}
				this.kongtuijian = this.buyitem.tuijianma == '' || this.buyitem.tuijianma == null;
			}
		},
		//推荐码
		async check() {
			let { status, data } = await Ecological.check(this.buyitem);
			if (status) {
				if (data.code == 200) {
					this.tuijianForm = data.data
					this.truetuijian = true;
					this.kongtuijian = false;
					this.falsetuijian = false;
				}else {
					if(this.buyitem.tuijianma == '' || this.buyitem.tuijianma == null) {
						this.kongtuijian = true;
						this.falsetuijian = false;
						this.truetuijian = false;
					}else {
						this.$message.error(data.message)
						this.falsetuijian = true;
						this.kongtuijian = false;
						this.truetuijian = false;
					}
					
				}
			} else {
				this.$message.error('请求失败')
			}
		},
		async onBuy(){
	  		if(this.image_urls.length == 0 &&this.buyitem.coins < this.totol_meals){
	  			this.$message.error("请上传付款凭证")
	  			return
	  		}
			if(this.buyitem.coins > this.buyitem.cash){
				this.$message.error("输入余额超出可用划转余额");
				return
			}
			if(this.buyitem.coins > this.totol_meals){
				this.$message.error("输入余额超出购买数量");
				return
			}
	  		this.buyitem.buy_proof = this.image_urls.join(',')
	  		let {status,data} = await Ecological.buymeal(this.buyitem);
	  		if (status) {
	  			if(data.code == 200){
	  				this.$message.success(data.data);
					this.isDisabled = true;
	  				this.$router.push('/Ecological/package');
	  			}else{
	  				this.$message.error(data.message)
	  			}
	  		}
	  	},
	 onCopy(e){ 　　 // 复制成功
	 	this.$message.success('复制成功')
	 },
	 onError(e){　　 // 复制失败
	 	this.$message.error('复制失败')
	 },
	 dateFmt(date){
	 	if (date == null || date == undefined){
	 		return '';
	 	}
	 	return date.slice(0,4)+'年'+date.slice(5,7)+'月'+date.slice(8,10)+'日';
	 }
  },
  watch: {
  	buyitem:{
  		handler(value) {
  			this.notes = false;
  			this.redundance = false;
  			this.available = false;
  			    //余额不足购买数量
  			if(value.coins < this.totol_meals) {
  				this.notes = true;
  				//输入余额超出可用余额
  			}else if(value.coins > this.buyitem.cash){
  				this.available = true;
  				//输入余额超出购买数量
  			}else if(value.coins > this.totol_meals ){
  				this.redundance = true
  			}
  		},
  
  		deep:true
  	},
  },
  computed:{
  	totol_meals(){
  		return this.item.base_number * this.buyitem.buy_num;
  	},
	totol_balance(){
		return this.buyitem.base_number * this.buyitem.buy_num - this.buyitem.coins;
	},
	remaining(){
		return this.buyitem.total_nums - this.buyitem.total_buy_nums;
	},
  }
};
</script>

<style lang="less">
	.buypackage {
		background-color: #F7F9FC;
		min-height: 100vh;
		padding: 5vw 2.5vw;
		.qrimg {
			padding: 5vw 5vw;
			border-radius: 3vw;
			.title {
				font-size: 4vw;
				color: #333333;
				text-align: center;
				padding-bottom: 5vw;
			}
			.box {
				background-color: #F7F6FB;
				padding: 5vw 5vw;
				text-align: center;
				.save {
					font-size: ;
					color: #1F71EB;
					margin: 5vw 0;
				}
				.text {
					color: #CCCCCC;
					font-size: 3.5vw;
				}
				.address_text {
					color: #333333;
					font-size: 3.5vw;
					padding-top: 3vw;
				}
			}
			.bot_note {
				font-size: 3.5vw;
				color: #999999;
				padding: 5vw 0;
			}
		}
		.item {
			background: url(../../assets/img/img_beijing.png) no-repeat center center;
			background-size: cover;
			width: 95vw;
			height: 56vw;
			position: relative;
			.tit {
				font-size: 4.5vw;
				font-weight: bold;
				color: #ffffff;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				top: 7vw;
			}
			.base_num {
				text-align: center;
				position: absolute;
				left: 11vw;
				top: 20vw;
				.nums {
					font-size: 5vw;
					color: #124DA5;
					font-weight: bold;
				}
				.text {
					font-size: 4vw;
					color: #999999;
					padding-top: 1vw;
				}
			}
			.release {
				text-align: center;
				position: absolute;
				left: 41vw;
				top: 20vw;
				.nums {
					font-size: 5vw;
					color: #124DA5;
					font-weight: bold;
				}
				.text {
					font-size: 4vw;
					color: #999999;
					padding-top: 1vw;
				}
			}
			.waiting {
				text-align: center;
				position: absolute;
				left: 66vw;
				top: 20vw;
				.nums {
					font-size: 5vw;
					color: #124DA5;
					font-weight: bold;
				}
				.text {
					font-size: 4vw;
					color: #999999;
					padding-top: 1vw;
				}
			}
			.number {
				position: absolute;
				bottom: 14vw;
				left: 11vw;
				font-size: 3vw;
				color: #999999;
			}
			.date {
				position: absolute;
				bottom: 7vw;
				left: 11vw;
				font-size: 3.5vw;
				color: #999999;
			}
		}
		.title {
			font-size: 4.5vw;
			color: #124DA5;
			font-weight: bold;
		}
		.meal_box {
			background-color: #ffffff;
			padding: 10vw 5vw;
			border-radius: 3vw;
			margin-top: 4vw;
			.base {
				padding-bottom: 5vw;
				border-bottom: 1px solid #e9e9e9;
			}
			.address_box {
				border-bottom: 1px solid #e9e9e9;
				border-top: 1px solid #e9e9e9;
				.address {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 5vw 0 3vw 0;
					
					.address_name {
						color: #333333;
						font-size: 4vw;
					}
				}
				.copyaddress {
					color: #146FF8;
					font-size: 4vw;
					padding-bottom: 2vw;
				}
				.qr {
					color: #146FF8;
					font-size: 4vw;
					span,.van-icon {
						vertical-align: middle;
					}
				}
				.zhiya {
					padding: 0 0 2vw 0;
					word-break: break-word;
					width: 87vw;
				}
				.note {
					font-size: 3.5vw;
					color: #D80000;
					padding-bottom: 5vw;
				}
			}
			.tit {
				padding-top: 3vw;
				font-size: 4.5vw;
				color: #333333;
			}
			.step {
				display: flex;
				justify-content: center;
				margin-top: 3vw;
			}
			.total {
				font-size: 3vw;
				color: #999999;
				margin-top: 3vw;
				margin-bottom: 3vw;
				padding: 0 3vw;
			}
			.tit_font {
				font-size: 4.5vw;
				color: #333333;
				margin-top: 5vw;
				margin-bottom: 5vw;
			}
			.btn {
				margin-top: 5vw;
				.van-button {
					width: 100%;
					font-size: 4vw;
					border-radius: 2vw;
				}
			}
			.balance {
				padding: 5vw 0;
				border-top: 1px solid #e9e9e9;
				.balance_input {
					border: 0.1vw solid #E5E5E5;
					border-radius: 3.5vw;
					margin-top: 2vw;
				}
				.notes {
					font-size: 3.5vw;
					color: #E30000;
					padding-top: 2vw;
				}
			}
			.recommend {
				padding-top: 2vw;
				
				.recommend_item {
					display: flex;
					justify-content: space-between;
					align-items: center;
					.recommend_input {
						border: 0.1vw solid #E5E5E5;
						border-radius: 3.5vw;
						margin-top: 2vw;
						width: 65%;
					}
					.van-button {
						margin-top: 2vw;
						border-radius: 2.5vw;
						color: #1F71EB!important;
					}
					.van-button--normal {
						padding: 0 8vw;
					}
				}
				.msg {
					padding-top: 2vw;
					img {
						vertical-align: middle;
						margin-right: 2vw;
					}
					span {
						vertical-align: middle;
						color: #333333;
						font-size: 3.5vw;
					}
				}
			}
			
		}
	}
</style>
