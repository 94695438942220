<template>
	<div>
		<van-row>
		   <router-view></router-view>
		</van-row>
		
		<van-row>
		  <Tabbar></Tabbar>
		</van-row>
	</div>
</template>

<script>
import Tabbar from './Tabbar';
export default {
	components: {
		Tabbar,
	},

}
</script>

<style>
	
</style>
