<template>
	<div>
		<van-empty description="该页面无法访问404" />
	</div>
</template>

<script>
	export default {
		
	}
</script>

<style>
	
</style>
