<template>
	<div class="package">
		<div class="title">
			<div>My pledge</div>
			<div class="special" v-if="this.infoform.id == 1089"><div class="text" v-if="!checked">开启生态质押</div><div class="text" v-if="checked">关闭生态质押</div><van-switch-cell v-model="checked" active-color="#00D2BD" @change='manage(checked)' /></div>
		</div>
		<div class="dec">
			<div>我的质押</div>
			<div class="zhiya_btn"><van-button color="#3988FF" @click="gobuy">质押</van-button></div>
		</div>
		<div class="package_detail">
			<div class="package_box">
				<div class="item">
					<p class="text">质押总数</p>
					<p class="number">{{Formdata.total_meals}}</p>
				</div>
				<div class="item">
					<p class="text">生态产出总数</p>
					<p class="number">{{Formdata.total}}</p>
				</div>
				<div class="item item-total" >
					<p class="text">总锁仓数量</p>
					<p class="number">{{Formdata.locked}}</p>
				</div>
			</div>
		</div>
		<div class="package_bottom">
			<div class="item">
				<p class="number">{{Formdata.cash}}</p>
				<p class="text">实际可提现:{{Formdata.cash_int}}</p>
			</div>
			<div class="item">
				<p class="number">{{Formdata.pledged}}</p>
				<p class="text">质押释放总数</p>
			</div>
		</div>
		
		<div class="packageBtn">
			<div class="btn_operation">
				<van-button v-if="judge_realname"  type="info" @click="goAuthentication()">请实名认证</van-button>
				<van-button  v-if="judge_wallet" plain type="info" @click="onClickvalidation">钱包与手机验证</van-button>
			</div>
			<div class="btn_withdrawal">
				<van-button  v-if="judge_withdrawal" plain type="info" @click="onClickWarnDialog" :disabled="ifDisabled">{{BtnText}}</van-button>
			</div>
			<div class="btn_withdrawal">
				<van-button  plain type="info" @click="ReleaseWarnDialog" v-if="isPledged && judge_withdrawal">质押提现</van-button>
			</div>
		</div>
		<div class="form_list">
			<div class="package_total">
				<div class="text">生态合计:{{Formdata.sons_nums}}</div>
				<div><van-button color="#3988FF" size="small" v-if="judge_total" @click="gototal()" >详情</van-button></div>
			</div>
			<van-tabs v-model="active"  @change="tabchange">
				<van-tab title="我的质押" style="margin-bottom: 15vw;" name="0">
					<van-list v-model="loading" :finished="finished"  finished-text="没有更多了"  @load="onLoadMypackagelist()">
						<div class="package-list">
								<div class="item" @click="goParamMy(item)"  v-for="(item,index) in tableData">
									<div class="top">
										<div>
											<div >份数:{{item.buy_number}}</div>
											<div style="padding-top: 1vw;">质押:{{item.total_meals}}</div>
										</div>
										<div>
											<div>生效天数:{{item.waiting_days}}</div>
											<div v-show="item.lose_waiting_days != -1" style="padding-top: 1vw;">倒计时:{{item.lose_waiting_days}}天 <span v-show="item.lose_waiting_days == 0" style="color: #00D2BD;">(已生效)</span> </div>
										</div>
									</div>
									<!-- <div class="middle">
										<div >Gas:{{item.total_gas}}</div>
									</div> -->
									<div class="bottom">
										<div class="release" style="color: #999999;">
											开始时间:{{dateFmt(item.start_time)}}
										</div>
										<div class="date">
											<span v-if="item.status==0" style="color: #124DA5;vertical-align: middle;">审核中</span>
											<span v-if="item.status==1" style="color: #00D2BD;vertical-align: middle;">审核通过</span>
											<span v-if="item.status==2" style="color: #F56C6C ;vertical-align: middle;">审核未通过</span>
											<span v-if="item.status==3" style="color: #F56C6C;vertical-align: middle;">已到期</span>
											<van-icon name="arrow" style="vertical-align: middle;" />
										</div>
									</div>
								</div>
						</div>
					</van-list>
				</van-tab>
				<van-tab title="提现记录" style="margin-bottom: 15vw;" name="1">
					<van-list v-model="loading"  :finished="finished"  finished-text="没有更多了"  @load="onLoadPackagerecord()">
						<div class="package-list">
								<div class="item"  v-for="(item,index) in PackagerecordData" >
									<div class="top">
										<div>
											<span>申请提现</span>
										</div>
										<div>
											<span v-if="item.status == 1" style="color: #E80000;">待审核</span>
											<span v-if="item.status == 2" style="color: #124DA5;">审核中</span>
											<span v-if="item.status == 3" style="color: #E80000;">审核失败</span>
											<span v-if="item.status == 5" style="color: #124DA5;">审核通过</span>
										</div>
									</div>
									<div class="bottom">
										<div class="release">
											<span style="color: #E80000;font-weight: bold;">{{item.money}}</span>
										</div>
										<div class="date">{{item.applytime}}</div>
									</div>
								</div>
						</div>
					</van-list>
				</van-tab>
				<van-tab title="生态明细" style="margin-bottom: 15vw;" name="2">
					<van-list v-model="loading"  :finished="finished"  finished-text="没有更多了"  @load="onLoadMyzhiyaData()">
						<div class="package-list">
							<div class="item"  v-for="(item,index) in MyzhiyaData" @click="goParam(item)" >
								<div class="top">
									<div>
										<span>套餐</span>
									</div>
									<div class="package_title">
										<span>{{item.title}}</span>
										<van-icon name="arrow" />
									</div>
								</div>
								<div class="bottom">
									<div class="release">
										<span style="color: #333333;font-weight: bold;">基数</span>
									</div>
									<div class="date"  style="color: #E80000;font-weight: bold;">{{item.base_number}}</div>
								</div>
								<div class="bottom">
									<div class="release">
										<span style="color: #333333;font-weight: bold;">释放天数</span>
									</div>
									<div class="date"  style="color: #00D2BD;font-weight: bold;">{{item.release_days + item.waiting_days}}</div>
								</div>
								<div class="bottom">
									<div class="release">
										<span style="color: #333333;font-weight: bold;">生效天数</span>
									</div>
									<div class="date"  style="color: #00D2BD;font-weight: bold;">{{item.waiting_days}}</div>
								</div>
								<div class="bottom">
									<div class="release">
										<span style="color: #333333;font-weight: bold;">可提现数</span>
									</div>
									<div class="date"  style="color: #00D2BD;font-weight: bold;">{{item.cash}}</div>
								</div>
								<div class="bottom">
									<div class="release">
										<span style="color: #333333;font-weight: bold;">锁仓数</span>
									</div>
									<div class="date"  style="color: #00D2BD;font-weight: bold;">{{item.locked}}</div>
								</div>
							</div>
						</div>
					</van-list>
				</van-tab>
			</van-tabs>
		</div>
		<van-dialog v-model="warnDialog" title="重要提示" confirmButtonText='确定' show-cancel-button @confirm="onClickMultiple">
			<div class="warn_text">
				<div class="text">请确认您的地址不是火币交易所地址</div>
				<div class="text">{{infoform.wallet}}</div>
				<div class="text" style="color: darkred;">火币交易所地址不可提现，禁止使用</div>
			</div>
		</van-dialog>
		<van-dialog v-model="releaseWarnDialog" title="重要提示" confirmButtonText='确定' show-cancel-button @confirm="goPledged">
			<div class="warn_text">
				<div class="text">请确认您的地址不是火币交易所地址</div>
				<div class="text">{{infoform.wallet}}</div>
				<div class="text" style="color: darkred;">火币交易所地址不可提现，禁止使用</div>
			</div>
		</van-dialog>
	</div>
</template>

<script>
import {Ecological,Personal} from "@/api/index";
export default {
	data() {
	  return {
		infoform:{
			wallet:'',
		},
		warnDialog:false,
		releaseWarnDialog:false,
		BtnText:'分币中，今日12点开启',
		ifDisabled:true,
		checked:false,//生态开关
		active: 0,
	   tableData: [],
	   PackagerecordData:[],
	   MyzhiyaData:[],
	   infoform:[],
	  //刷新
	   loading: false,
	   finished: false,
	   data: [], 
	   page: {
	  	current_page: 1,
	    pagesize: 10
	  },
	  judge_withdrawal:false,
	  judge_realname:false,
	  judge_wallet:false,
	  judge_total:false,
	  Formdata:{},
	  isPledged:false,
	  };
	},
	created() {
		this.Coins()
		this.time_range("9:00", "12:00")
		setInterval(() => {
			this.time_range("9:00", "12:00")
		}, 5000);
	},
	methods:{
		formate (day) {
		    if(day>=10){
		        return day
		    }else{
		        return `0${day}`
		    }
		},
		time_range(beginTime, endTime) {
			let date = new Date();
			let year = date.getFullYear();
			let m = date.getMonth() + 1;
			let mounth = this.formate(m);
			let d = date.getDate();
			let day = this.formate(d);
		      let strb = beginTime.split(":");
		      if (strb.length != 2) {
		        return false;
		      }
		      let stre = endTime.split(":");
		      if (stre.length != 2) {
		        return false;
		      }
		      let b = new Date();
		      let e = new Date();
		      let n = new Date();
		      b.setHours(strb[0]);
		      b.setMinutes(strb[1]);
		      e.setHours(stre[0]);
		      e.setMinutes(stre[1]);
		      if (n.getTime() - b.getTime() >= 0 && n.getTime() - e.getTime() < 0) {
		        this.ifDisabled = true;
		        this.BtnText = '分币中，今日12点开启'
		      } else {
		        this.ifDisabled = false;
		        this.BtnText = '生态提现'
		      }
		    },
		//跳转实名
		goAuthentication() {
			this.$router.push('/Info/certification')
		},
		//跳转钱包验证
		onClickvalidation() {
			this.$router.push('/Info/binding')
		},
		//生态通证详情
		async Coins() {
			let { status, data } = await Ecological.coins();
			if (status) {
				this.Formdata = data.data;
				if(this.Formdata.pledged > 0) {
					this.isPledged = true;
				}else {
					this.isPledged = false;
				}
				this.Ifinfo()
			}
		},
		//判断信息
		async Ifinfo() {
			let { status, data } = await Personal.info();
			if(status) {
				this.infoform = data.data;
			}
			//特殊id
			if(this.infoform.grade == "off") {
				this.checked =false;
			} else {
				this.checked =true;
			}
			//提现判断
			this.judge_withdrawal = this.infoform.status==5 && this.Formdata.cash > 0 && this.infoform.mobile_status == 1 && this.infoform.unecologys !== 1;
			//实名判断
			this.judge_realname = this.infoform.status != 5;
			//钱包判断
			this.judge_wallet = (this.infoform.wallet == '' ||this.infoform.wallet == null) && this.infoform.mobile_status == 0;
			//生态合计详情判断
			this.judge_total = this.infoform.area_partner == 1;
		},
		//购买跳转
		gobuy(){
			this.$router.push({path:"/Ecological/index"})
		},
		//我的套餐列表
		async onLoadMypackagelist() {
			if(!this.finished){
				let {status,data} = await Ecological.mypackagelist({page:this.page.current_page});
				if (status) {
					this.tableData.push(...data.data.data);
					if(data.data.data.length == 0){
						this.finished = true;
					}
					this.page.current_page += 1
				}
			} 
			// 加载状态结束
			this.loading = false;
		},
		//提现记录列表
		async onLoadPackagerecord() {
			if(!this.finished){
				let {status,data} = await Ecological.packagerecord({page:this.page.current_page});
				if (status) {
					this.PackagerecordData.push(...data.data.data);
					if(data.data.data.length == 0){
						this.finished = true;
					}
					this.page.current_page += 1
				}
			} 
			// 加载状态结束
			this.loading = false;
		},
		//生态明细列表
		async onLoadMyzhiyaData() {
			if(!this.finished){
				let {status,data} = await Ecological.usablecoins();
				if (status) {
					this.MyzhiyaData.push(...data.data);
					this.finished = true;
					// this.MyzhiyaData.push(...data.data);
					// if(data.data.length == 0){
					// 	this.finished = true;
					// }
					// this.page.current_page += 1
				}
			} 
			// 加载状态结束
			this.loading = false;
		},
		tabchange(name){
			this.tableData = []
			this.PackagerecordData = []
			this.MyzhiyaData = []
			this.page.current_page = 1
			this.finished = false
			this.loading = true
			switch(name){
				case "0":
				this.onLoadMypackagelist()
				break
				case "1":
				this.onLoadPackagerecord()
				break
				case "2":
				this.onLoadMyzhiyaData()
				break
			}
		},
		//跳详情分红
		goParam(item){
			this.$router.push({path:'details?meal_id='+item.id,params:{id:item.id}})
		},
		//跳我的生态详情
		goParamMy(item){
			this.$router.push({path:'detail?id='+item.id,params:{id:item.id}})
		},
		//跳提现
		onClickMultiple() {
			this.$router.push({path:"/Ecological/withdrawal"})
		},
		goPledged() {
			this.$router.push({path:"/Ecological/pledgedwithdrawal"})
		},
		onClickWarnDialog() {
			this.warnDialog = true;
		},
		ReleaseWarnDialog() {
			this.releaseWarnDialog = true;
		},
		//跳转生态合计
		gototal() {
			this.$router.push({path:"/Ecological/total"})
		},
		//开始结束时间切割
		dateFmt(date) {
			if (date == null || date == undefined){
				return '';
			}
			return date.slice(0,11);
		},
		
		//特殊管理状态
		async manage(checked) {
			let ty = checked ? "on":"off";
			let { status, data } = await Ecological.manage({ty:ty});
			if (status) {
				if(data.code != 200) {
					this.$message.error('请求失败')
				}
			} 
		},
	},
}
</script>

<style lang="less">
	.package {
		padding: 0vw 5vw 20vw 5vw;
		background-color: #F8F8FB;
		.van-dialog {
			.warn_text {
				margin: 0 5vw;
				.text {
					font-size: 3.5vw;
					padding-top: 1.5vw;
				}
			}
		}
		.package_detail {
			background: url(../../assets/img/package_background.png) no-repeat center center;
			background-size: 315px 147px;
			padding: 23vw 0;
			position: relative;
			.package_box {
				display: flex;
				align-items: center;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				width: 80vw;
				.item {
					width: 35vw;
					text-align: center;
					.number {
						color: #ffffff;
						font-weight: bold;
						font-size: 4vw;
						padding-top: 2vw;
					}
					.text {
						color: #ffffff;
						font-size: 3vw;
					}
				}
				.item:last-child {
					border-right:0;
				}
				.item-total {
					position: relative;
					.van-button--small {
						position: absolute;
						left: 50%;
						transform: translateX(-50%);
						bottom: -10vw;
						border-radius: 2vw;
					}
				}
			}
		}
		.filecoin {
			margin:2vw;
			.text {
				vertical-align: middle;
				color: #999999!important;
				font-size: 4vw;
				margin-right: 2vw;
			}
			img {
				vertical-align: middle;
			}
		}
		.cash_number {
			font-size: 8vw;
			font-weight: bold;
			color: #124DA5;
			margin: 2vw 2vw;
		}
		.cash_text {
			font-size: 4vw;
			color: #124DA5;
			margin: 2vw 2vw;
		}
		.van-tab {
			justify-content: center;
			-webkit-justify-content: center;
		}
		.van-tabs__line {
			background-color: #124DA5;
			 
		}
		.van-tabs__wrap {
		    margin-bottom: 2vw;
		}
		.title {
			font-size: 3.5vw;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-weight: bold;
			padding-top: 5vw;
			.special {
				display: flex;
				justify-content: space-between;
				align-items: center;
				.van-cell {
					background-color: #F8F8FB;
				}
				.text {
					width: 22vw;
				}
				.van-switch-cell {
					width: 16vw;
					padding-left: 0;
					padding-right: 0;
				}
			}
		}
		.dec {
			font-size: 6vw;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-weight: bold;
			.zhiya_btn .van-button {
				border-radius: 2vw;
				padding: 1vw 8vw;
			}
		}
		.package_bottom {
			display: flex;
			margin: 5vw 2vw;
			.item {
				width: 49vw;
				text-align: center;
				border-right: 0.2vw dashed #999999;
				.number {
					color: #333333;
					font-weight: bold;
					font-size: 4vw;
				}
				.text {
					color: #999999;
					font-size: 3vw;
					padding-top: 1vw;
				}
			}
			.item:last-child {
				border-right:0;
			}
			.item-total {
				position: relative;
				.van-button--small {
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					bottom: -10vw;
					border-radius: 2vw;
				}
			}
		}
		.package_head {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.box {
				.num {
					font-size: 8vw;
					color: #124DA5;
					font-weight: bold;
				}
				.text {
					font-size: 4vw;
					color: #124DA5;
				}
			}
			.btn {
				.van-button {
					width: 25vw;
					border-radius: 2vw;
				}
			}
		}
		.form_list {
			.package_total {
				border-radius: 4vw;
				padding: 4vw;
				margin-top: 4vw;
				font-size: 3.5vw;
				background-color: #ffffff;
				display: flex;
				align-items: center;
				justify-content: space-between;
				div .van-button {
					border-radius: 2vw;
					padding: 1vw 3.5vw;
				}
			}
			border-top: 0.2vw solid #eeeeee;
			margin: 5vw 1vw;
		}
		.packageBtn {
			display: flex;
			
			.btn_operation {
				padding: 0vw 2vw;
				.van-button {
					width: 49%;
					border-radius: 3vw;
					margin-top: 0;
				}
				.van-button:first-child {
					margin-right: 2%;
				}
			}
			.btn_withdrawal {
				padding: 0vw 2vw;
				width: 40%;
				.van-button {
					width: 100%;
					border-radius: 3vw;
					margin-top: 0;
				}
			}
		}
		
		.package-list {
			.item {
				padding: 5vw 3vw;
				background-color: #FFFFFF;
				border-radius: 3vw;
				margin-bottom: 2vw;
				.top {
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 4vw;
					color: #333333;
					font-weight: bold;
					.package_title {
						span {
							vertical-align: middle;
						}
						.van-icon {
							color: #999999;
							vertical-align: middle;
						}
					}
					
					.status {
						margin: 0;
					}
				}
				.middle {
					font-size: 4vw;
					color: #333333;
					font-weight: bold;
				}
				.bottom {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-top: 2.5vw;
		
					.date {
						color: #999999;
						font-size: 4vw;
					}
		
					.release {
						color: #124DA5;
						font-size: 4vw;
					}
				}
			}
		}
		.van-tabs__nav {
			background-color: #F8F8FB;
		}
	}
</style>
