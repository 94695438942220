require('./plugins/axios')
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import message from "@/util/message";
import '@/plugins/axios'
import  {Dialog}  from 'vant';
import VueClipboard from 'vue-clipboard2'

Vue.prototype.$message = message

Vue.use(Vant);
Vue.use(Dialog);
Vue.use(VueClipboard)
Vue.config.productionTip = false
//title
Vue.directive('title', {
　　inserted: function (el, binding) {
　　　　document.title = el.dataset.title
　　}
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
