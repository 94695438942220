<template>
	<div class="operat">
		<div class="operatig">
			
		</div>
		<div class="cash_number">
			{{form.area_partner_fil}}
		</div>
		<div class="bottom_text">
			运营奖励总量
		</div>
		<div class="commission_list">
			<van-tabs v-model="active"  @change="tabchange">
				<van-tab title="提成记录" style="margin-bottom: 15vw;" name="0">
					<div class="item_list">
						<van-list v-model="loading"  :finished="finished"  finished-text="没有更多了"  @load="onLoadCommission()">
							<div class="item" v-for="(item,index) in tableData" @click="goRecord(item)" >
								<div class="top">
									<div>服务器编号:{{item.order_id}}</div>
									<van-icon name="arrow" />
								</div>
								<div class="bottom">
									<div class="num">奖励:{{item.balance}}</div>
									<div class="date">
										<countdown style="color: #333333;" :end-time="item.end_date" :release-time="item.release_time" :release-type="item.ty"></countdown>
									</div>
								</div>
							</div>
						</van-list>
					</div>
				</van-tab>
				<van-tab title="转出记录" style="margin-bottom: 15vw;" name="1">
					<div class="item_list">
						<van-list v-model="loading"  :finished="finished"  finished-text="没有更多了"  @load="onLoadTransferlist()">
							<div class="item" v-for="(item,index) in transferData" @click="goDetail(item)">
								<div class="top">
									<div>所属服务器:{{item.userkuang}}</div>
									<van-icon name="arrow" />
								</div>
								<div class="bottom">
									<div class="num">金额:{{item.money}}</div>
									<div class="date">
										{{item.thetime}}
									</div>
								</div>
							</div>
						</van-list>
					</div>
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
import { Home } from "@/api/index";
import countdown from "@/components/countdown";
export default {
    data() {
      return {
		active: 0,
        input: '',
        tableData: [],
		transferData:[],
		form:{},
        transferform: {
			id:'',
			balance: '',
			in_user: '',
        },
		//刷新
		 loading: false,
		 finished: false,
		 data: [], 
		 page: {
			current_page: 1,
		   pagesize: 10
		},
      };
    },
	components:{
		countdown
	},
created() {
		this.Total()
	},
methods:{
	//提成列表
	async onLoadCommission() {
		if(!this.finished){
			let { status, data } = await Home.operating({page:this.page.current_page}); 
			if (status) {
				this.tableData.push(...data.data.data);
				if(data.data.data.length == 0){
					this.finished = true;
				}
				this.page.current_page += 1
			}
		} 
		// 加载状态结束
		this.loading = false;
	},
	//转出列表
	async onLoadTransferlist() {
		if(!this.finished){
			let { status, data } = await Home.transferlist({page:this.page.current_page}); 
			if (status) {
				this.transferData.push(...data.data.data);
				if(data.data.data.length == 0){
					this.finished = true;
				}
				this.page.current_page += 1
			}
		} 
		// 加载状态结束
		this.loading = false;
	},
	tabchange(name){
		this.tableData = []
		this.transferData = []
		this.page.current_page = 1
		this.finished = false
		this.loading = true
		switch(name){
			case "0":
			this.onLoadCommission()
			break
			case "1":
			this.onLoadTransferlist()
			break
		}
	},
	async Total() {
			let { status, data } = await Home.total(); 
			if (status) {
				this.form = data.data;
			}
		},
	goRecord(item){
		this.$router.push({path:'record?id='+item.id,params:{id:item.id}})
	},
	goDetail(item){
		this.$router.push({path:'details?id='+item.id,params:{id:item.id}})
	},
}
  };	
</script>

<style lang="less">
	.operat {
		.van-tabs__line {
		    background-color: #124DA5!important;
		}
		.operatig {
			margin: 10vw 5vw 2vw;
			.text {
				vertical-align: middle;
				color: #999999;
				font-size: 4vw;
				margin-right: 2vw;
			}
			img {
				vertical-align: middle;
			}
		}
		.cash_number {
			font-size: 8vw;
			font-weight: bold;
			color: #124DA5;
			margin: 3vw 5vw 0vw;
		}
		.bottom_text {
			padding: 2vw 5vw 4vw;
			font-size: 3vw;
			color: #124DA5;
			border-bottom: 0.2vw solid #eee;
		}
		.commission_list{
			margin: 3vw 5vw;
			.title {
				font-size: 4vw;
				color: #124DA5;
				font-weight: bold;
				padding: 1vw 0 4vw;
				border-bottom: 0.2vw solid #eee;
			}
			.item_list {
				margin-bottom: 15vw;
				.item {
					padding: 5vw 0;
					border-bottom: 0.2vw solid #eee;
					.top {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding-bottom: 1.5vw;
						font-size: 4vw;
						color: #333333;
						font-weight: bold;
						.van-icon {
							color: #999999;
						}
					}
					.bottom {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding-top: 1.5vw;
						.date {
							color: #999999;
						}
						.num {
							color: #124DA5;
						}
					}
				}
			}
		}
	}
</style>
