<template>
	<div class="pledged_box">
		<div class="application">
			<div class="text">可提现</div>
			<div class="price">{{tableForm.pledged}}</div>
			<van-cell-group>
			  <van-field v-model="nums"   placeholder="请输入提现金额" />
			</van-cell-group>
			<div class="go_all" @click="handleAll(nums)">全部提现</div>
			<div>
				<van-button color="#124DA5" @click="LedgedWithdrawal(nums)" v-if="isPledged">提现</van-button>
			</div>
		</div>
		
		<div class="application_bottom"></div>
	</div>
</template>

<script>
	import { Ecological } from "@/api/index";
export default {
  data() {
    return {
      nums: '',
	  tableForm:[],
	  BtnText:'分币中，今日12点开启',
	  isPledged:true,
    };
  },
  created() {
  	this.Coins()
	
  },
  methods: {
	async Coins() {
	  	let { status, data } = await Ecological.coins();
	  	if (status) {
			if(data.code == 200){
				this.tableForm = data.data;
				if(this.tableForm.pledged > 0) {
					this.isPledged = true;
				}else {
					this.isPledged = false;
				}
			}else{
				this.$message.error(data.message);	
			}
		}
	},
	handleAll (nums) {
		this.nums = this.tableForm.pledged;
	},
	async LedgedWithdrawal (nums) {
		let { status, data} = await Ecological.releasewithdrawal({nums:nums});
		if(status) {
			if(data.code == 200){
				this.$message.success(data.data);
				this.$router.replace('/Ecological/package')
			}else{
				this.$message.error(data.message);	
			}
		}
	},
  }
};
</script>

<style lang="less">
	.pledged_box {
		.application {
			margin-top: 10vw;
			padding-bottom: 6vw;
			border-bottom: 0.2vw solid #eee;
			.van-cell {
				border: 0.2vw solid #124DA5;
				width: 50%;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				border-radius: 4vw;
			}
			.text {
				text-align: center;
				font-size: 3vw;
				color: #333;
				font-weight: bold;
			}
			.price {
				text-align: center;
				font-size: 3vw;
				color: #333;
				padding-top: 3vw;
				padding-bottom: 5vw;
			}
			.go_all {
				text-align: center;
				margin-top: 16vw;
				font-size: 3.5vw;
				color: #124DA5;
			}
		}
		[class*=van-hairline]::after {
			width: 0;
		}
		.application_bottom {
			padding: 5vw;
			.line {
				padding-bottom: 4vw;
				display: flex;
				height: 5vw;
				line-height: 5vw;
				font-size: 4vw;
				color: #333333;
				.name {
					width: 25vw;
				}
				.content_spacial {
					height: 30vw;
					width: 65vw;
				}
			}
		}
		.van-button {
			position: absolute;
			margin: 8vw auto;
			left: 0;
			right: 0;
			border-radius: 1vw;
			width: 25vw;
			height: 11vw;
		}
	}
</style>
