<template>
	<div class="contribution">
		<div class="contribution_top">
			<div class="number">{{tableForm.score}}</div>
			<div class="bottom">
				<div class="text">贡献值</div>
				<div class="go_exchange">
					<van-button color="#3988FF" @click="goCon">去兑换</van-button>
				</div>
			</div>
		</div>
		<div class="contribution_bottom">
			<van-tabs v-model="active"  @change="tabchange">
				<van-tab title="贡献值列表" style="margin-bottom: 15vw;" name="0">
					<van-list v-model="loading"  :finished="finished"  finished-text="没有更多了"  @load="onLoadG()">
						<div class="contribution_list" v-for="(item,index) in contributionData">
							<div class="item" @click="goCond(item)">
								<div class="top">
									<div class="name">
										<span v-if="item.aboutuser!==null">{{item.aboutuser.username}}</span>
									</div>
									<div class="number">{{item.money}}</div>
								</div>
								<div class="bottom">
									<div class="note">{{item.content}}</div>
									<van-icon name="arrow" />
								</div>
							</div>
						</div>
					</van-list>
				</van-tab>
				<van-tab title="兑换记录" style="margin-bottom: 15vw;" name="1">
					<van-list v-model="loading"  :finished="finished"  finished-text="没有更多了"  @load="onLoadD()">
						<div class="contribution_list" v-for="(item,index) in exchangeData">
							<div class="item" @click="goExc(item)">
								<div class="top">
									<div class="name">{{item.content}}</div>
									<div class="number">{{item.money}}</div>
								</div>
								<div class="bottom">
									<div class="note">{{item.applytime}}</div>
									<van-icon name="arrow" />
								</div>
							</div>
						</div>
					</van-list>
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
import { Distribution } from "@/api/index";
export default {
    data() {
      return {
		active:'',
		centerDialogVisible: false,
		form:{
			nums:'',
		},
		tableForm:[],
		contributionData:[],
		exchangeData:[],
		//刷新
		loading: false,
		finished: false,
		page: {
			current_page: 1,
			pagesize: 10
		},
      };
    },
	created() {
		this.Details()
	},
	methods:{
		//进入提贡献页面
		goCon() {
			this.$router.push('/Distribution/exchange')
		},
		//页面详情
		async Details() {
				let { status, data } = await Distribution.details({ty:this.ty}); 
				if (status) {
					this.tableForm = data.data;
				}
			},
		//贡献值列表
		async onLoadG() {
			if(!this.finished){
				let { status, data } = await Distribution.contributionlist({page:this.page.current_page}); 
				if (status) {
					this.contributionData.push(...data.data.data);
					if(data.data.data.length == 0){
						this.finished = true;
					}
					this.page.current_page += 1
				}
			} 
			// 加载状态结束
			this.loading = false;
		},
		//兑换记录
		async onLoadD() {
			if(!this.finished){
				let { status, data } = await Distribution.exchange({page:this.page.current_page}); 
				if (status) {
					this.exchangeData.push(...data.data.data);
					if(data.data.data.length == 0){
						this.finished = true;
					}
					this.page.current_page += 1
				}
			} 
			// 加载状态结束
			this.loading = false;
		},
		tabchange(name){
			this.contributionData = []
			this.exchangeData = []
			this.page.current_page = 1
			this.finished = false
			this.loading = true
			switch(name){
				case "0":
				this.onLoadG()
				break
				case "1":
				this.onLoadD()
				break
			}
		},
		goCond(item){
			this.$router.push({path:'contributiondetail?id='+item.id,params:{id:item.id}})
		},
		goExc(item){
			this.$router.push({path:'exchangedetail?id='+item.id,params:{id:item.id}})
		},
	},
  };
</script>

<style lang="less">
	.contribution {
		.contribution_top {
			padding: 10vw 5vw 7vw;
			border-bottom: 0.2vw solid #eee;
			.number {
				font-size: 8vw;
				color: #124DA5;
				font-weight: bold;
			}
			.bottom {
				display: flex;
				align-items: center;
				justify-content: space-between;
				.text {
					font-size: 3.5vw;
					color: #124DA5;
				}
				.go_exchange {
					.van-button {
						border-radius: 2vw;
						width: 25vw;
						margin-top: 0;
					}
				}
			}
		}
		.contribution_bottom {
			margin: 5vw 5vw;
		}
		.contribution_list {
			.item {
				padding: 5vw 0;
				border-bottom: 0.2vw solid #eee;
				.top {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-bottom: 1vw;
					.name {
						font-size: 3.5vw;
						color: #333333;
					}
					.number {
						font-size: 3.5vw;
						float: right;
					}
				}
				.bottom {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-top: 1vw;
					.note {
						font-size: 3.5vw;
						color: #999999;
					}
					.van-icon {
						font-size: 3.5vw;
						color: #999999;
					}
				}
			}
		}
	}
</style>
