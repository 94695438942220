<template>
	<div class="total">
		<p class="title">生态合计</p>
		<p class="tit">信息</p>
		<div class="info_box" v-for="item in tableData">
			<div class="line">
				<div class="name">套餐</div>
				<div class="text">{{item.title}}</div>
			</div>
			<div class="line">
				<div class="name">基数</div>
				<div class="text">{{item.base_number}}</div>
			</div>
			<div class="line">
				<div class="name">质押</div>
				<div class="text">{{item.base_number * item.nums_count}}</div>
			</div>
			<!-- <div class="line">
				<div class="name">Gas</div>
				<div class="text">{{item.gas_number}}</div>
			</div> -->
			<div class="line">
				<div class="name">释放天数</div>
				<div class="text">
					<span>{{item.release_days + item.waiting_days}}</span>
				</div>
			</div>
			<div class="line">
				<div class="name">开始时间</div>
				<div class="text">{{dateFmt(item.start_time)}}</div>
			</div>
			<div class="line">
				<div class="name">到期时间</div>
				<div class="text">{{dateFmt(item.end_time)}}</div>
			</div>
			<div class="line">
				<div class="name">生效天数</div>
				<div class="text">
					<span>{{item.waiting_days}}</span>
				</div>
			</div>
			<div class="line">
				<div class="name">生态合计</div>
				<div class="text">
					<span>{{item.nums_count}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {Ecological} from "@/api/index";
export default {
	data() {
		return {
			tableData:[],
		};
	},
	created() {
		this.Packagetotal()
	},
	methods:{
		//生态合计列表
		async Packagetotal() {
			let {status,data} = await Ecological.packagetotal();
			if (status) {
				this.tableData = data.data;
			}
		},
		//时间方法
		dateFmt(date) {
			if (date == null || date == undefined){
				return '';
			}
			return date.slice(0,11);
		},
	}
	
}
</script>

<style lang="less">
	.total {
		padding: 5vw 5vw 20vw 5vw;
		.title {
			text-align: center;
			font-weight: bold;
		}
		.tit {
			font-size: 4.5vw;
			font-weight: bold;
			color: #124DA5;
		}
		.info_box {
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
			border-radius: 4vw;
			padding: 5vw 5vw 2vw 5vw;
			background-color: #F1F5FA;
			margin-top: 5vw;
			.line {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-bottom: 4vw;
				.name {
					font-size: 4vw;
					color: #999999;
				}
				.text {
					font-size: 4vw;
					color: #333333;
				}
			}
		}
	}
</style>
