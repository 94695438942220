<template>
	<div class="login-bg">
		<p class="CN"></p>
		<p class="EN"></p>
		<div class="login-form">
			<div class="head-title">
				<div class="left">
					<p class="left-CN">欢迎登陆</p>
					<p class="left-EN">Welcome to login</p>
				</div>
	
			</div>
			<van-form @submit="onSubmit">
			  <van-field
				left-icon="user-o"
			    v-model="username"
			    name="name"
			    placeholder="请输入账号/手机号"
			  />
			  <van-field
			    v-model="password"
			    type="password"
			    name="password"
			    left-icon="edit"
			    placeholder="请输入密码"
			  />
			  <div class="forget_text"><a @click="forget()">忘记密码?</a></div>
			  <div style="margin: 16px;">
			    <van-button round block color="#124DA5" native-type="submit">登录</van-button>
			  </div>
			</van-form>
			<div class="bottom">没有账号?请点击<a @click="register()" class="goregister">注册账号</a></div>
		</div>
		
	</div>
</template>

<script>
import {Admin} from "@/api/index";
export default {
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
	  forget() {
		  this.$router.replace('/forget')
	  },
	  register() {
	  	this.$router.replace('/register')
	  },
	  // onSubmit() {
		 //   this.$message.error("后台正在维护，开放时间另行通知。");
	  // }
	  async onSubmit(values) {
		let { status, data } = await Admin.login(values);
		if (status) {
			if(data.code != 200){
				this.$message.error(data.message);
				return
			}
			this.$message.success("登陆成功");
			this.$store.commit('setToken', data.data.token);
			this.$router.replace('/home/index')
		} else {
			this.$message.error("data.message")
		}
    },
  },
};
</script>

<style lang="less">
	.van-button {
		margin-top: 14vw;
		border-radius: 3vw;
	}
	.van-cell {
		padding: 17px 16px;
	}
	.login-bg {
		background: url(../../assets/img/ic_beijing.png) no-repeat center top;
		background-size: contain;
		.CN {
			color: #ffffff;
			font-size: 8.53vw;
			text-align: center;
			padding-top: 14.13vw;
			margin-block-start: 0em;
			margin-block-end: 0em;
		}
		.EN {
			color: #ffffff;
			font-size: 1.73vw;
			text-align: center;
			padding-top: 0.93vw;
		}
		.login-form {
			border-radius: 10vw;
			background-color: #fff;
			border-radius: 4vw;
			border: 1px solid #ccc;
			height: 120vw;
			width: 85vw;
			margin: 5.51vw auto 0;
			.head-title {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin: 10vw 5vw;
				.left {
					.left-CN {
						color: #333333;
						font-size: 6vw;
						margin-block-start: 0em;
						margin-block-end: 0em;
					}
					.left-EN {
						color: #333333;
						font-size: 3vw;
						padding-top: 2.93vw;
						margin-block-start: 0em;
						margin-block-end: 0em;
					}
				}
			}
			.bottom {
				text-align: center;
				font-size: 3.5vw;
				color: #999999;
				.goregister {
					color: #016FE1;
				}
			}
		}
		.forget_text {
			text-align: right;
			padding-top: 4vw;
			padding-right: 4vw;
			a {
				font-size: 3.5vw;
				color: #999999;
			}
		}
	}
</style>
