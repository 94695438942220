<template>
	<div class="mobile">
		<div class="index-bg">
			<div class="top">
				<div class="left">
					<p class="price"><span style="font-size: 4vw;">￥</span>{{form.money}}</p>
					<p class="text">可提余额(元)</p>
				</div>
				<div class="right">
					<div class="message" @click="goAnnouncement"><img width="36px" src="../../assets/img/ic_youxiaoxi.png" alt="智岚博丰"></div>
					<div class="avatar"><img width="44px" src="../../assets/img/ic_logo.png" alt="智岚博丰"></div>
				</div>
			</div>
			<div class="terms">
				<router-link to="/Assets/assets">
					<div class="item item1">
						<p class="name">通证数量<van-icon name="arrow" /></p>
						
						<p class="number">{{form.coins.total}}</p>
					</div>
				</router-link>
				<router-link to="/Assets/assets">
					<div class="item item2">
						<p class="name">实际可提现数</p>
						
						<p class="number" style="padding-top: 1vw;">{{form.coins.cash_int}}</p>
						<p class="name" style="padding-top: 2vw;font-size: 3vw;" >(总可提现数)</p>
						<p class="number" style="padding-top: 1vw;">{{form.coins.cash}}</p>
					</div>
				</router-link>
				<router-link to="/Assets/assets">
					<div class="item item3">
						<p class="name">锁仓数</p>
						<p class="number">{{form.coins.locked}}</p>
					</div>
				</router-link>
			</div>
		</div>
		<div class="list">
			<p class="title">我的数字通证</p>
			<div class="operating-box" v-if="judge" @click="goOperating()">
				<div class="asset-item">
					<div class="left">
						<div class="my-icon">
							<img width="44px" src="../../assets/img/ic_shishijiage .png" alt="智岚博丰">
						</div>
						<div class="text">
							<p class="text1">运营奖励</p>
							
						</div>
					</div>
					<div class="price right">
						<span>{{form.area_partner_fil}}</span>
						<van-icon name="arrow" />
					</div>
				</div>
			</div>
			<div class="asset-box">
				<div class="asset-item">
					<div class="left">
						<div class="my-icon">
							<img width="44px" src="../../assets/img/ic_shishijiage.png" alt="智岚博丰">
						</div>
						<div class="text">
							<p class="text1">实时价格</p>
						</div>
					</div>
					<div class="price right">
						{{form.realtime_price}}
					</div>
				</div>
			</div>
			<div class="operating-box" @click="goEcological()" v-if="judgemarket">
				<div class="asset-item">
					<div class="left">
						<div class="my-icon">
							<img width="44px" src="../../assets/img/ic_shengtaibankuai.png" alt="智岚博丰">
						</div>
						<div class="text">
							<p class="text1">生态板块</p>
							
						</div>
					</div>
					<div class="price right">
						<span>{{Formdata.total}}</span>
						<van-icon name="arrow" />
					</div>
				</div>
			</div>
			<p class="title title_special">账户概况</p>
			<div class="account-box">
				<div class="account-item" v-if="judgemarket">
					<div class="left">
						<div class="my-icon">
							<img width="44px" src="../../assets/img/ic_gongxianzhi.png" alt="智岚博丰">
						</div>
						<div class="text">
							贡献值
						</div>
					</div>
					<div class="right">
						{{form.score}}
					</div>
				</div>
				<div class="account-item">
					<div class="left">
						<div class="my-icon">
							<img width="44px" src="../../assets/img/ic_suanli.png" alt="智岚博丰">
						</div>
						<div class="text">
							存储空间(T)
						</div>
					</div>
					<div class="right">
						{{form.myti}}
					</div>
				</div>
				<!-- <div class="account-item" v-if="judgeagent">
					<div class="left">
						<div class="my-icon">
							<img width="44px" src="../../assets/img/ic_hehuoren.png" alt="智岚博丰">
						</div>
						<div class="text">
							合伙人
						</div>
					</div>
					<div class="right">
						{{form.sons}}
					</div>
				</div> -->
			</div>
		</div>
		<van-dialog v-model="noticeShow" title="关于收取第三周期托管费的通知"  confirmButtonText = "确定" confirmButtonColor = "#ffffff" allowHtml="true">
			<div style="font-size:3vw;color:#333333;line-height:6vw;padding: 3vw;text-align: justify;height:60vh; overflow:auto;">
				<p style="font-size:3vw;">
各位合作伙伴，<br />
自从 2020 年 10 月 15 日 FIL 主网正式上线至今，已经进入了第三个周期（540
天/周期），过去的第二个周期经历了市场调整的大周期，无论是公司还是合作伙
伴异常艰难，因为产出不足以缴纳托管费用，超过 95%的伙伴选择不再缴纳托管
费，因此公司除了将产出变现以外还举债来保障节点的正常运行，以免造成无可
挽回的损失。节点的质押和产出是公司和伙伴的所有希望寄托，但是公司也到了
无法独立支撑的境地，再加机房托管费用上涨因素，因此经过公司研究决定，对
本周期托管费缴纳政策变更如下：<br />
<strong>1、设备托管费缴纳为一次性缴清，即 6 个季度 9000 元/台（96T）一次性缴纳；</strong><br />
<strong>2、生态板块按照每季度 30 元/T，同样一次性缴纳 180 元/T；</strong><br />
<strong>3、没升级集群按照 6T/台，一次性缴纳 1080 元；</strong><br />
<strong>4、参考数据：按照效率 0.0045FIL/T/天，币价在 110 元达到盈亏平衡点；</strong><br />
<strong>5、截止到 2024 年 5 月 17 日，合作伙伴选择缴纳托管费本周期正常分配收益，盈亏自负；</strong><br />
<strong>6、截止到 2024 年 5 月 17 日，合作伙伴选择不缴纳托管费到本周期结束不再分配收益，设备和生态质押不得撤出，直到本周期结束，节点解压方可提走；</strong><br />
<strong>7、倘若多数伙伴放弃缴纳托管费，公司也会继续举债维持节点正常运行一个周期；</strong><br />
<strong>8、本周期为最后一个服务周期，到期公司将不再提供服务，设备自行取回，生态质押提现。</strong><br />
<div style="text-align:right;">
智岚博丰科技有限公司<br />
2024 年 5 月 10 
</div>
				</p>
			</div>
		</van-dialog>
		<van-dialog v-model="show" title="重要通知"  confirmButtonText = "知道了" confirmButtonColor = "#ffffff">
			<div style="font-size:3vw;color:#333333;line-height:6vw;padding: 3vw;text-align: justify;">
				  <p style="text-indent:32px;font-size:3vw;">
					尊敬的客户您好，
					您的服务器运行已经满一个周期（540天），请联系公司处理。</p>
			</div>
		</van-dialog>
		<!-- <van-dialog v-model="showPassed" title="停服通知"  confirmButtonText = "知道了" confirmButtonColor = "#ffffff">
			<div style="font-size:3vw;color:#333333;line-height:6vw;padding: 3vw;text-align: justify;">
				  <p style="text-indent:32px;font-size:3vw;">
					尊敬的客户，
					您的服务器已经运营满一个周期，请尽快与公司取得联系！
					</p>
			</div>
		</van-dialog> -->
	</div>
</template>

<script>
	import { Home,Ecological,Personal } from "@/api/index";
	import countdown from "@/components/countdown";
	export default {
	  data() {
	    return {
		  show: false,
		  noticeShow:true,
		  // showPassed:false,
	      value: 5,
		  form: {coins:{}},
		 //刷新
		  loading: false,
		  finished: false,
		  data: [], 
		  page: {
		 	current_page: 1,
		    pagesize: 10
		 },
		 Formdata:{},
		 judge:false,
		 judgemarket:false,
		judgeagent:false,
	    };
	  },
	  components:{
	      	countdown,
	  	},
	  created() {
		this.Total();
		this.Coins();
		this.Agent()
	  },
	  methods: {
		  async Agent() {
		  	let { status, data } = await Personal.info();
		  	if (status) {
		  		this.$store.commit('setBase',{
		  			agent:data.data.agent,
		  			agent_name:data.data.agent_name,
		  			agent_partner:data.data.agent_partner,
		  			market:data.data.market,
		  			next_agent:data.data.next_agent,
		  			status:data.data.status,
		  			username:data.data.username,
		  			grade:data.data.grade,
					id:data.data.id
		  		})
		  	}
		  },
		  goOperating() {
			this.$router.push('/Operating/list')
		  },
		  goEcological() {
			  this.$router.push('/Ecological/package')
		  },
		  goAnnouncement() {
		  	this.$router.push('/Announcement/list')
		  },
		  async Coins() {
		  	let { status, data } = await Ecological.coins();
		  	if (status) {
		  		this.Formdata = data.data;
		  	}
		  },
		async Total() {
			let { status, data } = await Home.total();
			if (status) {
				this.form = data.data;
				if(this.form.passed > 0) {
					this.show = true;
				}else {
					this.show = false;
				}
				// if(this.form.passed > 0) {
				// 	this.showPassed = true;
				// }else {
				// 	this.showPassed = false;
				// }
				
				this.judge = this.form.area_partner_fil > 0;
				this.judgemarket = this.form.market != 2;
				this.judgeagent = this.form.agent > 1;
			}
		},
		goPay(kuang_id){
			this.$router.push({path:"/Buy/second?kuang="+kuang_id,params:{kuang:kuang_id}})
		},
	  },
	};
</script>

<style lang="less">
.mobile {
	.van-dialog {
		.van-dialog__confirm {
			background-color: #409eff;
		}
	}
}
.index-bg {
	background: url(../../assets/img/ic _beijing2.png) no-repeat center center;
	background-size: cover;
	height: 100vw;
	width: 100vw;
	.top {
		display: flex;
		justify-content: space-between;
		margin: 0vw 5vw;
		padding-top: 15vw;
		.left {
			.price {
				color: #fff;
				font-size: 7vw;
			}
			.text {
				color: #fff;
				font-size: 4vw;
				padding-top: 3vw;
			}
		}
		.right {
			display: flex;
			.message {
				margin-right: 2vw;
			}
		}
	}
	.terms {
		display: flex;
		margin: 10vw 5vw 0;
		width: 90vw;
		height: 35vw;
		a {
			width: calc(40% - 4vw);
			height: 35vw;
			margin-right: 4vw;
			background-color: #fff;
			border-radius: 4vw;
			box-sizing: border-box;
		}
		a:last-child {
			margin-right: 0vw;
		}
		.item {
			width: 100%;
			height: 35vw;
			margin-right: 4vw;
			background-color: #fff;
			border-radius: 4vw;
			.name {
				padding-top: 6vw;
				display: flex;
				align-items: center;
				justify-content: space-around;
				color: #fff;
				font-size: 3.5vw;
			}
			.filecoin_en {
				padding-top: 3vw;
				padding-left: 2vw;
				color: #fff;
				font-size: 3vw;
			}
			.number {
				padding-top: 4vw;
				padding-left: 2vw;
				color: #fff;
				font-size: 3vw;
			}
		}
		.item1 {
			background-color: #fff;
			.name {
				color: #124DA5;
				font-size: 3.5vw;
				margin-left: 1vw;
			}
			.filecoin_en {
				color: #124DA5;
				font-size: 3vw;
				padding-left: 3vw;
			}
			.number {
				color: #124DA5;
				font-size: 3vw;
				padding-left: 3vw;
			}
		}
		.item2 {
			background-color: #26A6F4;
			.name {
				display: block;
				color: #fff;
				font-size: 3.5vw;
				margin-left: 3vw;
			}
			.filecoin_en {
				color: #fff;
				font-size: 3vw;
				padding-left: 3vw;
			}
			.number {
				color: #fff;
				font-size: 3vw;
				padding-left: 3vw;
			}
		}
		.item3 {
			background-color: #26A6F4;
			.name {
				display: block;
				color: #fff;
				font-size: 3.5vw;
				margin-left: 3vw;
			}
			.filecoin_en {
				color: #fff;
				font-size: 3vw;
				padding-left: 3vw;
			}
			.number {
				color: #fff;
				font-size: 3vw;
				padding-left: 3vw;
			}
		}
	}
	//特殊用户
	.terms_special {
		margin: 5vw;
		.top_special {
			display: flex;
			justify-content: space-between;
			a {
				width: 42.5vw;
				height: 20vw;
				margin-right: 5vw;
				background-color: #fff;
				border-radius: 4vw;
				box-sizing: border-box;
			}
			a:last-child {
				margin-right: 0vw;
			}
			.item {
				width: 100%;
				height: 20vw;
				margin-right: 4vw;
				background-color: #fff;
				border-radius: 4vw;
				color: #124DA5;
				.name {
					padding-top: 2vw;
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin: 0 5vw;
					font-size: 3vw;
				}
				.filecoin_en {
					padding-top: 2vw;
					margin-left: 5vw;
					font-size: 3vw;
				}
				.number {
					padding-top: 2vw;
					margin-left: 5vw;
					font-size: 3vw;
				}
			}
			.item1 {
				background-color: #fff;
				color: #124DA5;
			}
			.item2 {
				background-color: #26A6F4;
				color: #fff;
			}
		}
		.bottom_special {
			display: flex;
			justify-content: space-between;
			margin-top: 5vw;
			a {
				width: 42.5vw;
				height: 20vw;
				margin-right: 5vw;
				background-color: #fff;
				border-radius: 4vw;
				box-sizing: border-box;
			}
			a:last-child {
				margin-right: 0vw;
			}
			.item {
				width: 100%;
				height: 20vw;
				margin-right: 4vw;
				background-color: #fff;
				border-radius: 4vw;
				color: #124DA5;
				.name {
					padding-top: 2vw;
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin: 0 5vw;
					font-size: 3vw;
				}
				.filecoin_en {
					padding-top: 2vw;
					margin-left: 5vw;
					font-size: 3vw;
				}
				.number {
					padding-top: 2vw;
					margin-left: 5vw;
					font-size: 3vw;
				}
			}
			.item3 {
				background-color: #26A6F4;
				color: #fff;
			}
			.item4 {
				background-color: #26A6F4;
				color: #fff;
			}
		}
	}
}
.list {
	background-color: #fff;
	height: 100vh;
	width: 100vw;
	border-top-right-radius: 10vw;
	border-top-left-radius: 10vw;
	position: absolute;
	top: 85vw;
	left: 50%;
	transform: translateX(-50%);
	.title {
		font-size: 4vw;
		color: #124DA5;
		padding-top: 6vw;
		padding-left: 5vw;
	}
	.title_special {
		padding-top: 3vw;
	}
	.asset-box {
		margin: 4vw 5vw;
		padding: 6vw 4vw;
		border-radius: 5vw;
		box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
		.asset-item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 0.1vw solid #eee;
			.left {
				display: flex;
				align-items: center;
				.my-icon {
					margin-right: 2vw;
					img {
						display: block;
					}
				}
				.text {
					.text1 {
						font-size: 4vw;
						font-weight: bold;
						color: #333333;
						padding-bottom: 0.5vw;
					}
					.text2 {
						padding-top: 0.5vw;
						font-size: 3vw;
						color: #999999;
					}
				}
			}
			.price {
				font-size: 4vw;
				color: #124DA5;
			}
		}
		.asset-item:last-child {
			margin-bottom: 0;
			border-bottom: 0;
		}
	}
	.operating-box {
		height: 11vw;
		margin: 4vw 5vw;
		padding: 7vw 4vw;
		border-radius: 5vw;
		box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
		.asset-item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-bottom: 4vw;
			margin-bottom: 4vw;
			border-bottom: 0.1vw solid #eee;
			.left {
				display: flex;
				align-items: center;
				.my-icon {
					margin-right: 2vw;
					img {
						display: block;
					}
				}
				.text {
					.text1 {
						font-size: 4vw;
						font-weight: bold;
						color: #333333;
						padding-bottom: 0.5vw;
					}
					.text2 {
						padding-top: 0.5vw;
						font-size: 3vw;
						color: #999999;
					}
				}
			}
			.price {
				display: flex;
				align-items: center;
				font-size: 4vw;
				color: #124DA5;
				span {
					vertical-align: middle;
				}
				.van-icon {
					vertical-align: middle;
				}
			}
		}
		.asset-item:last-child {
			margin-bottom: 0;
			border-bottom: 0;
		}
	}
	.account-box {
		margin: 4vw 0vw;
		padding: 2vw 4vw 20vw 4vw;
		.account-item {
			box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
			border-radius: 3vw;
			height: 18vw;
			display: flex;
			align-items: center;
			padding: 0 5vw;
			margin-top: 2vw;
			justify-content: space-between;
			.left {
				display: flex;
				align-items: center;
				.my-icon {
					margin-right: 1vw;
					margin-top: 1vw;
				}
			}
			.right {
				font-weight: bold;
				color: #37D39B;
			}
		}
	}
	.server-box {
		margin: 4vw 4vw;
		padding: 2vw 4vw 15vw;
		.server-item {
			height: 30vw;
			display: flex;
			align-items: center;
			margin-top: 0vw;
			border-bottom: 0.2vw solid #eeeeee;
			.right {
				margin-left: 4vw;
				.product_name {
					font-size: 4vw;
					font-weight: bold;
					color: #333333;
					margin-bottom: 1.5vw;
				}
				.T_number {
					font-size: 3.5vw;
					color: #999999;
					margin-bottom: 1.5vw;
				}
				.score {
					margin-bottom: 1vw;
				}
				.operation {
					display: flex;
					width: 54vw;
					align-items: center;
					justify-content: space-between;
					font-size: 3.5vw;
					.tag {
						padding: 1vw;
						background-color: #FDEEE9;
						color: #E8541E;
					}
					.buy_product {
						color: #FF1900;
						span {
							vertical-align: middle;
						}
						.van-icon {
							vertical-align: middle;
						}
					}
				}
			}
		}
	}
}
</style>
