<template>
	<div class="forget">
		<div class="text">忘记密码</div>
		<van-form>
		  <van-field
		    v-model="submitForm.mobile"
		    type="text"
		    name="mobile"
		    label="手机号码"
		    placeholder="请输入手机号码"
		  />
		  <div class="warn"><van-icon name="info-o" /><div class="font">请输入绑定钱包时输入的手机号码</div></div>
		  <div class="message">
			  <van-field
			    v-model="submitForm.code"
			    type="text"
			    name="code"
			    label="验证码"
			    placeholder="请输入验证码"
			  />
			  <van-button v-if="showbtn"  @click="handleSend(submitForm.mobile)">发送验证码</van-button>
			  <van-button v-else :disabled="attcode">{{code_ts}}</van-button>
		  </div>
		  
		  <div style="margin: 16px;">
		    <van-button round block color="#124DA5" @click="handleSafe(submitForm)">验证</van-button>
		  </div>
		</van-form>
	</div>
</template>

<script>
import {
		Admin
	} from "@/api/index";
export default {
  data() {
    return {
		attcode: false, //点击获取验证码按钮判断
		showbtn: true, // 展示获取验证码或倒计时按钮判断
		code_ts: '获取验证码', //倒计时提示文字
		sec: 60, // 倒计时秒数
      submitForm: {
      	mobile: '',
      	code: '',
      },
    };
  },
  created() {
	
  },
  methods: {
	
	//发送短信
	async handleSend(mobile) {
		let {
			status,
			data
		} = await Admin.sendsms({
			mobile:mobile,
			scene:this.scene
		});
		if (status) {
			if (data.code == 200) {
				var timer = setInterval(() => {
					this.sec = this.sec - 1
					this.code_ts = this.sec + 'S后重试'
					this.showbtn = false
					this.attcode = true
					if (this.sec === 0) {
						clearInterval(timer)
						this.sec = 60
						this.code_ts = this.sec + 'S后重试'
						this.showbtn = true
						this.attcode = false
					}
				}, 1000)
				this.$message.success(data.data);
			} else {
				this.$message.error(data.message);
			}
		}
	},
	//确定
	async handleSafe(mobile, code) {
		let {
			status,
			data
		} = await Admin.check(this.submitForm);
		if (status) {
			if (data.code == 200) {
				this.$router.push({path:'modification',query:{token:data.data}});
			} else {
				this.$message.error(data.message);
			}
		}
	},
  },
};
</script>

<style lang="less">
	.forget {
		.text {
			font-size: 5vw;
			color: #333333;
			text-align: center;
			font-weight: bold;
			margin-top: 3vw;
		}
		.van-button {
			margin-top: 14vw;
			border-radius: 3vw;
		}
		.van-field__label {
			margin-bottom: 1vw;
		}
		.van-cell {
			display: block;
		}
		.van-field__control {
			border: 1px solid #E5E5E5;
			border-radius: 2vw;
			padding: 2vw;
		}
		.van-cell::after {
			display: none;
		}
		.warn {
			display: flex;
			align-items: center;
			font-size: 3vw;
			color: #999999;
			padding: 0 16px;
			.font {
				margin-left: 2vw;
			}
		}
		.van-form {
			margin: 10vw 5vw;
			.van-radio {
				overflow: visible;
			}
		}
		.van-radio {
			font-size: 3.5vw;
			margin-left: 3.5vw;
			margin-top: 5vw;
			color: #999999;
			.aggrement {
				color: #016FE1;
			}
		}
		.van-field__label {
			width: 7.2em;
		}
		.message {
			position: relative;
			.van-field__control {
				border: 1px solid #E5E5E5;
				border-radius: 2vw;
				padding: 2vw;
				width: 65%;
			}
			.van-button {
				margin: 0;
				position: absolute;
				right: 0;
				bottom: 10px;
				width: 35%;
			}
		}
	}
	
</style>
