<template>
	<div class="details">
		<p class="title">生态</p>
		<p class="tec_tit">生态{{meal_id}}期详情</p>
		<div class="form_list">
			<van-tabs v-model="active"  @change="tabchange">
				<van-tab title="每日生态明细" style="margin-bottom: 15vw;" name="2">
						<div class="assets-list" v-for="(item,index) in Dayrecords" v-if="Dayrecordsnokong">
								<div class="item">
									<div class="top">
										<div style="font-weight: bold; font-size: 4vw;">时间</div>
										<div class="status">
											{{item.share_time}}
										</div>
									</div>
									<div class="bottom">
										<div class="price" style="font-weight: bold; font-size: 4vw;">额度变化:</div>
										<div class="date">{{item.share}}</div>
									</div>
								</div>
						</div>
						<div v-if="Dayrecordskong">
							<van-empty
							  class="custom-image"
							  image="error"
							  description="此列表为空"
							/>
						</div>
						<van-pagination
							v-if="Dayrecordsnokong"
							v-model="Daypage1.current_page"
							:total-items="Daypage1.total"
							:show-page-size="4"
							force-ellipses
							@change="handleCurrentChange3"
						/>
				</van-tab>
				<van-tab title="生态明细" style="margin-bottom: 15vw;" name="0">
						<div class="assets-list" v-for="(item,index) in records" v-if="recordsnokong">
								<div class="item">
									<div class="top">
										<div>序号:{{item.id}}</div>
										<div class="status">
											<span v-if="item.share_type===1">25%立即释放</span>
											<span v-if="item.share_type===2">75%线性释放</span>
										</div>
									</div>
									<div class="bottom">
										<div class="price">额度变化:<span>{{item.share_balance}}</span></div>
										<div class="date">{{item.share_time}}</div>
									</div>
									<div class="linear">
										<div class="text">线性进程</div>
										<div class="num">{{item.process_id}}</div>
									</div>
									<div class="linear">
										<div class="text">线性次数</div>
										<div class="num">{{item.step}}</div>
									</div>
								</div>
						</div>
						<div v-if="recordskong">
							<van-empty
							  class="custom-image"
							  image="error"
							  description="此列表为空"
							/>
						</div>
						<van-pagination
							v-if="recordsnokong"
							v-model="page1.current_page"
							:total-items="page1.total"
							:show-page-size="4"
							force-ellipses
							@change="handleCurrentChange1"
						/>
				</van-tab>
				<van-tab title="每日分红明细" style="margin-bottom: 15vw;" name="3">
						<div class="assets-list" v-for="(item,index) in DaybonusData" v-if="DaybonusDatanokong">
								<div class="item">
									<div class="top">
										<div style="font-weight: bold; font-size: 4vw;">时间</div>
										<div class="status">
											{{item.share_time}}
										</div>
									</div>
									<div class="bottom">
										<div class="price" style="font-weight: bold; font-size: 4vw;">额度变化:</div>
										<div class="date">{{item.share}}</div>
									</div>
								</div>
						</div>
						<div v-if="DaybonusDatakong">
							<van-empty
							  class="custom-image"
							  image="error"
							  description="此列表为空"
							/>
						</div>
						<van-pagination
							v-if="DaybonusDatanokong"
							v-model="Daypage2.current_page"
							:total-items="Daypage2.total"
							:show-page-size="4"
							force-ellipses
							@change="handleCurrentChange4"
						/>
				</van-tab>
				<van-tab title="分红明细" style="margin-bottom: 15vw;" name="1">
						<div class="assets-list" v-for="(item,index) in bonusData" v-if="bonusDatanokong">
								<div class="item">
									<div class="top">
										<div>序号:{{item.id}}</div>
										<div class="status">
											<span v-if="item.share_type===1">25%立即释放</span>
											<span v-if="item.share_type===2">75%线性释放</span>
										</div>
									</div>
									<div class="bottom">
										<div class="price">额度变化:<span>{{item.share_balance}}</span></div>
										<div class="date">{{item.share_time}}</div>
									</div>
								</div>
						</div>
						<div v-if="bonusDatakong">
							<van-empty
							  class="custom-image"
							  image="error"
							  description="此列表为空"
							/>
						</div>
						<van-pagination
							v-if="bonusDatanokong"
							v-model="page2.current_page"
							:total-items="page2.total"
							:show-page-size="4"
							force-ellipses
							@change="handleCurrentChange2"
						/>
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
import {Ecological} from "@/api/index";
export default {
	data() {
	  return {
		active: 0,
		recordskong:false,
		recordsnokong:false,
		bonusDatakong:false,
		bonusDatanokong:false,
		Dayrecordskong:false,
		Dayrecordsnokong:false,
		DaybonusDatakong:false,
		DaybonusDatanokong:false,
	    id:'',
		meal_id:'',
	    page1: {
	      current_page: 1,
	      pagesize: 10,
	      total:0,
	     },
	   records:[],
	   Daypage1: {
	      current_page: 1,
	      pagesize: 10,
	      total:0,
	     },
	   Dayrecords:[],
	   page2: {
	      current_page: 1,
	      pagesize: 10,
	      total:0,
	     },
	   bonusData:[],
	   Daypage2: {
	      current_page: 1,
	      pagesize: 10,
	      total:0,
	     },
	   DaybonusData:[],
	   }
	},
	created() {
		let { meal_id } = this.$route.query;
		this.meal_id = meal_id
		if (this.meal_id == ''){
			this.meal_id = meal_id
		}
		this.onLoadMealRecords()
		this.onLoadBonus()
		this.onLoadMealRecordsDay()
		this.onLoadBonusDay()
	},
	methods:{
		//生态分页
		async handleCurrentChange1(val) {
			this.page1.current_page = val;
			this.onLoadMealRecords(this.page1.current_page)
		},
		//每日生态分页
		async handleCurrentChange3(val) {
			this.Daypage1.current_page = val;
			this.onLoadMealRecordsDay(this.Daypage1.current_page)
		},
		//分红分页
		async handleCurrentChange2(val) {
			this.page2.current_page = val;
			this.onLoadBonus(this.page2.current_page)
		},
		//每日分红分页
		async handleCurrentChange4(val) {
			this.Daypage2.current_page = val;
			this.onLoadBonusDay(this.Daypage2.current_page)
		},
		//每日生态明细
		async onLoadMealRecordsDay() {
			let { status, data } = await Ecological.day({meal_id:this.meal_id,page:this.Daypage1.current_page,cate:1});
			if (status) {
				this.Dayrecords = data.data.data
				if(this.Dayrecords.length == 0){
					this.Dayrecordskong = true
				}else {
					this.Dayrecordsnokong = true
				}
				this.Daypage1.total = data.data.total
			}
		},
		//我的生态明细
		async onLoadMealRecords() {
			let { status, data } = await Ecological.bonus({meal_id:this.meal_id,page:this.page1.current_page,cate:1});
			if (status) {
				this.records = data.data.data
				if(this.records.length == 0){
					this.recordskong = true
				}else {
					this.recordsnokong = true
				}
				this.page1.total = data.data.total
			}
		},
		//我的分红明细
		async onLoadBonus() {
			let { status, data } = await Ecological.bonus({meal_id:this.meal_id,page:this.page2.current_page,cate:2});
			if (status) {
				this.bonusData = data.data.data
				if(this.bonusData.length == 0){
					this.bonusDatakong = true
				}else {
					this.bonusDatanokong = true
				}
				this.page2.total = data.data.total
			}
		},
		//每日分红明细
		async onLoadBonusDay() {
			let { status, data } = await Ecological.day({meal_id:this.meal_id,page:this.Daypage2.current_page,cate:2});
			if (status) {
				this.DaybonusData = data.data.data
				if(this.DaybonusData.length == 0){
					this.DaybonusDatakong = true
				}else {
					this.DaybonusDatanokong = true
				}
				this.Daypage2.total = data.data.total
			}
		},
		tabchange(name){
			this.page1.total = 1
			this.page1.current_page = 1
			this.Daypage1.total = 1
			this.Daypage1.current_page = 1
			this.page2.total = 1
			this.page2.current_page = 1
			this.Daypage2.total = 1
			this.Daypage2.current_page = 1
			switch(name){
				case "0":
				this.onLoadMealRecords()
				break
				case "1":
				this.onLoadBonus()
				break
				case "2":
				this.onLoadMealRecordsDay()
				break
				case "3":
				this.onLoadBonusDay()
				break
			}
		},
		//开始结束时间切割
		dateFmt(date) {
			if (date == null || date == undefined){
				return '';
			}
			return date.slice(0,11);
		},
	}
}
</script>

<style lang="less">
	.details {
		padding: 5vw 5vw 20vw 5vw;
		.van-tab {
			padding: 0;
		}
		.title {
			text-align: center;
			font-weight: bold;
		}
		.tit {
			font-size: 4.5vw;
			font-weight: bold;
			color: #124DA5;
			padding-bottom: 5vw;
		}
		.tec_tit {
			font-size: 4.5vw;
			font-weight: bold;
			color: #124DA5;
			padding-bottom: 5vw;
			padding-top: 5vw;
		}
		.assets-list {
			.item {
				padding: 5vw 0;
				border-bottom: 0.2vw solid #eee;
				.top {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-bottom: 1.5vw;
					font-size: 4vw;
					color: #333333;
					font-weight: bold;
					.van-icon {
						color: #999999;
					}
					.status {
						color: #124DA5;
						margin: 0;
					}
				}
				.bottom {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-top: 1.5vw;
		
					.date {
						color: #999999;
					}
		
					.num {
						color: #124DA5;
					}
		
					.price {
						span {
							color: #E80000;
						}
					}
				}
				.linear {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-top: 1.5vw;
					.text {
						color: #999999;
					}
							
					.num {
						color: #124DA5;
					}
				}
			}
		}
		.van-pagination {
			padding: 10vw 0;
		}
		.van-pagination__item::after {
			border: 0;
		}
		.van-tabs__line {
			background-color: #124DA5;
		}
	}
</style>
