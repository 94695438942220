<template>
	<div class="record">
		<div class="operating_top">
			<div class="text">运营奖励</div>
			<div class="content">
				<div class="number">{{Operatingdetailform.balance}}</div>
				<div class="btn"><van-button color="#3988FF" v-if="form.area_partner == 1 && parseInt((new Date().getTime() - new Date(Operatingdetailform.end_date).getTime())/1000) > 0"  @click="onBtn(Operatingdetailform.id)" :disabled="isDisabled">转出</van-button></div>
			</div>
		</div>
		<div class="operating_bottom">
			<div class="line">
				<span class="title">用户</span>
				<span class="content">{{Operatingdetailform.user_id}}</span>
			</div>
			<div class="line">
				<span class="title">服务器编号</span>
				<span class="content">{{Operatingdetailform.order_id}}</span>
			</div>
			<div class="line">
				<span class="title">发布时间</span>
				<span class="content">{{Operatingdetailform.start_date}}</span>
			</div>
			<div class="line">
				<span class="title">结束时间</span>
				<span class="content">{{Operatingdetailform.end_date}}</span>
			</div>
			<div class="line">
				<span class="title">释放时间</span>
				<span class="content">{{Operatingdetailform.release_time}}</span>
			</div>
			<div class="line">
				<span class="title">倒计时</span>
				<span class="content">
					<countdown style="color: #333333;" :end-time="Operatingdetailform.end_date" :release-time="Operatingdetailform.release_time" :release-type="Operatingdetailform.ty"></countdown>
				</span>
			</div>
		</div>
		<van-dialog v-model="show" title="转出" show-cancel-button @confirm="handleOut()">
			<van-field v-model="transferform.balance" type="text" label="运营奖励" />
			<van-field v-model="transferform.in_user" type="text" label="转入人" />
		</van-dialog>
	</div>
</template>

<script>
import { Home } from "@/api/index";
import countdown from "@/components/countdown";
	export default {
		data() {
			return {
				isDisabled:false,
				id:'',
				Operatingdetailform:[],
				show:false,
				transferform: {
					id:'',
					balance: '',
					in_user: '',
				},
				 form:{},
			};
		},
		components:{
			countdown
		},
		created() {
			let { id } = this.$route.query;
			if (this.id == ''){
				this.id = id
			}
			this.Operating()
			this.Total()
		},
		methods: {
			async Total() {
				let { status, data } = await Home.total(); 
				if (status) {
					this.form = data.data;
				}
			},
			async Operating() {
				let { status, data } = await Home.operatingdetail({id:this.id});
				if (status) {
					this.Operatingdetailform = data.data;
					if(this.Operatingdetailform.user_id == 633 || this.Operatingdetailform.user_id == 634 || this.Operatingdetailform.user_id == 1) {
						this.isDisabled = true;
					}
				}
			},
			//转出按钮
				onBtn(id) {
					this.show = true;
					this.transferform.id = id;
				},
			//转出操作
			async handleOut() {
				let { status, data } = await Home.transfer(this.transferform);
				if(status) {
					if(data.code == 200) {
						this.$message.success(data.data);
						this.Operating()
						this.$router.replace('/Operating/list')
					} else {
						this.$message.error(data.message);
					}
				}
			},
		},
		
	};
</script>

<style lang="less">
	.record {
		.operating_top {
			margin: 5vw 5vw;
			.text {
				font-size: 4.5vw;
				font-weight: bold;
			}
			.content {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 2vw;
				.number {
					font-size: 6vw;
					font-weight: bold;
					color: #E80000;
				}
				.btn {
					.van-button {
						border-radius: 2vw;
						height: 9vw;
						padding: 0 6vw;
					}
				}
			}
		}
		.operating_bottom {
			margin: 10vw 5vw 0;
			.line {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 3vw 0;
				.title {
					font-size: 4vw;
					color: #999999;
				}
				.content {
					font-size: 4vw;
					color: #333333;
				}
			}
		}
		.van-dialog {
			.dialog {
				margin: 0 5vw;
			}
			.cash_num {
				margin: 2vw 5vw 0;
				color: #124DA5;
				font-weight: bold;
			}
			.van-cell__value {
				border-bottom: 0.1vw solid #ccc;
			}
			.van-cell::after {
				width: 0;
			}
		}
	}
	
</style>
